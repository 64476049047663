import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "./service/service";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = getCookie();
    return <Route
        {...rest}
        render={(props) =>
            !auth ? (
                <Redirect exact to="/Login" />
            ) : (
                <Component {...props} />
            )
        }
    />
}

export default PrivateRoute;
