import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import UserDetailsDrawerCss from "./UserDetailsDrawer.module.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AppContext } from "../../../../../service/context";

const useStyles = makeStyles({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
});

const UserDetailsDrawer = ({
  isOpen,
  setOpen,
  currentSelectedUser,
  setcurrentSelectedUser,
}) => {
  const classes = useStyles();


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={UserDetailsDrawerCss.drawer_head}>
        <span>User Details</span>
        <span>
          <CancelIcon onClick={toggleDrawer(false)} />
        </span>
      </div>
      <div className={UserDetailsDrawerCss.drawer_body}>
        <p>
          First Name:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.firstname ? currentSelectedUser.firstname : "-"}
          </span>
        </p>
        <p>
          Last Name:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.lastname ? currentSelectedUser.lastname : "-"}
          </span>{" "}
        </p>
        <p>
          Company:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.company ? currentSelectedUser.company : "-"}
          </span>{" "}
        </p>
        <p>
          Role:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.role ? currentSelectedUser.role : "-"}
          </span>{" "}
        </p>
        <p>
          Email Id:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.email ? currentSelectedUser.email : "-"}
          </span>{" "}
        </p>
        <p>
          Phone Number:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedUser && currentSelectedUser.phone ? currentSelectedUser.phone : "-"}
          </span>{" "}
        </p>
        <p>
          Status:{" "}
          <span style={{ fontWeight: 500 }}>
            <FormControlLabel
              control={
                <Switch
                  name="status"
                  color="primary"
                  checked={currentSelectedUser.active ? true : false}
                  size="small"
                />
              }
              label={currentSelectedUser.active == true ? 'active' : 'inactive'}
            />
          </span>{" "}
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          id={"userList_drawer"}
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default UserDetailsDrawer;
