import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";

import ListUserCss from "./ListUser.module.css";
import AddUser from "../AddUser/AddUser";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayButton from "../../../UI-Components/Button/Button";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getUserDetailsFromJwtToken,
  getUserDetailsByCompanyId,
  postDeleteUserDetails,
  getCompanyListDetails,
  resetPassword,
  getPermissionStatus,
  activateUser,
  deactivateUser
} from "../../../../service/service";
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import { fade, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { indexOf, set } from "lodash";
import "../User.css";
import UserDetailsDrawer from "./UserDetailsDrawer/UserDetailsDrawer";
const useStyles = makeStyles((theme) => ({
  search: {
    margin: "5px",
    position: "relative",
    borderRadius: "5px",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    float: "right",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  filterColor: {
    color: "red"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    display: "block",

    padding: "0.8rem 0.5rem",
    width: "100%",

    border: "1px solid black",
    borderRadius: "12px"

    // ?boxShadow: "0px 2px 3px 1px #bab8af",

  },

  card: {
    width: '100%',
    marginBottom: "1rem",
    fontSize: '14px !important'
  },
  bodyCard: {
    fontSize: '14px !important'

  },
  titleCard: {
    '& span': {
      // minHeight: '46px',
      fontSize: '16px !important'

    }
  },
  selectedCard: {
    width: '100%',
    border: "1px solid #4d8fd1",
    marginBottom: "1rem"

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  popList: {
    boxShadow: "0px !important",
    padding: "0.6rem",
    boxShadow: "0px 2px 1px 1px grey",
    display: "flex"
  },
  iconStyle: {
    margin: "0px 0.2rem"
  }

}));

const ListUser = (props) => {
  let curruntUser = {};
  const classes = useStyles();
  const [isAddUser, setIsAddUser] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [ListUser, SetListUser] = useState([]);
  const [ListCompany, SetListCompany] = useState([]);
  const [selectedCompany, SetSelectedCompany] = useState({
    id: 0,
    name: "",
  });
  const [currentSelectedUser, setcurrentSelectedUser] = useState({});
  const [currentUserOpted, setCurrentUserOpted] = useState({});
  const [filterdUsers, setfilterdUsers] = useState([]);
  const [ShowAlert, SetAlert] = useState({ isShowAlert: false, msg: "" });
  const [ToggleForm, SetToggleForm] = useState({
    isToggleForm: false,
    data: null,
  });
  const [currentUserId, setCurrentUserId] = useState(null);
  const [toggleReset, setToggleReset] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(getUserDetailsFromJwtToken());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { appData, setAppData } = useContext(AppContext);
  const open = Boolean(anchorEl);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [dialogMessage, setdialogMessage] = useState('');
  const [dialogButtonText, setdialogButtonText] = useState('');
  const [dialogNext, setdialogNext] = useState(null);
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  useEffect(() => {
    onLoadSetData();
  }, []);
  const handleClick = (event, data) => {
    setCurrentUserOpted(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }


    setIsAddUser(open);
  };
  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    if (ListUser) {
      const searchUsers = ListUser.filter(user => {
        const regExp = new RegExp(`${e.target.value}`, "gi");
        return (user.firstname + user.lastname).match(regExp)
      })
      setfilterdUsers(searchUsers);
    }
  };

  const onLoadSetData = () => {
    if (props && props.isSuperAdmin && props.isSuperAdmin === true) {
      if (ListCompany && ListCompany.length === 0) {
        getCompanyListDetails().then((result) => {
          if (result.success === true) {
            let _data = result.data;
            if (_data && _data.length > 0) {
              _data = _data.map((y) => {
                const _x = { value: y, displayName: y.name };
                return _x;
              });
              _data.unshift({
                value: { id: 0, name: "All" },
                displayName: "All",
              });
              SetListCompany(_data);
              SetSelectedCompany(_data[0]["value"]);
              onGetUsers(_data[0]["value"]["id"]);
            } else {
              SetListCompany([]);
            }
          } else {
            SetListCompany([]);
          }
        });
      } else {
        onGetUsers(selectedCompany.id);
      }
    } else {
      const _userData = getUserDetailsFromJwtToken();
      if (_userData) {
        onGetUsers(_userData.compid);
        SetSelectedCompany({ comp_id: _userData.compid, name: "" });
      }
    }

    const _userData = getUserDetailsFromJwtToken();
    if (_userData) {
      setLoggedInUser(_userData);
    }
  };

  const onGetUsers = (companyid) => {

    const body = {
      params: {
        companyid: companyid,
      },
    };
    getUserDetailsByCompanyId(body).then((result) => {
      if (result.success === true) {
        SetListUser(result.data);
        setfilterdUsers(result.data);
      } else {
        SetListUser([]);
      }
    });
  };

  const onAddUserButton = () => {
    SetToggleForm({ isToggleForm: false, data: null });
    setCurrentUserOpted({});
    setcurrentSelectedUser({});
    setIsAddUser(!isAddUser);
  };

  const onEditUser = (data) => {

    setIsAddUser(true);
    SetToggleForm({ ...ToggleForm, data })
  }

  const onToggleResetButton = (id) => {
    setToggleReset(!toggleReset);
    setCurrentUserId(id);
    handleClose();
  };

  const onResetPassword = async () => {
    // this was passsing all user insteed of user id only
    const res = await resetPassword(currentUserId.id);
    setToggleReset(!toggleReset);
    if (res.success) {
      onAlert(true, 'Password reset successfull');
    }
  };

  const onUserAdd = (data) => {

    SetToggleForm({ isToggleForm: false, data: null });
    const _msg =
      data === "successUpdate"
        ? "User details updated successfully"
        : "User added successfully with password b1naryway";
    onAlert(true, _msg);
    onLoadSetData();
  };

  const onDeleteUser = (data) => {
    const body = {
      userid: data.id,
    };
    postDeleteUserDetails(body).then((result) => {
      if (result && result.success === true) {
        setAnchorEl(false);
        onAlert(true, `User ${data.firstname} ${data.lastname} deleted successfully`);
        onLoadSetData();
      }
    });
  };

  const onCompanyDropDownChange = (event) => {
    if (event && event.target.value) {
      SetSelectedCompany(event.target.value);
      onGetUsers(event.target.value.id);
    }
  };

  const onAlert = (bol, msg) => {
    appData.ShowAlert = {
      bool: true,
      msg: msg,
      type: "success",
    };
    setAppData({ ...appData, ShowAlert: appData.ShowAlert });
  };
  const onUserClick = (selectedUser) => {
    setcurrentSelectedUser(selectedUser);
    setOpenViewMore(true);
  }


  const onActiveChange = async () => {
    let user = curruntUser
    if (!user.active) {
      let responce = await activateUser(user.id)
      if (responce.success) {
        user.active = true;
        onAlert(true, `User ${user.firstname} ${user.lastname} activated successfully`);
      }
    } else {
      let responce = await deactivateUser(user.id)
      if (responce.success) {
        user.active = false;
        onAlert(true, `User ${user.firstname} ${user.lastname} deactivated successfully`);
      }
    }
    setdialogOpen(false)
  };

  function showDialog(user, message, buttonText, next) {
    curruntUser = user
    setdialogMessage(message)
    setdialogButtonText(buttonText)
    setdialogOpen(true)
    setdialogNext(next)
  }

  const handleDialogClose = () => {
    setdialogOpen(false)
  }
  return (
    <>
      <Container fluid={true}>
        <Row className="users_header">
          <Col xs="3" >
            <div className="users_header_title">
              <span> List of Users</span>{" "}
            </div>
          </Col>
          <Col xs="5">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Col>
          <Col xs="2">
            <div style={{ padding: "10px" }}>
              {loggedInUser.roleid === 1 && (
                <BinarywayDropdown
                  placeHolder=""
                  label="Company"
                  value={selectedCompany}
                  options={ListCompany}
                  onChange={(event) => onCompanyDropDownChange(event)}
                />
              )}
            </div>
          </Col>
          <Col xs="2">
            <div className="users_stepper_button">
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_USER
                ) && (
                  <BinarywayButton
                    onClick={onAddUserButton}
                    label="Add User"
                  ></BinarywayButton>
                )}
            </div>
          </Col>
        </Row>

        <Row className="users_body_background">
          {appData.Permissions &&
            getPermissionStatus(appData.Permissions, appConstants.LIST_USER) &&
            ListUser &&
            ListUser.length > 0 ? (
              <>
                <Col xs={12} style={{overflow:"auto", height: "calc(100vh - 124px)"}}  className={ListUserCss.listUser_body_main}>
                  <div
                    className={ListUserCss.listUser_body_tile}
                    style={{ overflowX: "hidden" }}
                  >
                    <Row className={ListUserCss.listUser_body_tile_row}>
                      {filterdUsers &&
                        filterdUsers.map((x, index) => {
                          return (
                            x.role_id >= loggedInUser.roleid && 
                            <Col xs={3} style={{ padding: "5px" }} key={index}>
                              <Card className={x.id === currentSelectedUser.id ? classes.selectedCard : classes.card}  >
                                <CardHeader
                                  action={
                                    (loggedInUser.roleid === 1 || (x.company_id === loggedInUser.compid && x.role_id >= loggedInUser.roleid)) ? <IconButton aria-label="settings" onClick={(e) => handleClick(e, x)} style={{ outline: "none" }}>
                                      <MoreVertIcon />
                                    </IconButton> : null
                                  }
                                  className={classes.titleCard}
                                  title={`${x.firstname} ${x.lastname}`}
                                />
                                <hr />
                                <CardContent>
                                  <Typography variant="h6" component="h3" className={classes.bodyCard} >
                                    Company: <span style={{ fontWeight: 500 }}>{x.company}</span>
                                  </Typography>
                                  <Typography variant="h6" component="h3" className={classes.bodyCard}>
                                    Role: <span style={{ fontWeight: 500 }}>{x.role}</span>
                                  </Typography>
                                </CardContent>
                                <hr />
                                <CardActions style={{ display: "flex", justifyContent: "space-between", paddingLeft: "1rem", paddingRight: "1rem" }}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="status"
                                        color="primary"
                                        checked={x.active}
                                        onChange={() => showDialog(x, `Do you want to ${x.active ? 'deactivate' : 'activate'} user '${x.firstname} ${x.lastname}' ?`, `${x.active ? 'Deactivate' : 'Activate'}`, () => onActiveChange)}
                                      />
                                    }
                                    label={x.active === true ? "Active" : "Inactive"}
                                  />
                                  <VisibilityIcon
                                    onClick={() => onUserClick(x)}
                                  />
                                </CardActions>
                              </Card>
                              <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                classes={{
                                  paper: classes.popList
                                }}
                              >
                                {/* <LockIcon className={classes.iconStyle} /> */}

                                {(loggedInUser.roleid === 1 || (x.company_id === loggedInUser.compid && x.role_id >= loggedInUser.roleid)) && <RefreshIcon className={classes.iconStyle} onClick={() => onToggleResetButton(currentUserOpted)} />}
                                {(loggedInUser.roleid === 1 || (x.company_id === loggedInUser.compid && x.role_id >= loggedInUser.roleid)) && <EditIcon className={classes.iconStyle} onClick={() => onEditUser(currentUserOpted)} />}
                                {(loggedInUser.roleid === 1 || (x.company_id === loggedInUser.compid && x.role_id >= loggedInUser.roleid)) && <DeleteIcon className={classes.iconStyle} onClick={() => onDeleteUser(currentUserOpted)} />}

                              </Popover>

                            </Col>

                          )
                        })}


                    </Row>
                  </div>
                </Col>
              </>
            ) : (
              <Row className="py-4">
                <Col md="12" style={{ textAlign: "center" }}>
                  Seems Like you have not created any users yet. You can quicky
                  add it
                    </Col>
              </Row>
            )}
        </Row>
      </Container>
      <UserDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentSelectedUser={currentSelectedUser}
        setcurrentSelectedUser={setcurrentSelectedUser}
      />

      <AddUser
        isAddUser={isAddUser}
        setIsAddUser={setIsAddUser}
        toggleDrawer={toggleDrawer}
        companyId={
          props.isSuperAdmin === true ? null : selectedCompany.id
        }
        isSuperAdmin={props.isSuperAdmin}
        onChange={onUserAdd}
        dataFromList={ToggleForm.data}
      />
      {ToggleForm && ToggleForm.isToggleForm && (
        <Modal
          isOpen={ToggleForm.isToggleForm}
          toggle={() => SetToggleForm({ isToggleForm: false, data: null })}
          className=""
          size="md"
        >
          <ModalHeader
            toggle={() => SetToggleForm({ isToggleForm: false, data: null })}
          >
            Add/Update User
          </ModalHeader>
          <ModalBody>
            {/* <AddUser
              companyId={
                props.isSuperAdmin === true ? null : selectedCompany.id
              }
              isSuperAdmin={props.isSuperAdmin}
              onChange={onUserAdd}
              dataFromList={ToggleForm.data}
            ></AddUser> */}
          </ModalBody>
        </Modal>
      )}

      {toggleReset && (
        <Modal
          isOpen={toggleReset}
          toggle={() => setToggleReset(false)}
          className=""
          size="md"
        >
          <ModalHeader toggle={() => setToggleReset(false)}>
            Reset Password
          </ModalHeader>
          <ModalBody>
            <div>
              <p>Are you sure</p>
              <button style={{ float: "right" }} onClick={onResetPassword}>
                Ok
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Dialog onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <span style={{ textAlign: 'right', paddingBottom:"5px", paddingRight:"7px" }}>
          <BinarywayButton onClick={handleDialogClose} color="default" label="Close" autoFocus>
          </BinarywayButton>
          <BinarywayButton onClick={() => dialogNext()} color="primary" label={dialogButtonText} autoFocus>
          </BinarywayButton>
        </span>
      </Dialog>
    </>
  );
};

export default ListUser;
