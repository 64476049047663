import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";

const Chart = () => {
  const ref = useRef(null);
  const createPie = d3
    .pie()
    .value((d) => d.value)
    .sort(null);
  const createArc = d3.arc().innerRadius(0).outerRadius(100);
  const colors = d3.scaleOrdinal(d3.schemeCategory10);
  const format = d3.format(".2f");
  let apidata = [];
  useEffect(() => {
    apidata = [
      { date: 0, value: 40.23406405624706 },
      { date: 1, value: 98.05261051499821 },
      { date: 2, value: 28.43304673254392 },
      { date: 3, value: 39.604661923878616 },
      { date: 4, value: 44.73410095930066 },
    ];
    const data = createPie(apidata);
    const group = d3.select(ref.current);
    const groupWithData = group.selectAll("g.arc").data(data);

    groupWithData.exit().remove();

    const groupWithUpdate = groupWithData
      .enter()
      .append("g")
      .attr("class", "arc")
      .on("click", (d, i) => console.log(i));

    const path = groupWithUpdate
      .append("path")
      .merge(groupWithData.select("path.arc"));

    path
      .attr("class", "arc")
      .attr("d", createArc)
      .attr("fill", (d, i) => colors(i));

    const text = groupWithUpdate
      .append("text")
      .merge(groupWithData.select("text"));

    text
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("transform", (d) => `translate(${createArc.centroid(d)})`)
      .style("fill", "white")
      .style("font-size", 12);
  }, [apidata]);

  return (
    <>
      <span className="label">Hooks</span>
      <svg width={200} height={200}>
        <g ref={ref} transform={`translate(${100} ${100})`} />
      </svg>
    </>
  );
};

export default Chart;
