import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import BinarywayButton from "../../../../UI-Components/Button/Button";
import RuleDetailsDrawerCss from "./RuleDetailsDrawer.module.css";
import { getPermissionStatus } from "../../../../../service/service";
import { AppContext } from "../../../../../service/context";
import { appConstants } from "../../../../../service/constants";
import HelpIcon from '@material-ui/icons/Help';
import CustomDialog from "../../../../UI-Components/Modal/CustomDialog";
import MUIRichTextEditor from 'mui-rte';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  list: {
    width: 350,
    overflow:'hidden'
  },
  fullList: {
    width: "auto",
  },
  customTooltip: {
    backgroundColor: '#c5c2c2',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between'
  },
  customArrow: {
    color: '#c5c2c2',
  },
});

class Tabs extends React.Component {

  constructor(props) {
    super();
    this.state = { selected: this.props ? this.props.selected : 0 }
  }

  render() {
    return (<div className="RuleDrawer">
      <ul className={RuleDetailsDrawerCss.drawerheaderContent}>
      {this.props.children.map((elem, index) => {
            return (
              <div>
                <li
                  className={index == this.state.selected ? "selected" : ""}
                  key={index}
                  onClick={this.handleChange.bind(this, index)}
                >
                  {elem.props.title}
                </li>
                {index == this.state.selected ? (
                  <div
                    style={{
                      width: "100%",
                      height: "1.5px",
                      backgroundColor: "white",
                    }}
                  >
                    {" "}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "1.5px",
                      backgroundColor: "transparent",
                    }}
                  >
                    {" "}
                  </div>
                )}
              </div>
            );
          })}
      </ul>
      <div className={RuleDetailsDrawerCss.tab}>{this.props.children[this.state.selected]}</div>
    </div>
    )
  }

  handleChange(index) {
    this.setState({ selected: index })
  }
}

class Panel extends React.Component {
  render() {
    return <>{this.props.children}</>
  }
}

const RuleDetailsDrawer = ({
  isOpen,
  setOpen,
  currentRule,
  setCurrentRule,
  onDeleteRule
}) => {
  const classes = useStyles();
  const { appData, setAppData } = useContext(AppContext);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = (value) => {
    setOpenDialog(false);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={RuleDetailsDrawerCss.drawer_head}>
        <span>View Rule Details</span>
        <span>
          <CancelIcon onClick={toggleDrawer(false)} />
        </span>
      </div>
      <div className={RuleDetailsDrawerCss.drawer_box_main}>
        <div className={RuleDetailsDrawerCss.drawer_firstBox}>
          <div className={RuleDetailsDrawerCss.drawer_body_ruleTitle}>
          <Tooltip title={currentRule && currentRule.NAME ? currentRule.NAME : "-"} placement="left"
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow
              }}
              arrow>
            <span>
              {currentRule && currentRule.NAME ? (currentRule.NAME.length > 130 ? currentRule.NAME.substring(0, 130) + '...' : currentRule.NAME) : "-"}
            </span>
            </Tooltip>
          </div>
          {
            currentRule && currentRule.DESCRIPTION &&
            (<div className={RuleDetailsDrawerCss.drawer_body_ruleDescription}>
              <Tooltip title={currentRule.DESCRIPTION} placement="left"
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow
              }}
              arrow>
                <span>{currentRule.DESCRIPTION.length > 190 ? currentRule.DESCRIPTION.substring(0, 190) + '...' : currentRule.DESCRIPTION}</span>
              </Tooltip>
            </div>)
          }
        </div>
        <div className={RuleDetailsDrawerCss.drawerContent}>
          <div className={RuleDetailsDrawerCss.drawer_firstBox}>
              <Tabs selected={1} className={RuleDetailsDrawerCss.drawerTab}>
                <Panel title="Basic Details">
                  <div className={RuleDetailsDrawerCss.drawerMiddleContent}>
                    {currentRule && currentRule.TARGET_VERSION && (
                      <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                        <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Blue Prism Version</span>
                        <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                          {currentRule ? currentRule.TARGET_VERSION : "-"}{" "}
                        </span>
                      </div>
                    )} 
                    <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                      <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Rule Type</span>
                      <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                        {currentRule ? currentRule.RULE_TYPE : "-"}{" "}
                      </span>
                    </div>
                    {currentRule && currentRule.RULE_TYPE !== 'Compare Template' &&
                      (
                        <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Component</span>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                            {currentRule ? currentRule.COMPONENT_NAME : "-"}{" "}
                          </span>
                        </div>
                      )}
                    {currentRule && currentRule.CRITERIA &&
                      (
                        <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Criteria</span>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                            {currentRule ? currentRule.CRITERIA : "-"}{" "}
                          </span>
                        </div>
                      )}
                    {currentRule && currentRule.PROPERTY_NAME &&
                      (
                        <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Component Property</span>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                            {currentRule ? currentRule.PROPERTY_NAME : "-"}
                          </span>
                        </div>
                      )}
                    {currentRule && currentRule.PROPERTY_NAME &&
                      (
                        <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Component Property</span>
                          <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                            {currentRule ? currentRule.PROPERTY_NAME : "-"}
                          </span>
                        </div>
                      )}
                    {currentRule && currentRule.ATTRIBUTE_PROPERTY && (
                      currentRule.RULE_TYPE === 'Compare Template' ?
                        (
                          <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                            <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Template</span>
                            <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                              {currentRule ? currentRule.ATTRIBUTE_PROPERTY : "-"}
                            </span>
                          </div>
                        )
                        : (
                          <div className={RuleDetailsDrawerCss.drawerMiddleContentBlock}>
                            <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockTitle}>Component Property Value</span>
                            <span className={RuleDetailsDrawerCss.drawerMiddleContentBlockValue} >
                              {currentRule ? currentRule.ATTRIBUTE_PROPERTY : "-"}
                            </span>
                          </div>
                        )
                    )}
                    {currentRule && currentRule.stageData.length
                      ? currentRule.stageData.map((obj) => {
                        if (!obj.STAGE_NAME) return;

                        return (
                          <Accordion style={{ border: "1px solid silver" }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <>{obj.STAGE_NAME} </>
                            </AccordionSummary>
                            {currentRule &&
                              obj.STAGE_PROPERTY &&
                              obj.STAGE_PROPERTY !== "null" && (
                                <AccordionDetails>
                                  <p>
                                    {
                                      currentRule.RULE_TYPE === "Process Flow" &&
                                        obj.STAGE_NAME === "Recover" ? "Max Attempts:" : "Business Object:"
                                    }{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {currentRule ? obj.STAGE_PROPERTY || "-" : "-"}{" "}
                                    </span>
                                  </p>
                                </AccordionDetails>
                              )}
                            {currentRule &&
                              obj.STAGE_PROPERTY_ADDITIONAL &&
                              obj.STAGE_PROPERTY_ADDITIONAL !== "null" && (
                                <AccordionDetails>
                                  <p>
                                    Action:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {currentRule ? obj.STAGE_PROPERTY_ADDITIONAL : "-"}
                                    </span>{" "}
                                  </p>
                                </AccordionDetails>
                              )}
                          </Accordion>
                        );
                      })
                      : null}
                  </div>
                </Panel>
                <Panel title="Remedy">
                  <div className={RuleDetailsDrawerCss.drawerMiddleContent}>
                    {/* <span variant="outlined" color="primary" onClick={handleDialogOpen}>
                        Remedy :{" "}
                        <HelpIcon />
                      </span>{" "} */}
                    <MUIRichTextEditor
                      id="MUIRichTextEditor_ReadOnlyUI"
                      defaultValue={currentRule ? currentRule.REMEDY : "-"}
                      toolbar={false}
                      readOnly={true}
                    />
                    {/* <CustomDialog openDialog={openDialog} data={currentRule ? currentRule.REMEDY : "-"} onClose={handleDialogClose} /> */}
                  </div>
                </Panel>
              </Tabs>
            </div>
        </div>
      </div>
      <div className={RuleDetailsDrawerCss.drawer_btn}>
        <BinarywayButton
          variant="outlined"
          label="Okay"
          onClick={toggleDrawer(false)}
        ></BinarywayButton> 
              
      </div>
    </div >
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          id={"ruleList_drawer"}
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default RuleDetailsDrawer;
