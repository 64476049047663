import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Container, CardTitle } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
import { fade, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import { getDownload } from "../../../../service/service";
// import { getPermissionStatus } from "../../../../service/service";
import moment from 'moment';

import {
  getPermissionStatus,
  getUserDetailsFromJwtToken,
  getConfig,
} from '../../../../service/service';
import { AppContext } from '../../../../service/context';
import { appConstants, imgSrc } from '../../../../service/constants';
import BinarywayButton from '../../../UI-Components/Button/Button';
import BinarywayDropdown from '../../../UI-Components/Drop-Down/Drop-Down';
import RuleListPng from '../../../../assets/RuleList.png';
import { ReactComponent as ExcelSVG } from '../../../../assets/excel-icon-svg.svg';
import ListProcessAnalyzerCss from './ListProcessAnalyzer.module.css';
import CreateProcessAnalyzer from '../CreateProcessAnalyzer/CreateProcessAnalyzer';
import ProcessAnalyzerDetailDrawer from '../ProcessAnalyzerDetailDrawer/ProcessAnalyzerDetailDrawer';
import '../ProcessAnalyzer.css';
import BinaryWayLogoWhite from '../../../../assets/BinaryWayLogoBlack.png';

//chart
import CategoryChart1 from '../../Chart/CategoryChart1';
import OverallChart1 from '../../Chart/OverallChart1';

import ReactExport from 'react-data-export';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Switch from '@material-ui/core/Switch';
import CustomDialog from '../../../UI-Components/Modal/CustomDialog';
import * as _ from 'lodash';
import { green } from '@material-ui/core/colors';
import RuleDetailsDrawer from '../../Rules/ListRules/RuleDetailsDrawer/RuleDetailsDrawer';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
am4core.useTheme(am4themes_animated);
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  card: {
    width: '100%',
    margin: '8px',
    fontSize: '14px !important',
  },
  bodyCard: {
    fontSize: '14px !important',
    borderBottom: '1px solid #cac4c4',
    padding: '8px',
  },
  titleCard: {
    '& span': {
      // minHeight: '46px',
      fontSize: '20px !important',
      fontWeight: '600',
      height: '44px',
      display: 'block',
    },
  },
  selectedCard: {
    border: '1px solid #4d8fd1',
    margin: '5px',
    borderRadius: '12px',
  },
  selectedCardDisable: {
    width: '100%',
    border: '1px solid #4d8fd1',
    marginBottom: '1rem',
    background: 'lightgrey',
  },
  passedRuleCount: {
    minWidth: '0px',
    backgroundColor: '#28a745',
    '&:hover': {
      backgroundColor: '#28a745',
    },
    borderRadius: '50%',
  },
  failedRuleCount: {
    minWidth: '0px',
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#f44336',
    },
    borderRadius: '50%',
  },
  customAnalyzerBtn: {
    backgroundColor: '#0664ca',
    '&:hover': {
      backgroundColor: '#1b3dd2',
    },
  },
  resultFilterDrd: {
    width: '16%',
    marginLeft: '20px',
    marginTop: '-3%',
  },
  analyzerFilterDrd: {
    width: '21%',
    margin: '5px 10px',
  },
  analyzerActionDrd: {
    margin: '5px 10px',
    width: '30%',
  },
  ruleBody: {
    paddingTop: '0px',
  },
  cardTitle: {
    background: '#0f183f',
    color: 'white',
    padding: '16px',
    '& .MuiTypography-h5': {
      fontSize: '18px',
    },
    height: '80.0333px',
    justifyContent: 'flex-start'
  },
  cardContent: {
    minHeight: '70px',
    color: 'black',
    padding: '10px 16px',
  },
  cardAction: {
    backgroundColor: '#e8edf3',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  visibilityIcon: {
    marginRight: '16px',
  },
  tooltipContainer: {
    minWidth: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tooltipGreen: {
    fontSize: '12px',
    lineHeight: 2,
    color: '#ffffff',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    borderRadius: '2px',
    backgroundColor: '#02b04a',
    textAlign: 'center',
  },
  tooltipRed: {
    fontSize: '12px',
    lineHeight: 2,
    color: '#ffffff',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    borderRadius: '2px',
    backgroundColor: '#f14030',
    textAlign: 'center',
    marginLeft: '16px',
  },
  processAnalyzerContainer: {
    // padding:'8px'
  },
  cardContainer: {
    margin: '0',
  },
  customTooltip: {
    backgroundColor: '#c5c2c2',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between'
  },
  customArrow: {
    color: '#c5c2c2',
  },
}));

const ListProcessAnalyzer = (props) => {
  const classes = useStyles();
  const resultFilterListData = [
    {
      value: { id: 0, name: 'all' },
      displayName: 'All',
    },
    {
      value: { id: 1, name: 'pass' },
      displayName: 'Passed',
    },
    {
      value: { id: 2, name: 'fail' },
      displayName: 'Failed',
    },
  ];

  const groupByFilterListData = [];

  const actionListData = [
    {
      value: { id: 0, name: 'pdf' },
      displayName: 'Export PDF',
    },
    {
      value: { id: 1, name: 'xls' },
      displayName: 'Export XLS',
    },
  ];

  const { appData, setAppData } = useContext(AppContext);
  const [processAnalyzerData, setProcessAnalyzerData] = useState({});
  const [isOpenChooseAnalyzer, setOpenChooseAnalyzer] = useState(false);
  const [
    isOpenProcessAnalyzerDetailDrawer,
    setOpenProcessAnalyzerDetailDrawer,
  ] = useState(false);
  const [
    currentProcessAnalyzerRuleData,
    setcurrentProcessAnalyzerRuleData,
  ] = useState({});
  const [
    currentProcessAnalyzerFilterRuleData,
    setcurrentProcessAnalyzerFilterRuleData,
  ] = useState([]);
  const [ListResultFilter, setListResultFilter] = useState(
    resultFilterListData
  );
  const [ListGroupByFilter, setListGroupByFilter] = useState(
    groupByFilterListData
  );
  const [ListActionsData, setListActionsData] = useState(actionListData);
  const [selectedResultFilter, setSelectedResultFilter] = useState(
    resultFilterListData[0]['value']
  );
  const [selectedGroupByFilter, setSelectedGroupByFilter] = useState(
    ListGroupByFilter
  );
  const [selectedAction, setSelectedAction] = useState();
  const [configData, setConfigData] = useState([]);
  const [customCategory, setCustomCategory] = useState([]);
  const [anchorEl, setAnchorEl] = useState({ obj: null, state: null });
  const [isCardView, setCardView] = useState(true);
  const [cardOverallCount, setCardOverallCount] = useState(0);
  const [overallCount, setOverallCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [chartView, setChartView] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [remedyForModal, setRemedyForModal] = useState('');
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);
  const [overallChartData, setOverallChartData] = useState('');
  const [categoryChartData, setCategoryChartData] = useState('');
  const [
    currentRuleForProcessResult,
    setCurrentRuleForProcessResult,
  ] = useState({});
  const categoryGroup = [];
  const processGroup = [];
  const toggleAddRule = () => {
    setOpenChooseAnalyzer(true);
  };

  useEffect(() => {
    if (appData.processAnalyzerData) {
      setProcessAnalyzerData(appData.processAnalyzerData);
    }
    getConfigData();
  }, []);

  useEffect(() => {
    getChart();
  }, [isCardView, currentProcessAnalyzerFilterRuleData]);

  useEffect(() => {
    if (processAnalyzerData && processAnalyzerData.response) {
      setDataToProcessAnalyzer();
    }
  }, [processAnalyzerData, selectedGroupByFilter]);

  const onViewMore = (obj) => {
    setCurrentRule(obj);
    setOpenViewMore(true);
  };

  const handleDialogOpen = (remedy) => {
    setRemedyForModal(remedy);
    setOpenDialog(true);
  };

  const handleDialogClose = (value) => {
    setRemedyForModal(null);
    setOpenDialog(false);
  };

  const getChart = () => {
    let chartData1 = [];
    let chartData2 = [];
    if (
      processAnalyzerData &&
      processAnalyzerData.response &&
      processAnalyzerData.response.data
    ) {
      let categoryGroup = [];
      let passedCount = 0;
      let failedCount = 0;
      // CATEGORY WISE CHART DATA
      if (
        selectedGroupByFilter &&
        selectedGroupByFilter.name === 'Category Wise'
      ) {
        processAnalyzerData.response.data.forEach((obj, index) => {
          let categoryIndex = categoryGroup.findIndex(
            (x) => x.category == obj.ruleCategory
          );
          obj.passedCount = 0;
          obj.failedCount = 0;
          if (categoryIndex == -1) {
            obj.outputMessage &&
              obj.outputMessage.length > 0 &&
              obj.outputMessage.map((x) => {
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                  passedCount = passedCount + 1;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                  failedCount = failedCount + 1;
                }
              });

            categoryGroup.push({
              description: obj.ruleCategoryDes,
              category: obj.ruleCategory,
              pass: obj.passedCount,
              fail: obj.failedCount,
            });
          } else {
            obj.outputMessage &&
              obj.outputMessage.length > 0 &&
              obj.outputMessage.map((x) => {
                if (x.pass === true) {
                  categoryGroup[categoryIndex].pass =
                    categoryGroup[categoryIndex].pass + 1;
                  passedCount = passedCount + 1;
                } else {
                  categoryGroup[categoryIndex].fail =
                    categoryGroup[categoryIndex].fail + 1;
                  failedCount = failedCount + 1;
                }
              });
          }
        });
        let temPassObj = {
          category: 'Pass',
          categoryIndex: passedCount,
          subData: [],
        };
        let temFaillObj = {
          category: 'Fail',
          categoryIndex: failedCount,
          subData: [],
        };
        categoryGroup.forEach((data, index) => {
          //////////////////////////////////////////
          let temObj = {
            category: data.category ? data.category : 'Default',
            categoryIndex: data.pass + data.fail,
            subData: [],
          };
          temObj.description = data.description;
          temObj.subData.push({ name: 'Pass', value: data.pass });
          temObj.subData.push({ name: 'Fail', value: data.fail });
          chartData1.push(temObj);
          /////////////////////////////////////////////
          temPassObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.pass,
          });
          temFaillObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.fail,
          });
        });
        chartData2.push(temPassObj);
        chartData2.push(temFaillObj);

        setCardOverallCount(passedCount + failedCount);
        setCategoryCount(passedCount + failedCount);
      }
      // RULE WISE CHART DATA
      if (
        selectedGroupByFilter &&
        selectedGroupByFilter.name === 'Rule Wise'
      ) {
        processAnalyzerData.response.data.forEach((obj, index) => {
          obj.failedCount = 0;
          obj.passedCount = 0;
          obj.outputMessage &&
            obj.outputMessage.length > 0 &&
            obj.outputMessage.map((x) => {
              x.ruleName = x.ruleName || 'Release Specific Occurrences';
              let processIndex = categoryGroup.findIndex(
                (p) => p.category == x.ruleName
              );
              obj.failedCount = 0;
              obj.passedCount = 0;
              if (processIndex == -1) {
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                  passedCount = passedCount + 1;
                  categoryGroup.push({
                    category: x.ruleName,
                    pass: obj.passedCount + 1,
                    fail: 0,
                  });
                } else {
                  obj.failedCount = obj.failedCount + 1;
                  failedCount = failedCount + 1;
                  categoryGroup.push({
                    category: x.ruleName,
                    pass: 0,
                    fail: obj.failedCount + 1,
                  });
                }
              } else {
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                  passedCount = passedCount + 1;
                  categoryGroup[processIndex].pass =
                    categoryGroup[processIndex].pass + obj.passedCount;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                  failedCount = failedCount + 1;
                  categoryGroup[processIndex].fail =
                    categoryGroup[processIndex].fail + obj.failedCount;
                }
              }
            });
        });
        let temPassObj = {
          category: 'Pass',
          categoryIndex: passedCount,
          subData: [],
        };
        let temFaillObj = {
          category: 'Fail',
          categoryIndex: failedCount,
          subData: [],
        };
        categoryGroup.forEach((data, index) => {
          //////////////////////////////////////////
          let temObj = {
            category: data.category ? data.category : 'Default',
            categoryIndex: data.pass + data.fail,
            subData: [],
          };
          temObj.subData.push({
            name: 'Pass',
            value: data.pass
          });
          temObj.subData.push({
            name: 'Fail',
            value: data.fail
          });
          chartData1.push(temObj);
          /////////////////////////////////////////////
          temPassObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.pass,
          });
          temFaillObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.fail,
          });
        });
        chartData2.push(temPassObj);
        chartData2.push(temFaillObj);
        setCardOverallCount(passedCount + failedCount);

        setOverallCount(passedCount + failedCount);
      }

      //Process wise chart data
      if (
        selectedGroupByFilter &&
        selectedGroupByFilter.name === 'Process Wise'
      ) {
        processAnalyzerData.response.data.forEach((obj, index) => {
          obj.failedCount = 0;
          obj.passedCount = 0;
          obj.outputMessage &&
            obj.outputMessage.length > 0 &&
            obj.outputMessage.map((x) => {
              x.process = x.process || 'Release Specific Occurrences';
              let processIndex = categoryGroup.findIndex(
                (p) => p.category == x.process
              );
              obj.failedCount = 0;
              obj.passedCount = 0;
              if (processIndex == -1) {
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                  passedCount = passedCount + 1;
                  categoryGroup.push({
                    category: x.process,
                    pass: obj.passedCount + 1,
                    fail: 0,
                  });
                } else {
                  obj.failedCount = obj.failedCount + 1;
                  failedCount = failedCount + 1;
                  categoryGroup.push({
                    category: x.process,
                    pass: 0,
                    fail: obj.failedCount + 1,
                  });
                }
              } else {
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                  passedCount = passedCount + 1;
                  categoryGroup[processIndex].pass =
                    categoryGroup[processIndex].pass + obj.passedCount;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                  failedCount = failedCount + 1;
                  categoryGroup[processIndex].fail =
                    categoryGroup[processIndex].fail + obj.failedCount;
                }
              }
            });
        });
        let temPassObj = {
          category: 'Pass',
          categoryIndex: passedCount,
          subData: [],
        };
        let temFaillObj = {
          category: 'Fail',
          categoryIndex: failedCount,
          subData: [],
        };
        categoryGroup.forEach((data, index) => {
          //////////////////////////////////////////
          let temObj = {
            category: data.category ? data.category : 'Default',
            categoryIndex: data.pass + data.fail,
            subData: [],
          };
          temObj.subData.push({ name: 'Pass', value: data.pass });
          temObj.subData.push({ name: 'Fail', value: data.fail });
          chartData1.push(temObj);
          /////////////////////////////////////////////
          temPassObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.pass,
          });
          temFaillObj.subData.push({
            name: data.category ? data.category : 'Default',
            value: data.fail,
          });
        });
        chartData2.push(temPassObj);
        chartData2.push(temFaillObj);
        setCardOverallCount(passedCount + failedCount);

        setOverallCount(passedCount + failedCount);
      }
    } else {
      chartData1 = [];
      chartData2 = [];
    }

    ///////////////////////////////////////////////
    getOverallChart(chartData2);
    getCatagoryChart(chartData1);
  };

  const getOverallChart = (chartData2) => {
    if (chartData2.length > 0) {
      const overAllchartData = (
        <OverallChart1 data={chartData2} count={overallCount} />
      );
      setOverallChartData(overAllchartData);
    }
  };

  const getCatagoryChart = (chartData1) => {
    if (chartData1.length > 0) {
      let categorychartData = (
        <CategoryChart1 data={chartData1} count={categoryCount} viewRule={filterAndShowResult} analyzerResultData={currentProcessAnalyzerFilterRuleData} />
      );
      setCategoryChartData(categorychartData);
    }
  };

  // const getOverallChart = (chartData2) => {
  //   if (chartData2.length > 0) {
  //     var container = am4core.create('chartdiv1', am4core.Container);
  //     container.width = am4core.percent(90);
  //     container.height = am4core.percent(100);
  //     container.layout = 'horizontal';
  //     var chart = container.createChild(am4charts.PieChart);
  //     chart.width = am4core.percent(40);
  //     chart.radius = am4core.percent(50);

  //     // Add data
  //     chart.data = chartData2;

  //     // Add and configure Series
  //     var pieSeries = chart.series.push(new am4charts.PieSeries());
  //     pieSeries.dataFields.value = 'categoryIndex';
  //     pieSeries.dataFields.category = 'category';
  //     pieSeries.slices.template.states.getKey(
  //       'active'
  //     ).properties.shiftRadius = 0;
  //     //pieSeries.labels.template.text = "{category}\n{value.percent.formatNumber('#.#')}%";

  //     var colorSet = new am4core.ColorSet();
  //     colorSet.list = ['#28a745', '#dc3545'].map(function (color) {
  //       return new am4core.color(color);
  //     });
  //     pieSeries.colors = colorSet;

  //     pieSeries.slices.template.events.on('hit', function (event) {
  //       selectSlice1(event.target.dataItem);
  //     });

  //     var chart2 = container.createChild(am4charts.PieChart);
  //     chart2.width = am4core.percent(60);
  //     chart2.radius = am4core.percent(90);

  //     // Add and configure Series
  //     var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
  //     pieSeries2.dataFields.value = 'value';
  //     pieSeries2.dataFields.category = 'name';
  //     pieSeries2.slices.template.states.getKey(
  //       'active'
  //     ).properties.shiftRadius = 0;
  //     //pieSeries2.labels.template.radius = am4core.percent(50);
  //     //pieSeries2.labels.template.inside = true;
  //     //pieSeries2.labels.template.fill = am4core.color("#ffffff");
  //     // pieSeries2.labels.template.disabled = true;
  //     // pieSeries2.ticks.template.disabled = true;
  //     // pieSeries2.alignLabels = false;

  //     pieSeries2.events.on('positionchanged', updateLines1);

  //     var interfaceColors = new am4core.InterfaceColorSet();

  //     var line1 = container.createChild(am4core.Line);
  //     line1.strokeDasharray = '2,2';
  //     line1.strokeOpacity = 0.5;
  //     line1.stroke = interfaceColors.getFor('alternativeBackground');
  //     line1.isMeasured = false;

  //     var line2 = container.createChild(am4core.Line);
  //     line2.strokeDasharray = '2,2';
  //     line2.strokeOpacity = 0.5;
  //     line2.stroke = interfaceColors.getFor('alternativeBackground');
  //     line2.isMeasured = false;

  //     var selectedSlice;

  //     function selectSlice1(dataItem) {
  //       selectedSlice = dataItem.slice;

  //       var fill = selectedSlice.fill;

  //       var count = dataItem.dataContext.subData.length;
  //       // pieSeries2.colors.list = [];

  //       // var colorSet = new am4core.ColorSet();
  //       // colorSet.list = ["#dc3545", "#28a745"].map(function(color) {
  //       //   return new am4core.color(color);
  //       // });
  //       // pieSeries2.colors = colorSet;
  //       // for (var i = 0; i < count; i++) {
  //       //   pieSeries2.colors.list.push(fill.brighten(i * 2 / count));
  //       // }

  //       chart2.data = dataItem.dataContext.subData;
  //       pieSeries2.appear();

  //       var middleAngle = selectedSlice.middleAngle;
  //       var firstAngle = pieSeries.slices.getIndex(0).startAngle;
  //       var animation = pieSeries.animate(
  //         [
  //           { property: 'startAngle', to: firstAngle - middleAngle },
  //           { property: 'endAngle', to: firstAngle - middleAngle + 360 },
  //         ],
  //         600,
  //         am4core.ease.sinOut
  //       );
  //       animation.events.on('animationprogress', updateLines1);

  //       selectedSlice.events.on('transformed', updateLines1);

  //       //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
  //       //  animation.events.on("animationprogress", updateLines)
  //     }

  //     function updateLines1() {
  //       if (selectedSlice) {
  //         var p11 = {
  //           x:
  //             selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
  //           y:
  //             selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
  //         };
  //         var p12 = {
  //           x:
  //             selectedSlice.radius *
  //             am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
  //           y:
  //             selectedSlice.radius *
  //             am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
  //         };

  //         p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
  //         p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

  //         var p21 = { x: 0, y: -pieSeries2.pixelRadius };
  //         var p22 = { x: 0, y: pieSeries2.pixelRadius };

  //         p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
  //         p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

  //         line1.x1 = p11.x;
  //         line1.x2 = p21.x;
  //         line1.y1 = p11.y;
  //         line1.y2 = p21.y;

  //         line2.x1 = p12.x;
  //         line2.x2 = p22.x;
  //         line2.y1 = p12.y;
  //         line2.y2 = p22.y;
  //       }
  //     }

  //     chart.events.on('datavalidated', function () {
  //       setTimeout(function () {
  //         selectSlice1(pieSeries.dataItems.getIndex(0));
  //       }, 1000);
  //     });
  //   }
  // };

  // const getCatagoryChart = (chartData1) => {
  //   if (chartData1.length > 0) {
  //     var container = am4core.create('chartdiv2', am4core.Container);
  //     container.width = am4core.percent(90);
  //     container.height = am4core.percent(100);
  //     container.layout = 'horizontal';
  //     var chart = container.createChild(am4charts.PieChart);
  //     chart.width = am4core.percent(60);
  //     chart.radius = am4core.percent(90);

  //     // Add data
  //     chart.data = chartData1;

  //     // Add and configure Series
  //     var pieSeries = chart.series.push(new am4charts.PieSeries());
  //     pieSeries.dataFields.value = 'categoryIndex';
  //     pieSeries.dataFields.category = 'category';
  //     pieSeries.slices.template.states.getKey(
  //       'active'
  //     ).properties.shiftRadius = 0;
  //     //pieSeries.labels.template.text = "{category}\n{value.percent.formatNumber('#.#')}%";

  //     pieSeries.slices.template.events.on('hit', function (event) {
  //       selectSlice2(event.target.dataItem);
  //     });

  //     var chart2 = container.createChild(am4charts.PieChart);
  //     chart2.width = am4core.percent(40);
  //     chart2.radius = am4core.percent(50);

  //     // Add and configure Series
  //     var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
  //     pieSeries2.dataFields.value = 'value';
  //     pieSeries2.dataFields.category = 'name';
  //     pieSeries2.slices.template.states.getKey(
  //       'active'
  //     ).properties.shiftRadius = 0;
  //     //pieSeries2.labels.template.radius = am4core.percent(50);
  //     //pieSeries2.labels.template.inside = true;
  //     //pieSeries2.labels.template.fill = am4core.color("#ffffff");
  //     // pieSeries2.labels.template.disabled = true;
  //     // pieSeries2.ticks.template.disabled = true;
  //     // pieSeries2.alignLabels = false;
  //     pieSeries2.events.on('positionchanged', updateLines2);

  //     var interfaceColors = new am4core.InterfaceColorSet();

  //     var line1 = container.createChild(am4core.Line);
  //     line1.strokeDasharray = '2,2';
  //     line1.strokeOpacity = 0.5;
  //     line1.stroke = interfaceColors.getFor('alternativeBackground');
  //     line1.isMeasured = false;

  //     var line2 = container.createChild(am4core.Line);
  //     line2.strokeDasharray = '2,2';
  //     line2.strokeOpacity = 0.5;
  //     line2.stroke = interfaceColors.getFor('alternativeBackground');
  //     line2.isMeasured = false;

  //     var selectedSlice;

  //     function selectSlice2(dataItem) {
  //       selectedSlice = dataItem.slice;

  //       var fill = selectedSlice.fill;

  //       var count = dataItem.dataContext.subData.length;
  //       pieSeries2.colors.list = [];

  //       var colorSet = new am4core.ColorSet();
  //       colorSet.list = ['#28a745', '#dc3545'].map(function (color) {
  //         return new am4core.color(color);
  //       });
  //       pieSeries2.colors = colorSet;

  //       chart2.data = dataItem.dataContext.subData;
  //       pieSeries2.appear();

  //       var middleAngle = selectedSlice.middleAngle;
  //       var firstAngle = pieSeries.slices.getIndex(0).startAngle;
  //       var animation = pieSeries.animate(
  //         [
  //           { property: 'startAngle', to: firstAngle - middleAngle },
  //           { property: 'endAngle', to: firstAngle - middleAngle + 360 },
  //         ],
  //         600,
  //         am4core.ease.sinOut
  //       );
  //       animation.events.on('animationprogress', updateLines2);

  //       selectedSlice.events.on('transformed', updateLines2);

  //       //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
  //       //  animation.events.on("animationprogress", updateLines)
  //     }

  //     function updateLines2() {
  //       if (selectedSlice) {
  //         var p11 = {
  //           x:
  //             selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
  //           y:
  //             selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
  //         };
  //         var p12 = {
  //           x:
  //             selectedSlice.radius *
  //             am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
  //           y:
  //             selectedSlice.radius *
  //             am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
  //         };

  //         p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
  //         p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

  //         var p21 = { x: 0, y: -pieSeries2.pixelRadius };
  //         var p22 = { x: 0, y: pieSeries2.pixelRadius };

  //         p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
  //         p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

  //         line1.x1 = p11.x;
  //         line1.x2 = p21.x;
  //         line1.y1 = p11.y;
  //         line1.y2 = p21.y;

  //         line2.x1 = p12.x;
  //         line2.x2 = p22.x;
  //         line2.y1 = p12.y;
  //         line2.y2 = p22.y;
  //       }
  //     }

  //     chart.events.on('datavalidated', function () {
  //       setTimeout(function () {
  //         selectSlice2(pieSeries.dataItems.getIndex(0));
  //       }, 1000);
  //     });
  //   }
  // };

  const handlePopoverOpen = (event, obj) => {
    obj.anchorEl = event.currentTarget;
    setAnchorEl({ obj: obj, state: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEl({ obj: null, state: null });
  };

  const getSuccessResultByProcessOrObject = (data, process) => {
    const passedCases = data.filter(
      (obj) => obj.pass == "Fail" && obj.root == process
    );

    const FailedCases = data.filter(
      (obj) => obj.pass == "Fail"
    );

    if (!passedCases.length) return 0;

    const percentage = parseFloat(
      (passedCases.length / FailedCases.length) * 100
    ).toFixed(1);

    return percentage;
  };

  const open = Boolean(anchorEl.state);

  let exportdata = [];
  if (
    processAnalyzerData &&
    processAnalyzerData.response &&
    processAnalyzerData.response.data
  ) {
    const edata = processAnalyzerData.response.data;
    edata.forEach((d, i) => {
      d.outputMessage.forEach((o, i) => {
        var exportObj = {};
        exportObj.ruleName = d.ruleName;
        exportObj.ruleDescription = d.ruleDescription ? d.ruleDescription : '';
        exportObj.remedy = JSON.parse(d.remedy)?.blocks?.[0]?.text ? JSON.parse(d.remedy)?.blocks?.[0]?.text : '';
        // exportObj.remedy = (d.remedy ? d.remedy : "");
        exportObj.result = d.result && d.result == 'FALSE' ? 'Fail' : 'Pass';
        exportObj.message = o.message ? o.message : '';
        exportObj.pass = o.pass && o.pass == true ? 'Pass' : 'Fail';
        exportObj.root = o.root ? o.root : '';
        exportObj.page = o.page ? o.page : '';
        exportdata.push(exportObj);
      });
    });
  }
  const multiDataSet = [
    {
      columns: [
        { title: 'Rule Name', width: { wpx: 300 } }, //pixels width
        { title: 'Rule Description', width: { wpx: 300 } }, //char width
        // {title: "Result", width: {wpx: 90}},
        { title: 'Observation', width: { wpx: 500 } },
        { title: 'Result', width: { wpx: 90 } },
        { title: 'Process/Object', width: { wpx: 200 } },
        { title: 'Page', width: { wpx: 200 } },
        { title: "Remedy", width: { wpx: 300 } },
      ],
      data: exportdata.map((order, index) => {
        return [
          { value: order.ruleName ? order.ruleName.toString() : '-' },
          {
            value: order.ruleDescription
              ? order.ruleDescription.toString()
              : '-',
          },
          // { value: order.result,  style: order.pass == "Fail" ? ({fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}): ({fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}})},
          { value: order.message ? order.message.toString() : '-' },
          {
            value: order.pass ? order.pass.toString() : '-',
            style:
              order.pass == 'Fail'
                ? {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FFFF0000' },
                  },
                }
                : {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FF00FF00' },
                  },
                },
          },
          { value: order.root ? order.root.toString() : '-' },
          { value: order.page ? order.page.toString() : '-' },
          { value: order.remedy },
        ];
      }),
    },
  ];
  const _multiData = [];
  exportdata.map((order, index) => {
        if (order.root && order.root.length > 0 && _multiData.indexOf(order.root) === -1) {
          _multiData.push(order.root)
        }
  })
  const multiDataList = [
    {
      columns: [
        { title: 'Objects/Processes', width: { wpx: 300 } },
        { title: 'Success %', width: { wpx: 200} }
      ],
      data: _multiData.map((order, index) => {
        return [
          { value: order },
          { value: getSuccessResultByProcessOrObject(exportdata, order)}
        ]
      })
    },
  ];
  const onGeneratePDF = () => {
    const user = getUserDetailsFromJwtToken();
    const res = processAnalyzerData.response;
    const resultStatus = res.success;
    const result = res.data;
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape
    const img = new Image();
    img.src = BinaryWayLogoWhite;
    const marginLeft = 115;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(img, 'png', 20, 20, 100, 80);

    doc.setFontSize(20);
    doc.setFontType('bold');
    doc.text('CodeIQ Analyzer Report', 300, 50, null, null, 'center');
    const date = new Date();
    var formatedDate = moment(date).format('DD/MMM/YYYY HH:mm A');

    const userInfo = `User: ${titleCase(user.firstName)} ${titleCase(
      user.lastName
    )}`;
    doc.setFontType('bold');
    doc.setFontSize(12);
    doc.text(userInfo, 50, 110);

    const todayDate = 'Date: ' + formatedDate;
    doc.text(todayDate, 395, 110);

    const selectedfilenametitle = `\n\nRelease File:  ${processAnalyzerData.selectedFile.name
      ? processAnalyzerData.selectedFile.name.split('.')[0]
      : '-'
      }`;
    doc.text(selectedfilenametitle, 50, 110);

    const analayzerTitle = `\n\nAnalyzer:  ${processAnalyzerData.analyzer.displayName}`;
    doc.text(analayzerTitle, 50, 135);
    // doc.setFontStyle("Arial");

    doc.line(50, 175, 550, 175);

    var head = [['Observation', 'Result', 'Root Process', 'Page']];

    var body = result.map((res) => [
      res.outputMessage
        ? res.outputMessage.map((out) => out.message + '\n\n')
        : '',
      res.outputMessage
        ? res.outputMessage.map((out) =>
          out.pass && out.pass == true ? 'Pass' : 'Fail'
        )
        : '',
      res.outputMessage
        ? res.outputMessage.map((out) => (out.root ? out.root : ''))
        : '',
      res.outputMessage
        ? res.outputMessage.map((out) => (out.page ? out.page : ''))
        : '',
      res.ruleName,
      res.ruleType,
      res.result ? 'Pass' : 'Fail',
      !res.result ? res.remedy : '' || '',
      res.ruleDescription || '',
    ]);
    let ruleList = [];
    let tablebody = [];
    let previousIndex = null;
    body.forEach((b, i) => {
      let result = b[1];
      let root = b[2];
      let page = b[3];
      b[0].forEach((message, index) => {
        var obj = [];
        obj.push(
          message,
          result[index],
          root[index],
          page[index],
          b[4],
          b[5],
          b[6],
          b[7],
          b[8],
          i
        );
        if (previousIndex !== i) {
          ruleList = [];
          ruleList.push(obj);
        } else {
          ruleList.push(obj);
        }
        previousIndex = i;
      });
      tablebody.push(ruleList);
    });

    let previous = null;
    tablebody.forEach((rule, i) => {
      let ruleDetails = rule[0];
      if (previous !== ruleDetails[9]) {
        const ruleName = 'Rule Name:';
        doc.setFontType('normal');
        doc.text(ruleName, 50, i == 0 ? 200 : doc.lastAutoTable.finalY + 30);
        doc.setFontType('normal');
        doc.text(
          ruleDetails[4] || '',
          150,
          i == 0 ? 200 : doc.lastAutoTable.finalY + 30
        );
        doc.setFontType('normal');
        const ruleDescHeader = 'Rule Description:';
        let width = doc.getTextWidth(ruleDetails[8] || '');
        const desc = doc.splitTextToSize(ruleDetails[8], 400);
        doc.text(
          ruleDescHeader,
          50,
          i == 0 ? 220 : doc.lastAutoTable.finalY + 50
        );
        doc.text(desc || '', 150, i == 0 ? 220 : doc.lastAutoTable.finalY + 50);
        const ruleTypeHeader = 'Rule Type:';
        doc.text(
          ruleTypeHeader,
          50,
          i == 0
            ? width > 400
              ? 222
              : 240
            : width > 400
              ? doc.lastAutoTable.finalY + 80
              : doc.lastAutoTable.finalY + 70
        );
        doc.text(
          ruleDetails[5] || '' ? ruleDetails[5] : '',
          150,
          i == 0
            ? width > 400
              ? 222
              : 240
            : width > 400
              ? doc.lastAutoTable.finalY + 80
              : doc.lastAutoTable.finalY + 70
        );
        // const remedyHeader = "Remedy:";
        // const remedyStringText = ruleDetails[7].length ? JSON.parse(ruleDetails[7]).blocks[0].text : ''
        // const remedytext = remedyStringText.length > 140 ? (remedyStringText.substring(0, 140) + '...') : remedyStringText || "";
        // let remedyWidth = doc.getTextWidth(remedytext);
        // let remedy = doc.splitTextToSize(remedytext, 400);
        // doc.text(remedyHeader, 50, i == 0 ? (width > 400 ? 245 : 230) : (width > 400 ? doc.lastAutoTable.finalY + 95 : doc.lastAutoTable.finalY + 90));
        // doc.text((remedy), 150, i == 0 ? (width > 400 ? 245 : 230) : (width > 400 ? doc.lastAutoTable.finalY + 95 : doc.lastAutoTable.finalY + 90));
        const resultHeader = 'Result:';
        doc.text(
          resultHeader,
          50,
          i == 0
            ? width > 400
              ? 245
              : 260
            : width > 400
              ? doc.lastAutoTable.finalY + 95
              : doc.lastAutoTable.finalY + 90
        );
        doc.text(
          ruleDetails[6] || '',
          150,
          i == 0
            ? width > 400
              ? 245
              : 260
            : width > 400
              ? doc.lastAutoTable.finalY + 95
              : doc.lastAutoTable.finalY + 90
        );
      }

      doc.autoTable({
        head: head,
        body: rule,
        startY:
          i == 0
            ? 290
            : previous !== ruleDetails[9]
              ? doc.lastAutoTable.finalY + 125
              : doc.lastAutoTable.finalY + 20,
        styles: { overflow: 'linebreak' },
        headStyles: {
          fillColor: [51, 122, 183],
          textColor: [255],
          halign: 'center',
        },
        //columnWidth: 'wrap',
        columnStyles: {
          0: { cellWidth: 250 },
          1: { cellWidth: 30 },
          1: { cellWidth: 50 },
          2: { cellWidth: 100 },
        },
        theme: 'grid',
      });
      previous = rule[9];
    });
    doc.save(
      processAnalyzerData.selectedFile.name.split('.')[0] +
      '_' +
      processAnalyzerData.analyzer.displayName +
      '_' +
      formatedDate +
      '.pdf'
    );
  };

  const titleCase = (data) => {
    return data
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const getConfigData = async () => {
    const configKeys = await getConfig();
    if (configKeys.success) {
      setConfigData(
        configKeys.data.recordsets[0] &&
          configKeys.data.recordsets[0].length > 0
          ? configKeys.data.recordsets[0].map((y) => {
            const _x = { value: y, displayName: y.NAME };
            return _x;
          })
          : []
      );

      let customCategory = configKeys.data.recordsets[0].filter(
        (data) => data.TYPE === 'CATEGORY'
      );
      customCategory = customCategory.map((category) => {
        category.isChecked = true;
        return category;
      });
      setCustomCategory(customCategory);

      //let groupByFilterListData = [];
      let groupBy = configKeys.data.recordsets[0].filter(
        (data) => data.TYPE === 'RULE_GROUPING_KEY'
      );
      groupBy.map((g) => {
        var group = {};
        group.value = {};
        group.displayName = g.NAME;
        group.value.id = g.ID;
        group.value.name = g.NAME;
        groupByFilterListData.push(group);
      });
      setSelectedGroupByFilter(groupByFilterListData[0]['value']);
    } else {
      //Handle Not correct request here
    }
  };

  const onReset = () => {
    setProcessAnalyzerData({});
    setSelectedResultFilter(ListResultFilter[0]['value']);
    setSelectedGroupByFilter(ListGroupByFilter[0]['value']);
    setSelectedAction(null);
    setAppData({ ...appData, processAnalyzerData: {} });
  };

  const onRefresh = (data) => {
    setOpenChooseAnalyzer(false);
    setOpenProcessAnalyzerDetailDrawer(false);
    setProcessAnalyzerData(data);
    setAppData({ ...appData, processAnalyzerData: data });
  };

  const filterAndShowResult = (selectedItem) => {
    setcurrentProcessAnalyzerRuleData(selectedItem);
    setCurrentRuleForProcessResult(selectedItem);
    setOpenProcessAnalyzerDetailDrawer(true);
  }
  const onViewRule = (index, obj) => {
    setcurrentProcessAnalyzerRuleData(
      currentProcessAnalyzerFilterRuleData[index]
    );
    setCurrentRuleForProcessResult(obj);
    setOpenProcessAnalyzerDetailDrawer(true);
  };

  const onResultFilterChange = (event) => {
    setSelectedResultFilter(event.target.value);
  };

  const onGroupByFilterChange = (event) => {
    setSelectedGroupByFilter(event.target.value);
    setCardView(true);
  };

  const onActionChange = (event) => {
    setSelectedAction(event.target.value);
    if (event.target.value && event.target.value.name === 'pdf') {
      onGeneratePDF();
    }
  };

  const getPassedResult = () => {
    const passedCases = processAnalyzerData.response.data.filter(
      (obj) => obj.result === true
    );

    if (!passedCases.length) return 0;

    const percentage = parseFloat(
      (passedCases.length / processAnalyzerData.response.data.length) * 100
    ).toFixed(1);

    return percentage;
  };

  const setDataToProcessAnalyzer = () => {
    let DataToProcessAnalyzer = [];
    if (selectedGroupByFilter && selectedGroupByFilter.name === 'Rule Wise') {
      processAnalyzerData.response.data &&
        processAnalyzerData.response.data.forEach((obj) => {
          obj.outputMessage.map((x) => {
            x.remedy = obj.remedy;
            x.ruleType = obj.ruleType;
            x.ruleName = obj.ruleName;
            x.ruleCategory = obj.ruleCategory || 'No category';
            x.process = x.process || 'Release Specific Occurrences';
            x.deactive = obj.deactive;
            if (x.pass === true) {
              obj.passedCount = obj.passedCount + 1;
            } else {
              obj.failedCount = obj.failedCount + 1;
            }
          });
          DataToProcessAnalyzer.push({
            title: obj.ruleName,
            description: obj.ruleDescription,
            remedy: obj.remedy,
            ruleCompanyId: obj.ruleCompanyId,
            outputMessage: obj.outputMessage,
            passedCount: obj.passedCount,
            failedCount: obj.failedCount,
          });
        });
    } else if (
      selectedGroupByFilter &&
      selectedGroupByFilter.name === 'Category Wise'
    ) {
      processAnalyzerData.response.data &&
        processAnalyzerData.response.data.map((obj, index) => {
          obj.ruleCategory = obj.ruleCategory || 'No category';
          let categoryIndex = DataToProcessAnalyzer.findIndex(
            (x) => x.title == obj.ruleCategory
          );
          if (categoryIndex == -1) {
            obj.outputMessage.map((x) => {
              x.remedy = obj.remedy;
              x.ruleType = obj.ruleType;
              x.ruleName = obj.ruleName;
              x.ruleCategory = obj.ruleCategory || 'No category';
              x.process = x.process || 'Release Specific Occurrences';
              if (x.pass === true) {
                obj.passedCount = obj.passedCount + 1;
              } else {
                obj.failedCount = obj.failedCount + 1;
              }
            });
            DataToProcessAnalyzer.push({
              title: obj.ruleCategory,
              description: obj.ruleCategoryDes,
              ruleCompanyId: obj.ruleCompanyId,
              outputMessage: obj.outputMessage,
              passedCount: obj.passedCount,
              failedCount: obj.failedCount,
            });
          } else {
            obj.outputMessage &&
              obj.outputMessage.length > 0 &&
              obj.outputMessage.map((x) => {
                x.remedy = obj.remedy;
                x.ruleType = obj.ruleType;
                x.ruleName = obj.ruleName;
                x.ruleCategory = obj.ruleCategory || 'No category';
                x.process = x.process || 'Release Specific Occurrences';
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                }
                DataToProcessAnalyzer[categoryIndex].outputMessage.push(x);
              });
          }
        });
    } else if (
      selectedGroupByFilter &&
      selectedGroupByFilter.name === 'Process Wise'
    ) {
      processAnalyzerData.response.data &&
        processAnalyzerData.response.data.map((obj, index) => {
          obj.outputMessage &&
            obj.outputMessage.length > 0 &&
            obj.outputMessage.map((x) => {
              x.process = x.process || 'Release Specific Occurrences';
              let processIndex = DataToProcessAnalyzer.findIndex(
                (p) => p.title == x.process
              );
              if (processIndex == -1) {
                x.remedy = obj.remedy;
                x.ruleType = obj.ruleType;
                x.ruleName = obj.ruleName;
                x.ruleCategory = obj.ruleCategory || 'No category';
                x.process = x.process || 'Release Specific Occurrences';
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                }
                DataToProcessAnalyzer.push({
                  title: x.process,
                  description: obj.ruleDescription,
                  ruleCompanyId: obj.ruleCompanyId,
                  outputMessage: [x],
                  passedCount: obj.passedCount,
                  failedCount: obj.failedCount,
                });
              } else {
                x.remedy = obj.remedy;
                x.ruleName = obj.ruleName;
                x.ruleType = obj.ruleType;
                x.ruleCategory = obj.ruleCategory || 'No category';
                x.process = x.process || 'Release Specific Occurrences';
                if (x.pass === true) {
                  obj.passedCount = obj.passedCount + 1;
                } else {
                  obj.failedCount = obj.failedCount + 1;
                }
                DataToProcessAnalyzer[processIndex].outputMessage.push(x);
              }
            });
        });
    }
    setcurrentProcessAnalyzerFilterRuleData(DataToProcessAnalyzer);
    function handleChange(checked) {
    }
  };
  const handleCodeIQClick = async (e, id, n) => {
    e.preventDefault();
    if (id <= 0) {
      return;
    }
    let response = await getDownload(id);
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    let now = new Date()
    link.download = `${n.replace('.bprelease', '')}-CodeIQ-${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}-${now.getHours()}${now.getMinutes()}${now.getSeconds()}.bprelease`;
    link.click();
    link.remove();
  };
  return (
    <>
      <div className={classes.processAnalyzerContainer}>
        <Row className="processAnalyzer_header">
          <Col xs="10" style={{ paddingTop: '20px' }}>
            {processAnalyzerData && processAnalyzerData.response && (
              <span
                style={{ float: 'none', fontWeight: 'bold', fontSize: '25px' }}
              >
                {' '}
                {processAnalyzerData.analyzer.displayName}
                {'  '}
              </span>
            )}
          </Col>
          <Col
            xs="2"
            style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px' }}
          >
            <div style={{ float: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.customAnalyzerBtn}
                // variant="outlined"
                onClick={() => toggleAddRule()}
                label="Choose Analyzer"
              >
                Choose Analyzer
              </Button>
            </div>
          </Col>
        </Row>
        {processAnalyzerData && processAnalyzerData.response && (
          <Row className="processAnalyzer_body_background">
            <div
              style={{ marginLeft: '10px' }}
              className={ListProcessAnalyzerCss.procees_list_noData_header}
            >
              <Col xs="5" style={{ float: 'left' }}>
                <div className="processAnalyzer_header_title">
                  {processAnalyzerData && processAnalyzerData.response && (
                    <>
                      <span style={{ marginTop: '-1%', display: 'block' }}>
                        {'  '}
                        <DescriptionIcon />
                      </span>
                      <span
                        className={`${ListProcessAnalyzerCss.procees_list_header_title_sub} filenameSpan`}
                      >
                        {' '}
                        {processAnalyzerData.selectedFile.name}
                        {'  '}
                      </span>
                      {/* <span
                        style={{
                          border: "none",
                          margin: "0px",
                          backgroundColor: "white",
                        }}
                      >
                        {" "}
                        <span
                          onClick={(e) => {
                            handleCodeIQClick(
                              e,
                              processAnalyzerData.response.executionId,
                              processAnalyzerData.selectedFile.name
                            );
                          }}
                        >
                          <Tooltip title="Download CodeIQ bprelease file" placement="bottom">
                            <GetAppIcon />
                          </Tooltip>
                        </span>{" "}
                        <span
                          style={{
                            color: "#63BDF3",
                            fontSize: "1.1rem",
                          }}
                        >
                          {" "}
                          Code IQ Release File.{" "}
                        </span>
                      </span> */}
                      {isCardView !== true && (
                        <span style={{ marginRight: '5px' }}>
                          <Tooltip title="Encounters" placement="bottom">
                            <span
                              className={
                                ListProcessAnalyzerCss.procees_list_header_title_counter
                              }
                            >
                              (<i class="fa fa-circle"></i> : {cardOverallCount}
                              )
                            </span>
                          </Tooltip>
                        </span>
                      )}

                      <span
                        className={
                          ListProcessAnalyzerCss.procees_list_header_title_subgreay
                        }
                      >
                        (Total Results :{' '}
                      </span>
                      <span>
                        {' '}
                        <Tooltip title="Passed" placement="bottom">
                          <span
                            className={
                              ListProcessAnalyzerCss.procees_list_header_title_subgreen
                            }
                          >
                            <i class="fa fa-circle"></i> : {getPassedResult()}%
                          </span>
                        </Tooltip>
                        <Tooltip title="Failed" placement="bottom">
                          <span
                            className={
                              ListProcessAnalyzerCss.procees_list_header_title_subred
                            }
                          >
                            <i class="fa fa-circle"></i> :{' '}
                            {100 - getPassedResult()}%
                          </span>
                        </Tooltip>
                      </span>
                      <span
                        className={
                          ListProcessAnalyzerCss.procees_list_header_title_subgreay
                        }
                      >
                        )
                      </span>
                    </>
                  )}
                </div>
              </Col>

              <Col xs="7" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div
                  className={
                    processAnalyzerData && processAnalyzerData.response
                      ? 'processAnalyzer_stbtn'
                      : 'processAnalyzer_stepper_button'
                  }
                >
                  {appData.Permissions &&
                    getPermissionStatus(
                      appData.Permissions,
                      appConstants.EXECUTE_ANALYZER
                    ) && (
                      <>
                        {processAnalyzerData && processAnalyzerData.response ? (
                          <>
                            <div
                              style={{
                                display: 'inline-block',
                                padding: '0 10px 0 10px',
                              }}
                            >
                              <BinarywayDropdown
                                class="dropdown"
                                placeHolder=""
                                label="GROUP BY"
                                value={selectedGroupByFilter}
                                options={ListGroupByFilter}
                                onChange={(event) =>
                                  onGroupByFilterChange(event)
                                }
                              />
                            </div>
                            {
                              processAnalyzerData.response &&
                                processAnalyzerData.response.data.length > 0 ? (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      padding: '0 10px 0 10px',
                                      borderLeft: '1px solid #edeeed',
                                    }}
                                  >
                                    Chart View
                                    <Switch
                                      checked={isCardView}
                                      onChange={() => {
                                        if (isCardView) {
                                          setCardView(false);
                                        } else {
                                          setCardView(true);
                                        }
                                      }}
                                      color="primary"
                                      name="checkedB"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                Card View
                                  </div>
                                ) : null}
                            <div
                              style={{
                                display: 'inline-block',
                                padding: '0 10px 0 10px',
                                borderLeft: '1px solid #edeeed',
                              }}
                              onClick={() => onReset()}
                            >
                              <span> {'  '}</span>
                              <span style={{ marginRight: '5px' }}>
                                <Tooltip title="Reset" placement="bottom">
                                  <CachedIcon />
                                </Tooltip>
                              </span>
                              <span>Reset</span>
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                padding: '0 10px 0 10px',
                                borderLeft: '1px solid #edeeed',
                              }}
                              onClick={(e) => handleCodeIQClick(e,
                                processAnalyzerData.response.executionId,
                                processAnalyzerData.selectedFile.name)}
                            >
                              <span> {'  '}</span>
                              <span style={{ marginRight: '5px' }}>
                                <Tooltip
                                  title=""
                                  placement="bottom"
                                >
                                  <GetAppIcon />
                                </Tooltip>
                              </span>
                              <span style={{ color: "#63BDF3" }}>CodeIQ Updated</span>
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                padding: '0 10px 0 10px',
                                borderLeft: '1px solid #edeeed',
                              }}
                              onClick={() => onGeneratePDF()}
                            >
                              <span> {'  '}</span>
                              <span style={{ marginRight: '5px' }}>
                                <Tooltip
                                  title="Download PDF"
                                  placement="bottom"
                                >
                                  <GetAppIcon />
                                </Tooltip>
                              </span>
                              <span>PDF</span>
                            </div>
                            {/* <div
                              style={{
                                display: 'inline-block',
                                padding: '0 10px 0 10px',
                                borderLeft: '1px solid #edeeed',
                              }}
                            >
                              <span> {'  '}</span>
                              <span style={{ marginRight: '5px' }}>
                                <ExcelFile
                                  element={
                                    <Tooltip
                                      title="Download Excel"
                                      placement="bottom"
                                    >
                                      <GetAppIcon />
                                    </Tooltip>
                                  }
                                  filename={
                                    processAnalyzerData.selectedFile.name.split(
                                      '.'
                                    )[0] +
                                    '_' +
                                    processAnalyzerData.analyzer.displayName +
                                    '_' +
                                    moment(new Date()).format(
                                      'DD/MMM/YYYY HH:mm A'
                                    )
                                  }
                                >
                                  <ExcelSheet
                                    dataSet={multiDataSet}
                                    name="Result"
                                  />
                                  <ExcelSheet
                                    dataSet={multiDataList}
                                    name="Process and Object Scoring"
                                  />
                                </ExcelFile>
                              </span>
                              <span>Excel</span>
                            </div> */}
                          </>
                        ) : null}
                      </>
                    )}
                </div>
              </Col>
            </div>
          </Row>
        )}

        <Row className="processAnalyzer_body_background">
          <Col
            xs="12"
            className={ListProcessAnalyzerCss.procees_list_body_main}
          >
            <div
              className={ListProcessAnalyzerCss.procees_list_body}
              style={{ overflowX: 'hidden', overflow: 'hidden', marginTop: '0px', paddingTop: '0px' }}
            >
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.EXECUTE_ANALYZER
                ) && (
                  <>
                    {isCardView == true ? (
                      <Row className={classes.cardContainer}>
                        {processAnalyzerData.response &&
                          processAnalyzerData.response.data.length > 0 ? (
                            <>
                              <Col style={{ padding: '5px' }} xs={12}>
                                <div
                                  className={
                                    ListProcessAnalyzerCss.procees_list_paper_left_main_content
                                  }
                                  style={{ overflowX: 'hidden' }}
                                >
                                  {selectedGroupByFilter &&
                                    selectedGroupByFilter.name ===
                                    'Rule Wise' && (
                                      <Row className={classes.cardContainer}>
                                        {processAnalyzerData.response.data
                                          .filter((x) =>
                                            selectedResultFilter.name === 'all'
                                              ? x
                                              : selectedResultFilter.name ===
                                                'pass'
                                                ? x.result
                                                : !x.result
                                          )
                                          .map((obj, index) => {
                                            obj.passedCount = 0;
                                            obj.failedCount = 0;
                                            obj.outputMessage &&
                                              obj.outputMessage.length > 0 &&
                                              obj.outputMessage.map((x) => {
                                                if (x.pass === true) {
                                                  obj.passedCount =
                                                    obj.passedCount + 1;
                                                } else {
                                                  obj.failedCount =
                                                    obj.failedCount + 1;
                                                }
                                              });
                                            return (
                                              <>
                                                {(obj.passedCount > 0 ||
                                                  obj.failedCount > 0) && (
                                                    <Col
                                                      xs={3}
                                                      style={{ padding: 0 }}
                                                      key={index}
                                                    >
                                                      <Card
                                                        className={
                                                          classes.selectedCard
                                                        }
                                                      >
                                                        <Tooltip title={obj.ruleName} placement="bottom"
                                                        classes={{
                                                          tooltip: classes.customTooltip,
                                                          arrow: classes.customArrow
                                                        }}
                                                        arrow>
                                                        <CardHeader
                                                          className={
                                                            classes.cardTitle
                                                          }
                                                          title={obj.ruleName}
                                                        />
                                                        </Tooltip>
                                                        <Tooltip title={obj.ruleDescription} placement="bottom"
                                                        classes={{
                                                          tooltip: classes.customTooltip,
                                                          arrow: classes.customArrow
                                                        }}
                                                        arrow>
                                                        <CardContent
                                                          className={
                                                            classes.cardContent
                                                          }
                                                        >
                                                          {obj.ruleDescription}
                                                        </CardContent>
                                                        </Tooltip>
                                                        <CardActions
                                                          className={
                                                            classes.cardAction
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              classes.tooltipContainer
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                classes.tooltipGreen
                                                              }
                                                            >
                                                              {obj.passedCount}
                                                            </div>
                                                            <div
                                                              className={
                                                                classes.tooltipRed
                                                              }
                                                            >
                                                              {obj.failedCount}
                                                            </div>
                                                          </div>

                                                          <Tooltip title="View Result">
                                                            <div>
                                                              <VisibilityIcon
                                                                className={
                                                                  classes.visibilityIcon
                                                                }
                                                                title="View"
                                                                onClick={() =>
                                                                  onViewMore({
                                                                    NAME:
                                                                      obj.ruleName,
                                                                    DESCRIPTION:
                                                                      obj.ruleDescription,
                                                                    RULE_TYPE:
                                                                      obj.ruleType,
                                                                    COMPONENT_NAME:
                                                                      'test',
                                                                    stageData: [],
                                                                    REMEDY:
                                                                      obj.remedy,
                                                                  })
                                                                }
                                                              />
                                                              <SearchIcon
                                                                onClick={() =>
                                                                  onViewRule(
                                                                    index,
                                                                    obj
                                                                  )
                                                                }
                                                              ></SearchIcon>
                                                            </div>
                                                          </Tooltip>
                                                        </CardActions>
                                                      </Card>
                                                    </Col>
                                                  )}
                                              </>
                                            );
                                          })}
                                      </Row>
                                    )}
                                  {selectedGroupByFilter &&
                                    selectedGroupByFilter.name ===
                                    'Category Wise' && (
                                      <Row className={classes.cardContainer}>
                                        {processAnalyzerData.response.data &&
                                          processAnalyzerData.response.data.map(
                                            (obj, index) => {
                                              let categoryIndex = categoryGroup.findIndex(
                                                (x) =>
                                                  x.category == obj.ruleCategory
                                              );
                                              obj.passedCount = 0;
                                              obj.failedCount = 0;
                                              if (categoryIndex == -1) {
                                                obj.outputMessage &&
                                                  obj.outputMessage.length > 0 &&
                                                  obj.outputMessage.map((x) => {
                                                    if (x.pass === true) {
                                                      obj.passedCount =
                                                        obj.passedCount + 1;
                                                    } else {
                                                      obj.failedCount =
                                                        obj.failedCount + 1;
                                                    }
                                                  });

                                                categoryGroup.push({
                                                  category: obj.ruleCategory,
                                                  description:
                                                    obj.ruleCategoryDes,
                                                  pass: obj.passedCount,
                                                  fail: obj.failedCount,
                                                });
                                              } else {
                                                obj.outputMessage &&
                                                  obj.outputMessage.length > 0 &&
                                                  obj.outputMessage.map((x) => {
                                                    if (x.pass === true) {
                                                      categoryGroup[
                                                        categoryIndex
                                                      ].pass =
                                                        categoryGroup[
                                                          categoryIndex
                                                        ].pass + 1;
                                                    } else {
                                                      categoryGroup[
                                                        categoryIndex
                                                      ].fail =
                                                        categoryGroup[
                                                          categoryIndex
                                                        ].fail + 1;
                                                    }
                                                  });
                                              }
                                              return (
                                                <>
                                                  {(obj.passedCount > 0 || obj.failedCount > 0) &&
                                                    <Col xs={4} style={{ padding: 0 }} key={index}>
                                                      <Card className={classes.selectedCard}  >
                                                        <CardHeader
                                                          className={`${classes.cardTitle}`}
                                                          title={obj.ruleName}
                                                        />
                                                        <CardContent className={classes.cardContent}>
                                                          <span className="showMoreDots">
                                                            {obj.ruleName}
                                                          </span>
                                                        </CardContent>
                                                        <CardActions className={classes.cardAction}>
                                                          <div className={classes.tooltipContainer}>
                                                            <div className={classes.tooltipGreen}>
                                                              {obj.passedCount}
                                                            </div>
                                                            <div className={classes.tooltipRed}>
                                                              {obj.failedCount}
                                                            </div>
                                                          </div>

                                                          <Tooltip title="View Result">
                                                            <div>
                                                              <VisibilityIcon className={classes.visibilityIcon} title="View" onClick={() => onViewMore({
                                                                NAME: obj.ruleName,
                                                                DESCRIPTION: obj.ruleDescription,
                                                                RULE_TYPE: obj.ruleType, COMPONENT_NAME: 'test', stageData: [], REMEDY: obj.remedy
                                                              })} />
                                                              <SearchIcon onClick={() => onViewRule(index, obj)}></SearchIcon>
                                                            </div>
                                                          </Tooltip>
                                                        </CardActions>
                                                      </Card>
                                                    </Col>
                                                  }
                                                </>
                                              )
                                            }
                                          ) &&
                                          categoryGroup.length > 0 &&
                                          categoryGroup.map((cat, index) => {
                                            return (
                                              <>
                                                {(cat.pass > 0 ||
                                                  cat.fail > 0) && (
                                                    <Col
                                                      xs={3}
                                                      style={{ padding: 0 }}
                                                      key={index}
                                                    >
                                                      <Card
                                                        className={
                                                          classes.selectedCard
                                                        }
                                                      >
                                                        <CardHeader
                                                          className={
                                                            classes.cardTitle
                                                          }
                                                          title={cat.category}
                                                        />
                                                        <CardContent
                                                          className={
                                                            classes.cardContent
                                                          }
                                                        >
                                                          {cat.description}
                                                        </CardContent>
                                                        <CardActions
                                                          className={
                                                            classes.cardAction
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              classes.tooltipContainer
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                classes.tooltipGreen
                                                              }
                                                            >
                                                              {cat.pass}
                                                            </div>

                                                            <div
                                                              className={
                                                                classes.tooltipRed
                                                              }
                                                            >
                                                              {cat.fail}
                                                            </div>
                                                          </div>
                                                          <Tooltip title="View Result">
                                                            <SearchIcon
                                                              onClick={() =>
                                                                onViewRule(
                                                                  index,
                                                                  cat
                                                                )
                                                              }
                                                            ></SearchIcon>
                                                          </Tooltip>
                                                        </CardActions>
                                                      </Card>
                                                    </Col>
                                                  )}
                                              </>
                                            );
                                          })}
                                      </Row>
                                    )}
                                  {selectedGroupByFilter &&
                                    selectedGroupByFilter.name ===
                                    'Process Wise' && (
                                      <Row className={classes.cardContainer}>
                                        {processAnalyzerData.response.data &&
                                          processAnalyzerData.response.data.map(
                                            (obj, index) => {
                                              obj.outputMessage &&
                                                obj.outputMessage.length > 0 &&
                                                obj.outputMessage.map((x) => {
                                                  x.process =
                                                    x.process ||
                                                    'Release Specific Occurrences';
                                                  let processIndex = processGroup.findIndex(
                                                    (p) => p.process == x.process
                                                  );

                                                  obj.failedCount = 0;
                                                  obj.passedCount = 0;
                                                  if (processIndex == -1) {
                                                    if (x.pass === true) {
                                                      obj.passedCount =
                                                        obj.passedCount + 1;
                                                      processGroup.push({
                                                        process: x.process,
                                                        root: x.root,
                                                        description: x.message,
                                                        pass: obj.passedCount,
                                                        fail: 0,
                                                      });
                                                    } else {
                                                      obj.failedCount =
                                                        obj.failedCount + 1;
                                                      processGroup.push({
                                                        process: x.process,
                                                        root: x.root,
                                                        description: x.message,
                                                        pass: 0,
                                                        fail: obj.failedCount,
                                                      });
                                                    }
                                                  } else {
                                                    if (x.pass === true) {
                                                      obj.passedCount =
                                                        obj.passedCount + 1;
                                                      processGroup[
                                                        processIndex
                                                      ].pass =
                                                        processGroup[processIndex]
                                                          .pass + obj.passedCount;
                                                    } else {
                                                      obj.failedCount =
                                                        obj.failedCount + 1;
                                                      processGroup[
                                                        processIndex
                                                      ].fail =
                                                        processGroup[processIndex]
                                                          .fail + obj.failedCount;
                                                    }
                                                  }
                                                });
                                            }
                                          ) &&
                                          processGroup.map((pro, index) => {
                                            pro.description = 'No desc';
                                            processAnalyzerData.response.processNameData.forEach(
                                              (pn) => {
                                                if (
                                                  pro.root ===
                                                  `${pn.type} - ${pn.name}`
                                                ) {
                                                  pro.description =
                                                    pn.description;
                                                }
                                              }
                                            );
                                            return (
                                              <>
                                                {(pro.pass > 0 ||
                                                  pro.fail > 0) && (
                                                    <Col
                                                      xs={3}
                                                      style={{ padding: '0px' }}
                                                      key={index}
                                                    >
                                                      <Card
                                                        className={
                                                          classes.selectedCard
                                                        }
                                                      >
                                                        <CardHeader
                                                          title={pro.process}
                                                          className={
                                                            classes.cardTitle
                                                          }
                                                        />
                                                        <CardContent
                                                          className={
                                                            classes.cardContent
                                                          }
                                                        >
                                                          {pro.description}
                                                        </CardContent>
                                                        <CardActions
                                                          className={
                                                            classes.cardAction
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              classes.tooltipContainer
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                classes.tooltipGreen
                                                              }
                                                            >
                                                              {' '}
                                                              {pro.pass}
                                                            </div>
                                                            <div
                                                              className={
                                                                classes.tooltipRed
                                                              }
                                                            >
                                                              {' '}
                                                              {pro.fail}
                                                            </div>
                                                          </div>
                                                          <Tooltip title="View Result">
                                                            <SearchIcon
                                                              onClick={() =>
                                                                onViewRule(
                                                                  index,
                                                                  pro
                                                                )
                                                              }
                                                            ></SearchIcon>
                                                          </Tooltip>
                                                        </CardActions>
                                                      </Card>
                                                    </Col>
                                                  )}
                                              </>
                                            );
                                          })}
                                      </Row>
                                    )}
                                </div>
                              </Col>
                            </>
                          ) : (
                            <div
                              className={
                                ListProcessAnalyzerCss.procees_list_noData_body
                              }
                              onClick={() => toggleAddRule()}
                              style={{ cursor: 'pointer' }}
                            >
                              <div>
                                <img
                                  className={
                                    ListProcessAnalyzerCss.procees_list_noData_body_image
                                  }
                                  src={RuleListPng}
                                  alt="BinaryWayLogo"
                                />
                              </div>
                              <div
                                className={
                                  ListProcessAnalyzerCss.procees_list_noData_body_content
                                }
                                onClick={() => toggleAddRule()}
                                style={{ cursor: 'pointer' }}
                              >
                                Click anywhere to Analyze your release file
                            </div>
                            </div>
                          )}
                      </Row>
                    ) : (
                        selectedGroupByFilter && (
                          <div className="row cd-pi-row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-5">
                                  {' '}
                                  <h1 className="header">
                                    CodeIQ Execution Statistics - {selectedGroupByFilter.name}
                                  </h1>
                                </div>
                                <div className="col-sm-4">
                                  <h1 className="header fr">{selectedGroupByFilter.name}</h1>
                                </div>
                                <div className="col-sm-1">
                                  <Switch
                                    className="fr cd-switch"
                                    onChange={() => {
                                      if (chartView) {
                                        setChartView(false);
                                      } else {
                                        setChartView(true);
                                      }
                                    }}
                                    checked={chartView}
                                  />
                                </div>
                                <div className="col-sm-2">
                                  {' '}
                                  <h1 className="header fl">
                                    Result Wise
                                </h1>
                                </div>
                              </div>
                            </div>
                            <div className="divider"></div>
                            <div className="col-sm-12">
                              {chartView ? overallChartData : categoryChartData}
                            </div>
                          </div>
                        )
                      )}
                  </>
                )}
            </div>
          </Col>
        </Row>
      </div>
      <CreateProcessAnalyzer
        isOpen={isOpenChooseAnalyzer}
        setOpen={setOpenChooseAnalyzer}
        onRefresh={onRefresh}
      />
      <ProcessAnalyzerDetailDrawer
        isOpen={isOpenProcessAnalyzerDetailDrawer}
        setOpen={setOpenProcessAnalyzerDetailDrawer}
        currentProcessAnalyzerRuleData={currentProcessAnalyzerRuleData}
        currentRuleForProcessResult={currentRuleForProcessResult}
        selectedGroupByFilter={selectedGroupByFilter}
        onRefresh={onRefresh}
      />
      <RuleDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
        // onDeleteRule={onDeleteRule}
        onDeleteRule={() => { }}
      />
    </>
  );
};

export default ListProcessAnalyzer;
