import React, { Component } from "react";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import { withStyles } from "@material-ui/core/styles";
import BinaryWayLogoWhite from "../../assets/BinaryWayLogoWhite.png";
import bluePrism from "../../assets/bluePrism.png";
import loginIcon from "../../assets/loginIconBlue.png";
import LoginClass from "./Login.module.css";
import BinarywayButton from "../UI-Components/Button/Button";
import BinarywayInput from "../UI-Components/Input/Input";
import { userLogin, setCookie } from "../../service/service";
import { Grid, Box, Typography, Divider, TextField } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { emailRegex } from "./../../utils/helper";

const styles = (theme) => ({
  loginScreenRoot: {
    padding: 17,
    height: "100%",
  },
  loginScreenLeftSideContainer: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  innerContainer: {
    maxWidth: "80%",
    margin: "auto",
  },
  loginScreenRightSideContainer: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: "white",
  },
  loginScreenLeftSideHeroText: {
    color: "white",
    fontSize: 32,
    marginTop: "2%",
  },
  loginScreenLeftSideLongText: {
    color: "#3292e0",
    fontSize: 18,
    paddingBottom: 30,
    marginTop: 10,
  },
  loginScreenBluePrismImage: {
    width: "70%",
    height: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
    marginTop: 15,
  },
  loginScreenBlueprismTitle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "6px",
    marginTop: 21,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      textAlign: "center",
    },
  },
  loginScreenBluePrismDescription: {
    color: "white",
    fontSize: 12,
    marginTop: 7,
    maxWidth: "78%",
    textAlign: "justify",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      maxWidth: "100%",
    },
  },
  loginScreenBenefitList: {
    border: "1px dotted white",
    margin: "auto",
    backgroundColor: "#0051b5",
    padding: 5,
    paddingBottom: 30,
    marginBottom: 20,
    marginTop: 60,
    borderRadius: 12,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  loginScreenRightSideIcon: {
    width: "7%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },

  loginScreenRightSideTitle: {
    fontSize: 32,
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },

  loginScreenBenefitListTitle: {
    color: "white",
    fontSize: 14,
    marginLeft: 20,
    fontWeight: "bold",
    margin: 10,
  },
  loginScreenTextInput: {
    width: "60%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  firstInputStyle: {
    width: "60%",
    margin: "auto",
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginBottom: 25,
    },
  },
  loginScreenForgotPassword: {
    color: "grey",
    backgroundColor: "transparent",
    border: 0,
    fontSize: 13,
    "&:hover": {
      color: "grey",
      backgroundColor: "transparent",
    },
  },
  loginScreenFooter: {
    position: "absolute",
    bottom: 20,
  },
  loginScreenFooterText: {
    fontSize: 12,
    
  },
  inputBox: {
    borderColor: blue[500],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: blue[500],
      },
      "&:hover fieldset": {
        borderColor: blue[500],
      },
      "&.Mui-focused fieldset": {
        borderColor: blue[500],
      },
    },
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      passWord: null,
      isVaildResponse: true,
      inValidDetails: false,
      disableLogin: false,
      usernameError: "",
      isValidUsername: true,
      passwordError: "",
      isValidPassword: true,
    };
  }

  componentDidMount() {
    const { userName, passWord } = this.state;
    // var d = document.getElementById("test");
    // d.click();
    if (userName && passWord) {
      this.setState({ disableLogin: false });
    } else {
      this.setState({ disableLogin: true });
    }
  }

  validateEmail = () => {
    const { userName } = this.state;
    if (!userName) {
      this.setState({
        usernameError: "Email is required",
        isValidUsername: false,
      });

      return false;
    }

    if (!emailRegex.test(String(userName).toLowerCase())) {
      this.setState({
        usernameError: "Invalid Email",
        isValidUsername: false,
      });
      return false;
    } else
      this.setState({
        usernameError: "",
        isValidUsername: true,
      });
    return true;
  };

  validatePassword = () => {
    const { passWord } = this.state;
    if (!passWord) {
      this.setState({
        isValidPassword: false,
        passwordError: "Password Cannot be empty",
      });
    } else {
      this.setState({
        isValidPassword: true,
        passwordError: "",
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      isVaildResponse,
      inValidDetails,
      isValidUsername,
      usernameError,
      isValidPassword,
      passwordError,
      disableLogin,
      passWord,
      userName,
    } = this.state;
    return (
      <Box bgcolor="#edeeed" className={classes.loginScreenRoot}>
        <Grid container id="gridtest">
          <Grid item md={7} xl={7} container>
            <Box
              bgcolor="#0f183f"
              className={classes.loginScreenLeftSideContainer}
            >
              <Box className={classes.innerContainer}>
                <Box pt={"2%"} pb={"2%"}>
                  <Grid container item justify="center">
                    <img
                      className={LoginClass.login_image}
                      src={BinaryWayLogoWhite}
                      alt="BinaryWayLogo"
                    />
                  </Grid>
                </Box>
                <Box>
                  <Typography
                    align="center"
                    className={classes.loginScreenLeftSideHeroText}
                  >
                    Welcome to CodeIQ
                  </Typography>
                  <Typography
                    align="center"
                    className={classes.loginScreenLeftSideLongText}
                  >
                    CodeIQ - SOC 2 Compliance Ready SaaS Model Solution
                  </Typography>
                </Box>
                <Grid container direction="row" justify="center" spacing={2}>
                  <Grid item sm={5} container justify="flex-end">
                    <img
                      className={classes.loginScreenBluePrismImage}
                      src={bluePrism}
                      alt="BluePrismLogo"
                    />
                  </Grid>
                  <Grid item sm={7}>
                    <Box>
                      <Typography className={classes.loginScreenBlueprismTitle}>
                        Driving Scalable Deployments
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.loginScreenBluePrismDescription}
                      >
                        CodelQ is an intelligent and versatile code review
                        platform that instantly checks your code in line with
                        Blue Prism and company specific custom best practices.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  mt={4}
                  mb={5}
                  className={classes.loginScreenBenefitList}
                  maxWidth="80%"
                  margin="auto"
                >
                  <Grid container direction="column">
                    <Typography className={classes.loginScreenBenefitListTitle}>
                      Driving scalable deployments that adhere to industry
                      standards and best practices.
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                      <Grid item sm={5}>
                        <ul className={LoginClass.benefitsList}>
                          <li>Efficient and Effective Code Review</li>
                          <li>Easy and Customizable</li>
                          <li>Time and Cost Effective</li>
                        </ul>
                      </Grid>
                      <Grid item sm={7}>
                        <ul className={LoginClass.benefitsList}>
                          <li>Various Deployment Models and Integrations</li>
                          <li>Knowledge Base of Best Practices</li>
                          <li>Scalable</li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            md={5}
            xl={5}
            item
            direction="column"
            container
            className={classes.loginScreenRightSideContainer}
          >
            <Box mt={16}>
              <Grid container item justify="center">
                <img
                  className={classes.loginScreenRightSideIcon}
                  src={loginIcon}
                  alt="BinaryWayLogo"
                />
              </Grid>
              <Typography
                align="center"
                className={classes.loginScreenRightSideTitle}
              >
                Sign In
              </Typography>
            </Box>
            {isVaildResponse === false && (
              <div className={LoginClass.login_error}>
                Email id or password incorrect
              </div>
            )}
            {inValidDetails === true && (
              <div className={LoginClass.login_error}>
                Please enter an valid User name and Password.
              </div>
            )}
            <Box mt={5}>
              <Grid container item direction="column" justify="center">
                <div className={classes.firstInputStyle}>
                  <TextField
                    className={classes.inputBox}
                    label={"Email Address*"}
                    type={"text"}
                    error={!isValidUsername}
                    helperText={usernameError}
                    fullWidth={true}
                    variant="outlined"
                    onChange={(event) => this.setDetails("UserName", event)}
                    onBlur={this.validateEmail}
                  />
                </div>
                <div className={classes.loginScreenTextInput}>
                  <TextField

                    className={classes.inputBox}
                    label={"Password*"}
                    type={"password"}
                    onBlur={this.validatePassword}
                    error={!isValidPassword}
                    helperText={passwordError}
                    variant="outlined"
                    onChange={(event) => this.setDetails("Password", event)}
                    fullWidth={true}
                  ></TextField>
                </div>

                <Box width="60%" margin="auto" mt={5}>
                  <Grid container item justify="space-between">
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        className={classes.loginScreenForgotPassword}
                      >
                        Forgot Password?
                      </Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <BinarywayButton
                        label={"sign in"}
                        type={"submit"}
                        onClick={() => this.onLogin()}
                      ></BinarywayButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
            <Box
              borderTop={1}
              borderColor="#dcdddc"
              width="96%"
              pt={2}
              margin="auto"
              mt={"13.5%"}
              pb={2}
            >
              <Grid container justify="center">
                <Typography
                  variant="subtitle1"
                  className="loginScreenFooterText"
                >
                  Copyright © Binayway LLC 2021
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  setDetails(type, event) {
    const { userName, passWord } = this.state;
    if (type === "UserName") {
      this.setState({ userName: event.target.value });
    } else if (type === "Password") {
      this.setState({ passWord: event.target.value });
    }

    if (userName && passWord) {
      this.setState({
        inValidDetails: false,
        isVaildResponse: true,
        disableLogin: false,
      });
    } else {
      this.setState({ disableLogin: true });
    }
  }

  onLogin = () => {
    const { userName, passWord } = this.state;
    if (userName && passWord) {
      this.setState({ inValidDetails: false });
      const body = {
        username: userName,
        password: passWord,
      };
      userLogin(body).then((result) => {
        if (result && result.error == null) {
          this.setState({ isVaildResponse: true });
          setCookie(result.token);
          if (result.role === "user") {
            this.props.history.push("/Dashboard/Rules");
          } else {
            this.props.history.push("/Dashboard/Rules");
          }
        } else {
          this.setState({ isVaildResponse: false });
        }
      });
    } else {
      this.setState({ inValidDetails: true });
    }
  };
}

export default withStyles(styles)(Login);
