import React, { useRef, useState,  useEffect, useContext } from 'react'
import MUIRichTextEditor from 'mui-rte';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import BackupIcon from '@material-ui/icons/Backup'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { AppContext } from "../../../service/context";

import {
    uploadFromRichText,
} from "../../../service/service";
import { allowedFiles } from '../../../service/config';

const cardPopverStyles = makeStyles({
    root: {
        padding: 10,
        maxWidth: 350,
        overflow: "hidden",
    },
    textField: {
        width: "100%"
    },
    input: {
        display: "none"
    }
})

const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return new Promise(async (resolve, reject) => {
        const result = await uploadFromRichText(formData)
        if (!result) {
            reject()
            return
        }
        resolve({
            data: {
                url: result.data.data,
                width: 300,
                height: 200,
                alignment: "left", // or "center", "right"
                type: "image" // or "video"
            }
        })
    })
}

const UploadImagePopover = (props) => {
    const classes = cardPopverStyles(props)
    const [state, setState] = useState({
        anchor: null,
        isCancelled: false
    })
    const [data, setData] = useState({})
    const { appData, setAppData } = useContext(AppContext);

    const onAlert = (bol, msg) => {
        appData.ShowAlert = {
          bool: true,
          msg: msg,
          type: bol ? "success" : "error",
        };
        setAppData({ ...appData, ShowAlert: appData.ShowAlert });
    }

    useEffect(() => {
        setState({
            anchor: props.anchor,
            isCancelled: false
        })
        setData({
            file: undefined
        })
    }, [props.anchor])

    return (
        <Popover
            anchorEl={state.anchor}
            open={state.anchor !== null}
            onExited={() => {
                props.onSubmit(data, !state.isCancelled)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={10}>
                    <TextField 
                        className={classes.textField} 
                        disabled
                        value={data.file?.name || ""}
                        placeholder="Click icon to attach image"
                    />
                </Grid>
                <Grid item xs={2}>
                    <input
                        accept="image/jpeg, image/jpg, image/png, image/tiff"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                            const _file = event.target.files[0];
                            if (event.target.files && allowedFiles.includes(_file?.name?.split('.')[1]?.toLowerCase())) {
                                setData({
                                    ...data,
                                    file: (_file ? _file : null)
                                })
                            } else {
                                const _msg = "File format is not supported. Support only PNG, JPEG, JPG, TIFF";
                                onAlert(false, _msg);
                            }
                        }}
                    />
                    <label htmlFor="contained-button-file">
                        <IconButton color="primary" aria-label="upload image" component="span">
                            <AttachFileIcon />
                        </IconButton>
                    </label>
                </Grid>
                <Grid item container xs={12} justify="flex-end">
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: true
                        })
                    }}
                    >
                        <CloseIcon />
                    </Button>
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: false
                        })
                    }}
                    >
                        <DoneIcon />
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    )
}

const BinarywayRichTextEditor = (props) => {
    const ref = useRef(null)
    const [anchor, setAnchor] = useState(null)

    const handleFileUpload = (file) => {
        ref.current.insertAtomicBlockAsync("IMAGE", uploadImage(file), "Uploading now...")
    }

    const onChangeObject = (obj) =>{
        props.onChange(obj);
    }

    return (
        <>
            <UploadImagePopover 
                anchor={anchor}
                onSubmit={(data, insert) => {
                    if (insert && data.file) {
                        handleFileUpload(data.file)
                    }
                    setAnchor(null)
                }}
            />
            <MUIRichTextEditor
                error={props.error}
                ref={ref}
                controls={["title", "bold", "italic", "underline", 
                "strikethrough", "highlight", "undo", "redo", 
                "link", "media", "numberList", "bulletList", 
                "quote", "code", "upload-image"]}
                onChange={(event)=>onChangeObject(event)}
                defaultValue={props.defaultValue}
                inlineToolbar={props.inlineToolbar}
                customControls={[
                    {
                        name: "upload-image",
                        icon: <BackupIcon />,
                        type: "callback",
                        onClick: (_editorState, _name, anchor) => {
                            setAnchor(anchor)
                        }
                    }
                ]}
                draftEditorProps={{
                    handleDroppedFiles: (_selectionState, files) => {
                        if (files.length && (files[0]).name !== undefined) {
                            handleFileUpload(files[0])
                            return "handled"
                        }
                        return "not-handled"
                    }
                }}
            />
        </>
    )
}

export default BinarywayRichTextEditor;