import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Container } from "reactstrap";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {
  getRuleData,
  getPermissionStatus,
  saveAnalyzer,
  getCompanyListDetails,
  getUserDetailsFromJwtToken,
  updateAnalyzer,
  getConfig,
} from "../../../../service/service";

import BinarywayButton from "../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayInput from "../../../UI-Components/Input/Input";
import { AppContext, AnalyzerContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import AnalyzerCreateCss from "./AnalyzerCreate.module.css";
import RuleDetailsDrawer from "../../Rules/ListRules/RuleDetailsDrawer/RuleDetailsDrawer";
import {
  Grid,
  Box,
  Typography,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { options } from "@amcharts/amcharts4/core";
import { filter } from "lodash";
import "./secondary.css";
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid lightgrey",
    borderRadius: 12,
    marginBottom: 10,
  },
  cardTitltContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingBottom: 0,
    textOverflow:'ellipsis',
    overflow:'hidden',
  },
  cardFooterContainer: {
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  cardMainDescription: {
    marginBottom: 7,
    fontSize: 14,
    color: "#000000",
    fontWeight: "normal",
    padding: 5,
  },
  cardNumOfRules: {
    color: "#919294",
    marginRight: 3,
    fontSize: 14,
    padding: 5,
  },
  cardTitle: {
    // padding: 4,
    // margin: 5,
    // marginTop: 10,
    color: "white",
    fontSize: 14,
  },
  cardTitltContainerCheckbox: {
    margin: "10px",
    float: "right",
    background: "black",
  },
  saveUpdateButtton: {
    boxShadow: "0 2px 3px 0 #000000",
    backgroundColor: "#0051b5",
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    color: "black",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  autoCompleteTags: {
    color: "white",
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: "#0051b5",
    fontSize: "12px",
  },
  searchInput: {
    width: "432px",
    // height: 40px;
    margin: "0 20px",
    // padding: 7px 16px 8px;
    borderRadius: "12px",
    boxShadow: "0 2px 2px 0 var(--black-30)",
    border: "solid 1px #dcdddc",
    backgroundColor: "#ffffff",
  },
  searchBarLeftContainer: {
    display: "flex",
  },
  selectAllContainer: {
    display: "flex",
    alignItems: "center",
    marginRight:'5px',
  },
  ruleCountTypography: {
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#919294",
  },
  ruleCountTypographytagline: {
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#919294",
  },
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
      color: "black",
    },
  },
}));

export const AnalyzerCreate = (props) => {
  const classes = useStyles();
  const [filteredRules, SetFilteredRules] = useState([]);
  const [rules, SetRules] = useState([]);
  const [companyList, SetCompanyList] = useState([]);
  const [categoryList, SetCategoryList] = useState([]);
  const [ruleList, SetRuleListWithCategory] = useState([]);
  const [userDetails, SetUserDetails] = useState({});
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedRuleCount, setSelectedRuleCount] = useState([]);
  const [searchParamas, setSearchParams] = useState("");
  const [indeterminate, setindeterminate] = useState(false);

  const [CreateAnalyzerDetails, SetCreateAnalyzerDetails] = useState({
    groups: [],
    ruleErrorMessage: "",
    nameErrorMessage: "",
    analyzerName: "",
    analyzerDescription: "",
    checkName: false,
    disbaleBtn: false,
    companyId: null,
    selectAll: {
      value: false,
      name: "",
    },
    editAnalyzer: false,
  });
  const { appData, setAppData } = useContext(AppContext);
  const { analyzerData, setAnalyzerData } = useContext(AnalyzerContext);
  useEffect(() => {
    onLoadData();
  }, []);
  useEffect(() => {
    let filteredData = [];
    filteredData = rules.filter((obj) => {
      if (obj.NAME.toLowerCase().includes(searchParamas.toLowerCase())) {
        return true;
      }
      return false;
    });
    SetFilteredRules(filteredData);
  }, [searchParamas]);
  useEffect(() => {
    var count = rules.filter((rule) => {
      return rule.checked ? true : false;
    }).length;

    if (count.length === rules.length) {
      setindeterminate(false);
    } else if (count.length > 0) {
      setindeterminate(true);
    } else {
      setindeterminate(false);
    }
    setSelectedRuleCount(count.length);
  }, []);
  useEffect(() => {setSelectedCategory(selectedCategory)}, [selectedCategory]);
  const onLoadData = async () => {
    const _userDetails = getUserDetailsFromJwtToken();

    SetUserDetails(_userDetails);
    SetCreateAnalyzerDetails({
      ...CreateAnalyzerDetails,
      companyId: _userDetails.compid,
    });

    let result1 = await getRuleData();
    if (result1.success === true) {
      SetRules(result1.data);
      SetFilteredRules(result1.data);
    }

    //Category list-----
    let categoriesList = [{ displayName: "All" }];
    const configKeys = await getConfig();
    const data =
      configKeys.data.recordsets[0] && configKeys.data.recordsets[0].length > 0
        ? configKeys.data.recordsets[0].map((obj) => {
            if (obj.TYPE === "CATEGORY") {
              categoriesList.push({ value: obj, displayName: obj.NAME });
            }
          })
        : [];

    SetCategoryList(categoriesList);
    if (categoriesList.length) {
      setSelectedCategory(categoriesList[0]);
      let filteredRules = [];
      // result1 && result1.data.map((rule) => {
      //   if (rule.CATEGORY_ID === categoriesList[0].value.ID)
      //     filteredRules.push(rule);
      // });
      SetFilteredRules(result1.data);
    }

    //SetRuleListWithCategory()
    //--------

    if (_userDetails && _userDetails.role === "super admin") {
      let result3 = await getCompanyListDetails();
      const _companyList =
        result3 && result3.data && result3.data.length > 0
          ? result3.data.map((y) => {
              const _x = { value: y.id, displayName: y.name };
              return _x;
            })
          : [];
      SetCompanyList(_companyList);
      if (
        analyzerData.viewType === "edit" ||
        analyzerData.viewType === "view"
      ) {
        editAnalyzerHandler(result1.data, _companyList, _userDetails);
      }
    } else {
      if (
        analyzerData.viewType === "edit" ||
        analyzerData.viewType === "view"
      ) {
        editAnalyzerHandler(result1.data, null, _userDetails);
      }
    }
  };

  const onViewRuleDetails = (obj) => {
    setCurrentRule(obj);
    setOpenViewMore(true);
  };

  const handleSelectRule = (e, id) => {
    rules[id].checked = e.target.checked;

    SetCreateAnalyzerDetails({
      ...CreateAnalyzerDetails,
      ruleErrorMessage: "",
    });
  };

  const [selectedRules, setSelectedRules] = useState(0);
  const handleSelectWithId = (e, id, isSingle = true) => {
    if (e.target.checked === false && allSelected === true) {
      setindeterminate(true);
    }
    /* if (analyzerData.viewType === "edit") {
      analyzerData.selectedObj.rules.map((rule) => {
        rules.map((r) => {
          if (rule.id === id) {
            r.checked = e.event.checked;
          }
        });
      });
    } */
    var tempRules = rules;
    const newRules = rules.map((rule) => {
      if (rule.ID === id) {
        rule.checked = e.target.checked;
      }
      return rule;
    });
    SetRules(newRules);
    if (isSingle) {
      const newFilterRule = filteredRules.map((rule) => {
        if (rule.ID == id) {
          rule.checked = e.target.checked;
        }
        return rule;
      });
      SetFilteredRules(newFilterRule);
    }
    var count = 0;
    rules.map((rule) => {
      if (rule.checked) {
        count++;
      }
    });
    setSelectedRules(count);
    if (count > 0) {
      setindeterminate(true);
    }
  };

  const handleCreateGroup = (e) => {
    e.preventDefault();
    if (CreateAnalyzerDetails.analyzerName === "") {
      return toast.error("Enter the analyzer name", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (CreateAnalyzerDetails.analyzerDescription === "") {
      return toast.error("Enter the analyzer description", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    SetCreateAnalyzerDetails({ ...CreateAnalyzerDetails, disbaleBtn: true });
    const {
      groups,
      analyzerName,
      analyzerDescription,
      companyId,
    } = CreateAnalyzerDetails;
    if (analyzerData.viewType === "edit") {
      const temp = rules.map((rule) => {
        analyzerData.selectedObj.rules.map((r) => {
          if (r.id === rule.ID && rule.checked != false) {
            rule.checked = true;
          }
        });
        return rule;
      });
      SetRules(temp);
    }
    var groupRules = rules.filter((obj) => obj.checked);

    /* var groupRules = rules.map((rule) => {
      if (rule.checked) {
        return rule;
      }
    }); */
    /* console.log("Edit Selected Rules: ");
    console.log(groupRules);
    console.log(analyzerData.selectedObj.rules); */
    if (
      userDetails.role === "super admin" &&
      (!companyId || companyId.id === null)
    ) {
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        companyNameErrorMessage: "Please select a company",
        disbaleBtn: false,
      });
    } else if (
      groupRules.length > 0 &&
      analyzerName !== "" &&
      analyzerDescription != "" &&
      analyzerData.viewType == "edit"
    ) {
      const newGroup = {
        name: analyzerName,
        description: analyzerDescription,
        rules: groupRules,
        analyzerId: CreateAnalyzerDetails.analyzerId,
      };

      updateAnalyzer(newGroup).then((result) => {
        onLoadData();
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          analyzerName: "",
          analyzerDescription: "",
          groups,
          nameErrorMessage: "",
          ruleErrorMessage: "",
          companyNameErrorMessage: "",
          disbaleBtn: false,
        });
        const _rules = rules.map((rule) => ({ ...rule, checked: false }));
        SetRules(_rules);
        appData.ShowAlert = {
          bool: true,
          msg: "Analyzer Updated Successfully",
          type: "success",
        };
        setAppData({ ...appData, ShowAlert: appData.ShowAlert });
        onBack();
      });
    } else if (
      groupRules.length > 0 &&
      analyzerName !== "" &&
      analyzerDescription != "" &&
      companyId
    ) {
      const newGroup = {
        name: analyzerName,
        description: analyzerDescription,
        rules: groupRules,
        companyId: companyId,
      };
      groups.push(newGroup);

      saveAnalyzer(newGroup).then((result) => {
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          analyzerName: "",
          analyzerDescription: "",
          groups,
          nameErrorMessage: "",
          ruleErrorMessage: "",
          companyNameErrorMessage: "",
          disbaleBtn: false,
        });
        SetRules({
          rules: rules.map((rule) => ({ ...rule, checked: false })),
        });
        appData.ShowAlert = {
          bool: true,
          msg: "Analyzer Added Successfully",
          type: "success",
        };
        setAppData({ ...appData, ShowAlert: appData.ShowAlert });
        onBack();
      });
    } else {
      if (groupRules.length === 0) {
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          ruleErrorMessage: "Aleast one rule is mandatory",
          disbaleBtn: false,
        });
      }
      if (analyzerName === "") {
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          nameErrorMessage: "Name is mandatory",
          disbaleBtn: false,
        });
      }

      if (analyzerDescription === "") {
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          nameErrorMessage: "Description is mandatory",
          disbaleBtn: false,
        });
      }

      if (!companyId) {
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          companyNameErrorMessage: "Please select a company",
          disbaleBtn: false,
        });
      }
    }
  };

  const handelSelect = (field, event) => {
    if (field === "AnalyzerName") {
      let value = event.target.value;
      //value = value.replace(/[^A-Za-z ]/gi, ""); // This was making it does not allow special charector
      if (value.length > 50) return;
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        analyzerName: value,
        nameErrorMessage: "",
      });
    } else if (field === "AnalyzerDescription") {
      let value = event.target.value;
      if (value.length > 100) return;
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        analyzerDescription: value,
        nameErrorMessage: "",
      });
    } else if (field === "Company") {
      let companyId = event.target.value;
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        companyId: companyId,
        companyNameErrorMessage: "",
      });
    }
  };
  const [f, setf] = useState("");
  const handleSelectCategory = async (obj) => {
    setSelectedCategory(obj);
    setf(obj.displayName);/* 
    if(obj.displayName != "All")
    {
      setOnlySelected(false);
    } */
    if (obj.displayName == "All") {
      if(analyzerData.viewType == "edit" && onlySelected)
      {
        onLoadData();
      }
      let newData = rules;
      newData.map((r) => {
        filteredRules.filter((f) => {
          if(f.ID == r.ID)
          {
            r.checked = f.checked;
          }
        })
      })
      SetFilteredRules(rules);
      setSearchParams("");
      var count = 0;
      rules.filter((rule) => {
        if (rule.checked) {
          count++;
        }
      });
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        selectAll: {
          value: count == rules.length ? true : false,
          name: "All",
        },
      });
      if(count > 0 && count < rules.length)
      {
        setindeterminate(true);
      }
      /* if(prev == true)
      {
        setOnlySelected(true);
      } */
      return obj;
    }
    if (obj) {
      let filteredData = [];
      let filteredRules = [];
      const newRules = rules.map((rule) => {
        if (rule.CATEGORY_ID === obj.value.ID) filteredRules.push(rule);
        return obj;
      });

      var count = 0;
      filteredData = filteredRules;
      if (
        analyzerData &&
        analyzerData.selectedObj &&
        analyzerData.selectedObj.rules
      ) {
        filteredData = filteredRules.map((obj) => {
          const index = analyzerData.selectedObj.rules.findIndex(
            (rule) => rule.id === obj.ID
          );
          if (index !== -1) {
            return { ...obj, checked: true };
          } else {
            return { ...obj, checked: false };
          }
        });
      }
      SetFilteredRules(filteredData);
      filteredData.filter((rule) => {
        if (rule.checked) {
          count++;
        }
      });
      if (count > 0 && count < filteredData.length) {
        setindeterminate(true);
      } else {
        setindeterminate(false);
        SetCreateAnalyzerDetails({
          ...CreateAnalyzerDetails,
          selectAll: {
            value: true,
            name: selectedCategory.displayName,
          },
        });
      }

      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        ruleErrorMessage: "",
        // selectAll: false,
      });
    }
  };
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllRule = (e, tabname) => {
    setAllSelected(e.target.checked);
    let { checked } = e.target;
    if (indeterminate === true) {
      checked = false;
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        selectAll: { name: "", value: false },
      });
      setindeterminate(false);
    }
    const newRules = filteredRules.map((obj) => {
      handleSelectWithId({ target: { checked: checked } }, obj.ID, false);
      return {
        ...obj,
        checked: checked,
      };
    });

    SetFilteredRules(newRules);
    var count = 0;
    newRules.map((rule) => {
      if (rule.checked) {
        count++;
      }
    });
    if (count < newRules.length && count > 0) {
      setindeterminate(true);
    } else {
      setindeterminate(false);
    }
    setSelectedCategory({displayName:tabname});
    setSelectedCategory((state) => {return state;});
    SetCreateAnalyzerDetails({
      ...CreateAnalyzerDetails,
      ruleErrorMessage: "",
      selectAll: {
        value: checked,
        name: tabname,
      },
    });

    // if (checked) {
    //   const newRules = filteredRules.map((obj) => {
    //     return {
    //       ...obj,
    //       checked: true,
    //     };
    //   });
    //   SetFilteredRules(newRules);

    //   const allRules = rules.map((obj) => {
    //     return {
    //       ...obj,
    //       checked: selectedCategory.displayName==="All"?true:obj.CATEGORY_ID === selectedCategory.value.ID ? true : false,
    //     };
    //   });
    //   SetRules(allRules);
    //   SetCreateAnalyzerDetails({
    //     ...CreateAnalyzerDetails,
    //     ruleErrorMessage: "",
    //     selectAll: true,
    //   });
    // } else {
    //   const newRules = filteredRules.map((obj) => {
    //     return {
    //       ...obj,
    //       checked: false,
    //     };
    //   });
    //   SetFilteredRules(newRules);

    //   const allRules = rules.map((obj) => {
    //     return {
    //       ...obj,
    //       checked: false,
    //     };
    //   });
    //   SetRules(allRules);
    //   SetCreateAnalyzerDetails({
    //     ...CreateAnalyzerDetails,
    //     ruleErrorMessage: "",
    //     selectAll: false,
    //   });
    // }
  };

  const onBack = () => {
    props.onGotoListAnalyzer();
  };

  const editAnalyzerHandler = (_rules, _companyList, _userDetails) => {
    const _rulesList = _rules.map((obj) => {
      const index = analyzerData.selectedObj.rules.findIndex(
        (rule) => rule.id === obj.ID
      );
      if (index !== -1) {
        return { ...obj, checked: true };
      } else {
        return { ...obj, checked: false };
      }
    });
    SetFilteredRules(_rulesList);
    if (_userDetails && _userDetails.role === "super admin") {
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        analyzerId: analyzerData.selectedObj.ID,
        analyzerName: analyzerData.selectedObj.ANALYZER_NAME,
        analyzerDescription: analyzerData.selectedObj.DESCRIPTION,
        companyId: analyzerData.selectedObj.COMPANY_ID,
      });
    } else {
      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        analyzerId: analyzerData.selectedObj.ID,
        analyzerName: analyzerData.selectedObj.ANALYZER_NAME,
        analyzerDescription: analyzerData.selectedObj.DESCRIPTION,
      });
    }
  };

  const [collapse, setCollpase] = useState(false);
  /* if (analyzerData.viewType === "edit") {
    const newR = rules.map((rule) => {
      analyzerData.selectedObj.rules.map((r) => {
        if (r.id === rule.ID) {
          rule.checked = true;
        }
      });
      return rule;
    });
    SetRules(newR);
  } */
  const [isVisible, setVisible] = useState("");
  const [onlySelected, setOnlySelected] = useState(false);
  const [prev, setPrev] = useState(false);
  const handleSelectedRules = (e) => {
    if(e) {e.preventDefault();}
    setOnlySelected(!onlySelected);
    setPrev(!prev);
  }
  return (
    <>
      <Container
        fluid={true}
        className={AnalyzerCreateCss.analyzerCreate_body_background}
        style={{ paddingLeft: "0px", height: "auto" }}
      >
        <Row className="analyzer_header">
          <Col xs="8">
            <div className="analyzer_header_title">
              <span>
                <ArrowBackIcon onClick={onBack} />
              </span>{" "}
              {analyzerData.viewType == "view" ? (
                <span style={{fontSize:'21px', fontWeight:'normal'}}> View Analyzer Detail </span>
              ) : analyzerData.viewType == "edit" ? (
                <span style={{fontSize:'21px', fontWeight:'normal'}}> Edit Analyzer </span>
              ) : (
                <span>
                  {" "}
                  Create New Analyzer{" "}
                  <span className={classes.ruleCountTypography}>
                    (Rules Selected - {`${selectedRules} / ${rules.length}`})
                  </span>{" "}
                </span>
              )}
            </div>
          </Col>
          <Col xs="0">
            {/* <Autocomplete
                multiple
                classes={{
                  option:classes.option
                }}
                size="small"
                limitTags={1}
                id="checkboxes-tags-demo"
                options={rules.sort((a,b)=>{
                  if(a.checked){
                    return -1
                  }
                  else {
                    return 1
                  }
                })}
                disableCloseOnSelect
                onChange={event=>{handleSelectWithId(event,event.target.value)}}
                
                renderTags={value=>{
                  let tag=''
                  if(value.length===1){
                    tag=value[0].NAME
                  }
                  else if (value.length >0){
                    tag = `${value.length} Rules Selected`
                  }
                  if(tag.length>25){
                    tag=tag.substr(0,25)+'...'
                  }
                  
                  return (
                    <React.Fragment >
                      <div className={classes.autoCompleteTags}>{tag}</div>
                    </React.Fragment>
                  )
                }}
                getOptionSelected={(option,value)=>{
                  return option.checked 
                }}
                value={rules.filter(rule=>{
                  return rule.checked?true:false
                })}
                
                getOptionLabel={(option) => option.NAME}
                renderOption={(option,{selected}) => {
                  
                  return (
                  <React.Fragment >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color:"black" }}
                      checked={selected}
                      value={option.ID}
                    />
                   <div >
                      {option.NAME}
                      <br />
                      <Typography variant="caption" style={{color:'gray'}}>Category:{option.CATEGORY}</Typography>
                    </div>
                  </React.Fragment>
                
                )}}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField  {...params} variant="outlined" label="Select Rules" placeholder="Select Rules" />
                )}
              /> */}
          </Col>
          <Col xs="4">
            {" "}
            <div className="analyzer_stepper_button">
              {analyzerData.viewType != "view" &&
                appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_ANALYZER
                ) && (
                  <div>
                    <BinarywayButton
                      class={classes.saveUpdateButtton}
                      onClick={handleCreateGroup}
                      label={
                        analyzerData.viewType == "edit" ? "Update" : "Save"
                      }
                    ></BinarywayButton>
                  </div>
                )}
            </div>
          </Col>
        </Row>
        {/* Changes Made By Shubham Kumar */}
        <div
          id="main_container"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            id="collapse_bar"
            style={{
              minWidth: ` ${collapse ? "5vw" : "21vw"} `,
              maxWidth: ` ${collapse ? "5vw" : "21vw"} `,
              backgroundColor: "white",
              marginRight: "10px",
              marginTop: "0px",
              boxShadow: "1px 0px 5px -2px black",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  display: ` ${collapse ? "none" : "block"} `,
                  fontSize:'14px',
                  fontWeight:'500',
                }}
              >
                {analyzerData.viewType === "view"
                  ? "View Analyzer Details"
                  : analyzerData.viewType === "edit"
                  ? "Edit Analyzer Details"
                  : "Create Analyzer Details"}
              </p>
              <div
                style={{
                  width: ` ${collapse ? "100%" : "10%"} `,
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1.1rem",
                  }}
                  onClick={() => {
                    setCollpase(!collapse);
                  }}
                >
                  {" "}
                  {collapse ? (
                    <i
                      className="fa fa-arrow-circle-right"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-arrow-circle-left"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  )}{" "}
                </button>
              </div>
            </div>
            {!collapse && <hr />}
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "rgb(15, 24, 63)",
                  borderRadius: "10px",
                  color: "white",
                  display: ` ${collapse ? "none" : "block"} `,
                  fontSize:'14px',
                  fontWeight:'normal',
                }}
              >
                Note : Rules are listed in All tab and category wise tabs.
                Please select rules for analyzer and then click Save to proceed.
              </div>
              {/* <div
                style={{
                  width: ` ${collapse ? "100%" : "10%"} `,
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1.1rem",
                  }}
                  onClick={() => {
                    setCollpase(!collapse);
                  }}
                >
                  {" "}
                  {collapse ? (
                    <i
                      className="fa fa-arrow-circle-right"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-arrow-circle-left"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  )}{" "}
                </button>
              </div> */}
            </div>
            {!collapse && <hr />}
            <div
              style={{
                marginBottom: "8px",
                display: ` ${collapse ? "none" : "block"} `,
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  display: ` ${collapse ? "none" : "block"} `,
                }}
              >
                <BinarywayInput
                  disabled={analyzerData.viewType === "view"}
                  label="Analyzer Name*"
                  type="text"
                  fullWidth={true}
                  errormessages={[
                    "this field is required",
                    "Max Length of the field is 50",
                  ]}
                  value={CreateAnalyzerDetails.analyzerName}
                  defaultValue={CreateAnalyzerDetails.analyzerName || ""}
                  onChange={(event) => handelSelect("AnalyzerName", event)}
                ></BinarywayInput>
              </div>
            </div>
            <div
              style={{
                marginBottom: "8px",
                display: ` ${collapse ? "none" : "block"} `,
              }}
            >
              <BinarywayInput
                disabled={analyzerData.viewType === "view"}
                label="Description*"
                type="text"
                fullWidth={true}
                errormessages={[
                  "this field is required",
                  "Max Length of the field is 100",
                ]}
                value={CreateAnalyzerDetails.analyzerDescription}
                defaultValue={CreateAnalyzerDetails.analyzerDescription || ""}
                onChange={(event) => handelSelect("AnalyzerDescription", event)}
                size="large"
                border="2px solid green"
              ></BinarywayInput>
            </div>
            {userDetails && userDetails.role === "super admin" && (
              <div
                className={AnalyzerCreateCss.analyzerCreate_body_row}
                style={{ display: ` ${collapse ? "none" : "block"} ` }}
              >
                <BinarywayDropdown
                  disabled={analyzerData.viewType === "view"}
                  placeHolder=""
                  label="Company *"
                  validator={true}
                  errorMessage={"this field is required"}
                  value={CreateAnalyzerDetails.companyId}
                  defaultValue={CreateAnalyzerDetails.companyId || null}
                  options={companyList}
                  onChange={(event) => handelSelect("Company", event)}
                />
              </div>
            )}
          </div>
          <div
            className={AnalyzerCreateCss.analyzerCreate_body}
            style={{ flex: "auto" }}
          >
            {/* <Paper>
              <Container
                fluid={true}
                className={
                  AnalyzerCreateCss.analyzerCreate_paper_body_header_height
                }
              >
                <Row>
                  <Col xs="3">
                    <div className={AnalyzerCreateCss.analyzerCreate_body_row}>
                      <BinarywayInput
                        disabled={analyzerData.viewType === "view"}
                        label="Analyzer Name*"
                        type="text"
                        fullWidth={true}
                        validators={["required", "maxLength"]}
                        errormessages={[
                          "this field is required",
                          "Max Length of the field is 50",
                        ]}
                        value={CreateAnalyzerDetails.analyzerName}
                        defaultValue={CreateAnalyzerDetails.analyzerName || ""}
                        onChange={(event) =>
                          handelSelect("AnalyzerName", event)
                        }
                      ></BinarywayInput>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className={AnalyzerCreateCss.analyzerCreate_body_row}>
                      <BinarywayInput
                        disabled={analyzerData.viewType === "view"}
                        label="Description*"
                        type="text"
                        fullWidth={true}
                        validators={["required", "maxLength"]}
                        errormessages={[
                          "this field is required",
                          "Max Length of the field is 100",
                        ]}
                        value={CreateAnalyzerDetails.analyzerDescription}
                        defaultValue={
                          CreateAnalyzerDetails.analyzerDescription || ""
                        }
                        onChange={(event) =>
                          handelSelect("AnalyzerDescription", event)
                        }
                      ></BinarywayInput>
                    </div>
                  </Col>
                  <Col xs="3">
                    {userDetails && userDetails.role === "super admin" && (
                      <div
                        className={AnalyzerCreateCss.analyzerCreate_body_row}
                      >
                        <BinarywayDropdown
                          disabled={analyzerData.viewType === "view"}
                          placeHolder=""
                          label="Company *"
                          validator={true}
                          errorMessage={"this field is required"}
                          value={CreateAnalyzerDetails.companyId}
                          defaultValue={CreateAnalyzerDetails.companyId || null}
                          options={companyList}
                          onChange={(event) => handelSelect("Company", event)}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </Paper> */}
            {/* <div>
              <Paper>
                <Container
                  fluid={true}
                  className={
                    AnalyzerCreateCss.analyzerCreate_paper_body_header_height_tag_line
                  }
                >
                  <Row>
                    <Col
                      xs="12"
                      className={
                        AnalyzerCreateCss.analyzerCreate_tag_line_padding_top_bottom
                      }
                    >
                      <span className={classes.ruleCountTypographytagline}>
                        Note :&nbsp; Rules are listed in All tab and category
                        wise tabs. Please select rules for analyzer and then
                        click Save to proceed.
                      </span>
                    </Col>
                  </Row>
                </Container>
              </Paper>
            </div> */}
            <Paper
              style={{
                height: "80vh",
                width: "100%",
              }}
            >
              <div className={AnalyzerCreateCss.analyzerCreate_categoriesBlock}>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((obj, i) => (
                    <div
                      className={
                        obj.displayName === selectedCategory.displayName
                          ? AnalyzerCreateCss.analyzerCreate_selectedCategory
                          : AnalyzerCreateCss.analyzerCreate_category
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedCategory(obj);
                        handleSelectCategory(obj)}}
                    >
                      <span style={{fontSize:'12px', fontWeight:'w500', fontFamily:'Roboto'}}>{obj.displayName} </span>
                    </div>
                  ))}
              </div>
              <div
                className={
                  AnalyzerCreateCss.analyzerCreate_categoriesBlockSecond
                }
              >
                <div>{selectedCategory.displayName} Rules</div>
                <div className={classes.searchBarLeftContainer}>
                  
                  {selectedCategory ? (
                    <BinarywayInput
                      className={classes.searchInput}
                      value={searchParamas}
                      placeholder="Search Rules"
                      onChange={(event) => setSearchParams(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon></SearchIcon>
                        </InputAdornment>
                      }
                    ></BinarywayInput>
                  ) : (
                    ""
                  )}
                  {analyzerData.viewType == "edit" && selectedCategory.displayName === "All" && 
                  <div className={classes.selectAllContainer}>
                  <Checkbox
                    checked={onlySelected}
                    color="primary"
                    onClick={(e) =>
                      handleSelectedRules(e)
                    } 
                    style={{borderRadius:'100%', paddingRight:'2px'}}
                    icon = {<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon = {<CheckCircleOutlineOutlinedIcon />}
                  />{" "}
                  <Typography style={{ marginRight: "10px" }}>
                    Selected Rules
                  </Typography>
                </div>
                  }
                  <div className={classes.selectAllContainer}>
                    <Checkbox
                      disabled={analyzerData.viewType == "view"}
                      checked={
                        CreateAnalyzerDetails.selectAll.name == "All"
                          ? CreateAnalyzerDetails.selectAll.value
                          : CreateAnalyzerDetails.selectAll.name ===
                              selectedCategory.displayName &&
                            CreateAnalyzerDetails.selectAll.value
                      }
                      color="primary"
                      indeterminate={indeterminate}
                      style={{paddingRight:'2px'}}
                      onClick={(e) =>
                        handleSelectAllRule(e, selectedCategory.displayName)
                      }
                    />{" "}
                    <Typography style={{ marginLeft: "0px" }}>
                      Select All Rules
                    </Typography>
                  </div>
                </div>
              </div>
              <Container
                fluid={true}
                className={AnalyzerCreateCss.analyzerCreate_paper_body_height}
                style={{ flex: "1",padding: '0px',paddingTop: '16px',height: "auto" }}
              >
                {(userDetails.role === "super admin"
                  ? CreateAnalyzerDetails.companyId
                  : true) && (
                  <Row>
                    <Col xs="8">
                      {filteredRules.length <= 0 && (
                        <label style={{ color: "#1976D2", fontWeight: 600 }}>
                          
                        </label>
                      )}
                      {CreateAnalyzerDetails.ruleErrorMessage !== "" && (
                        <span style={{ color: "red" }}>
                          {" "}
                          *{CreateAnalyzerDetails.ruleErrorMessage}
                        </span>
                      )}
                    </Col>
                  </Row>
                )}
                {(userDetails.role === "super admin"
                  ? CreateAnalyzerDetails.companyId
                  : true) && (
                  <Row
                    style={{
                      width: "100%",
                      marginRight: "0px",
                      marginLeft: "0px",
                      justifyContent:'flex-start',
                      paddingTop:'16px'
                    }}
                    className={AnalyzerCreateCss.analyzerCreate_paper_body}
                  >
                    {selectedCategory.displayName === "All"
                      ? categoryList &&
                        categoryList.map((cat) => {
                          let list = filteredRules.filter((rule) => {
                            if(onlySelected && cat.displayName !== "All" &&
                            rule.CATEGORY_ID === cat.value.ID && rule.checked)
                            {
                              return true;
                            }
                            else if(onlySelected && cat.displayName !== "All" &&
                            rule.CATEGORY_ID === cat.value.ID && !rule.checked)
                            {
                              return false;
                            }
                            else if (
                              cat.displayName !== "All" &&
                              rule.CATEGORY_ID === cat.value.ID
                            ) {
                              return true;
                            }
                            return false;
                          });
                          if (list.length > 0)
                            return (
                              <div style={{ width: "100%" }}>
                                <Typography
                                  style={{
                                    margin: " 0 15px",
                                    color: "#97989a",
                                  }}
                                >
                                  {cat.displayName !== "All" && list.length > 0
                                    ? cat.displayName
                                    : ""}
                                </Typography>
                                <Row style={{ paddingLeft: "16px", justifyContent:'flex-start', paddingRight:'16px', paddingTop:'5px' }}>
                                  {list.map((obj) => {
                                    if (
                                      userDetails.role === "super admin" &&
                                      CreateAnalyzerDetails.companyId !==
                                        obj.COMPANY_ID &&
                                      obj.COMPANY_ID !== -1
                                    ) {
                                      return;
                                    }
                                    if(onlySelected && !obj.checked)
                                    {
                                      return ;
                                    }
                                    return (
                                      <Col xs={collapse ? "3" : "4"} style={{paddingRight:'5px', paddingLeft:'5px'}}>
                                        <Box
                                          className={classes.cardContainer}
                                          boxShadow={1}
                                        >
                                          <Grid container direction="column">
                                            <Box
                                              bgcolor="#0f183f"
                                              pb={3}
                                              className={
                                                classes.cardTitltContainer
                                              }
                                              onMouseOver={()=>{setVisible(obj.ID)}}
                                              onMouseOut={()=>{setVisible("")}}
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                className={classes.cardTitle}
                                                style={{
                                                  height: '52px'
                                                    
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    maxWidth: '72px',
                                                    minWidth: '72px',
                                                    display: "flex",
                                                    height: '27px',
                                          
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                      alignItems: 'center',
                                          
                                                    fontFamily:'Roboto',
                                                    backgroundColor: 'white',
                                                    borderBottomLeftRadius: '10px',
                                                    padding: '0px 10px',
                                                    float: 'right'

                                                  }}
                                                >
                                                  <VisibilityIcon
                                                    style={{color: 'black',fontSize:'18px'}}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      onViewRuleDetails(obj);
                                                    }}
                                                    
                                                  />
                                                  <input
                                                    id="checkbox"
                                                    className={
                                                      classes.cardTitltContainerCheckbox
                                                    }
                                                    disabled={
                                                      analyzerData.viewType ==
                                                      "view"
                                                    }
                                                    checked={obj.checked}
                                                    type="checkbox"
                                                    onClick={(e) =>
                                                      handleSelectWithId(
                                                        e,
                                                        obj.ID
                                                      )
                                                    }
                                                    style={{
                                                      backgroundColor: "blue",
                                                      marginRight:'0px'
                                                    }}
                                                  />
                                                  
                                                </div>
                                                <p style={{padding: '6px',paddingLeft:'10px'}}>{obj.NAME}</p>
                                                
                                              </Typography>
                                            </Box>
                                            {/* <Box bgcolor="white" p={1}>
                                              <Typography
                                                className={
                                                  classes.cardMainDescription
                                                }
                                              >
                                                {obj.DESCRIPTION || "-"}
                                              </Typography>
                                            </Box> */}
                                            <Box
                                              bgcolor="#0f183f"
                                              p={1}
                                              pt={0}
                                              className={
                                                classes.cardFooterContainer
                                              }
                                            >
                                              <Grid
                                                container
                                                justify="flex-end"
                                              >
                                                {/* <Box mr={1}>
                                                  <Grid container item>
                                                    <VisibilityIcon
                                                      onClick={() =>
                                                        onViewRuleDetails(obj)
                                                      }
                                                    />
                                                  </Grid>
                                                </Box> */}
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </Box>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            );
                        })
                      : filteredRules.length
                      ? filteredRules.map((obj, index) => {
                        if(obj.CATEGORY != selectedCategory.displayName)
                            {
                              return;
                            }
                          if (
                            userDetails.role === "super admin" &&
                            CreateAnalyzerDetails.companyId !==
                              obj.COMPANY_ID &&
                            obj.COMPANY_ID !== -1
                          )
                            return;
                             
                          return (
                            <Col xs={collapse ? "3" : "4"} style={{paddingRight:'5px', paddingLeft:'5px'}}>
                              <Box
                                className={classes.cardContainer}
                                boxShadow={1}
                              >
                                <Grid container direction="column">
                                  <Box
                                    bgcolor="#0f183f"
                                    pb={3}
                                    className={classes.cardTitltContainer}
                                    onMouseOver={()=>{setVisible(obj.ID)}}
                                              onMouseOut={()=>{setVisible("")}}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.cardTitle}
                                      style={{
                                        height: '52px'
                                      }}
                                    >
                                      <div
                                         style={{
                                          maxWidth: '72px',
                                          minWidth: '72px',
                                          display: "flex",
                                          height: '27px',
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: 'center',
                                          fontFamily:'Roboto',
                                          backgroundColor: 'white',
                                          borderBottomLeftRadius: '10px',
                                          padding: '0px 10px',
                                          float: 'right'

                                        }}>
                                        <VisibilityIcon
                                                    style={{color: 'black',fontSize:'18px'}}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      onViewRuleDetails(obj);
                                                    }}
                                                    
                                                  />
                                        <input
                                          className={
                                            classes.cardTitltContainerCheckbox
                                          }
                                          disabled={
                                            analyzerData.viewType == "view"
                                          }
                                          checked={obj.checked}
                                          type="checkbox"
                                          onClick={(e) =>
                                            handleSelectWithId(e, obj.ID)
                                          }
                                          style={{
                                            backgroundColor: "blue",
                                            marginRight:'0px'
                                          }}
                                        />
                                        
                                      </div>
                                      <p style={{padding: '6px',paddingLeft:'10px'}}>{obj.NAME}</p>
                                      
                                    </Typography>
                                  </Box>
                                  {/* <Box bgcolor="white" p={1}>
                                    <Typography
                                      className={classes.cardMainDescription}
                                    >
                                      {obj.DESCRIPTION || "-"}
                                    </Typography>
                                  </Box> */}
                                  <Box
                                    bgcolor="#0f183f"
                                    p={1}
                                    pt={1}
                                    className={classes.cardFooterContainer}
                                  >
                                    {/* <Grid container justify="flex-end">
                                      <Box mr={1}>
                                        <Grid container item>
                                          <VisibilityIcon
                                            onClick={() =>
                                              onViewRuleDetails(obj)
                                            }
                                          />
                                        </Grid>
                                      </Box>
                                    </Grid> */}
                                  </Box>
                                </Grid>
                              </Box>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                )}
              </Container>
            </Paper>
          </div>
        </div>
      </Container>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RuleDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
      />
    </>
  );
};

export default AnalyzerCreate;
