import React from 'react';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
    cardContainer: {
        border: "1px solid lightgrey",
        borderRadius: 12,
        marginBottom: 10
    },
    cardTitltContainer: {
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    cardFooterContainer: {
        borderBottomRightRadius: 12,
        borderBottomLeftRadius: 12,
    },
    cardMainDescription: {
        marginBottom: 7,
        fontSize: 14,
        color: "#000000",
        fontWeight: "normal",
        padding: 5
    },
    cardNumOfRules: {
        color: '#919294',
        marginRight: 3,
        fontSize: 14,
        padding: 5
    },
    cardTitle: {
        padding: 4,
        margin: 5,
        marginTop: 10,
        color: "white",
        fontSize: 18
    }
}));

const Card = props => {
    const  classes  = useStyles();
    return (
        <Box className={classes.cardContainer} boxShadow={1}>
            <Grid container direction="column">
                <Box bgcolor="#0f183f" pb={3} className={classes.cardTitltContainer}>
                    <Typography variant="subtitle2" className={classes.cardTitle}>{props.obj.ANALYZER_NAME}</Typography>
                </Box>
                <Box bgcolor="white" p={1}>
                    <Typography className={classes.cardMainDescription}>
                    {props.obj.ANALYZER_NAME}
                </Typography>
                    <Divider />
                    <Grid container item justify="flex-start" alignItems="center">
                        <Typography className={classes.cardNumOfRules}>Number of rules : </Typography>
                        <Typography>{props.obj.rules.length}</Typography>
                    </Grid>
                </Box>
                <Box bgcolor="#e8edf3" p={1} pt={1} className={classes.cardFooterContainer}>
                    <Grid container justify="space-between">
                        <DeleteIcon
                            onClick={() =>
                                props.onDeleteAnalyzer(props.obj.ID)
                            }
                        />
                        <Box mr={1}>
                            <Grid container item>
                                <Box mr={1}>
                                    <CreateIcon
                                        onClick={() =>
                                            props.onEditAnalyzer(props.obj)
                                        }
                                    />
                                </Box>
                                <VisibilityIcon
                                    onClick={() =>
                                        props.onViewAnalyzerInfoDetails(props.obj)
                                    }
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default Card;
