import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    backgroundColor: "#fff",
  },
}));

const BinarywayDropdown = (props) => {
  const classes = useStyles();
  const options = props.options || [];
  const [property, setProperty] = useState({
    labelId: props.labelId || "drop-down-label_" + Math.random(),
    id: props.id || "drop-down_" + Math.random(),
    placeHolder: props.label || props.placeHolder || "Please Select",
    label: props.label || "Please Select",
    variant: props.variant || "outlined",
    size: props.size || "small",
    disabled: props.disabled || false,
    error: props.error || false,
    errormessages: props.errormessages || "this filed is mandatory",
    class: props.class || null
  });

  const onSelectChange = (event) => {
    if (props.validator && props.validator === true) {
      if (event.target.value === null) {
        setProperty({ ...property, error: true });
      } else {
        setProperty({ ...property, error: false });
      }
    }
    props.onChange(event);
  };

  return (
    <FormControl
      size={property.size}
      variant={property.variant}
      error={property.error}
      className={property.class || classes.formControl}
    >
      <InputLabel id={property.labelId} shrink={props.value ?  true :  false}>{property.label}</InputLabel>
      <Select
        labelId={property.labelId}
        id={property.id}
        value={props.value}
        // defaultValue={props.defaultValue}
        onChange={onSelectChange}
        onBlur={onSelectChange}
        label={property.placeHolder}
        disabled={property.disabled}
        inputProps= {{
          shrink: props.value ?  true :  false
        }}
      >
        {options &&
          options.map((x, index) => (
            <MenuItem key={index} value={x.value}>
              {x.displayName}
            </MenuItem>
          ))}
      </Select>

      {property.error && property.error === true && (
        <FormHelperText>{property.errormessages}</FormHelperText>
      )}
    </FormControl>
  );
};

export default BinarywayDropdown;
