import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BinarywayButton from "../../../../UI-Components/Button/Button";
import Divider from '@material-ui/core/Divider';
import FilterCss from "./Filter.module.css";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {  getConfig, getRulConfig} from '../../../../../service/service';
const useStyles = makeStyles((theme) => ({
  sideFilter: {
    height: "calc(100vh - 145px)",
    width: "100%",
  },
  filterAppbar: {
    padding: "1rem",
  },
  listItemText: {
    right: "1.5rem",
    position: "relative",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));
const Filter = ({onReset ,isOpen, setOpenFilter, toggleDrawer, configData, checkedFilter, setCheckedFilter, ruleTypes, setRuleTypes,customCategory,setCustomCategory, onApplyFilter, categoryTypes, setCategoryTypes }) => {
  const classes = useStyles();
  
  const [openCollapse, setOpenCollapse] = useState({
    ruleOpen: false,
    typeOpen: false,
    categoryOpen: false
  });

  

  const handleClick = (name, value) => {
    setOpenCollapse({ ...openCollapse, [name]: value })
  };

  

  const onCheckChange = (e) => {
    if (e.target.name == "allChecked") {
      setCheckedFilter(previousState => ({
        ...previousState,
        ruleTypeChecked: e.target.checked,
        allChecked: e.target.checked,
        categoryTypeChecked: e.target.checked,
        customcategoryTypeChecked: e.target.checked
      }))
      let tempRuleTypes = ruleTypes.map(rule =>{ 
        rule.isChecked = e.target.checked
        return rule
   })
        let tempCustomCategory = customCategory.map(cat =>{ 
          cat.isChecked = e.target.checked
          return cat
      })
       setRuleTypes(tempRuleTypes)
       setCustomCategory(tempCustomCategory)
       setCategoryTypes({...categoryTypes, builtIn:e.target.checked, custom:e.target.checked})


    } else {
      if (!e.target.checked) {
        setCheckedFilter(previousState => ({ ...previousState, allChecked: false, [e.target.name]: e.target.checked }))
      } else {

        const allValues = Object.values(checkedFilter);
        const filteredValues = allValues.filter(filterData => filterData === true);
        if (filteredValues.length === allValues.length - 2) {
          setCheckedFilter(previousState => ({ ...previousState, [e.target.name]: e.target.checked, allChecked: true }))
        } else {
          setCheckedFilter(previousState => {
            return { ...previousState, [e.target.name]: e.target.checked }
          })
        }
      }



    }
    if(e.target.name === "ruleTypeChecked") {
      let tempRuleTypes = ruleTypes.map(rule =>{ 
       rule.isChecked = e.target.checked
       return rule
  })
      setRuleTypes(tempRuleTypes)
    }
    if(e.target.name === "customcategoryTypeChecked") {
      let tempCustomCategory = customCategory.map(cat =>{ 
        cat.isChecked = e.target.checked
       return cat
  })
      setCustomCategory(tempCustomCategory)
    }
    if(e.target.name=== "categoryTypeChecked") {
      setCategoryTypes({...categoryTypes, builtIn:e.target.checked, custom:e.target.checked })
    }

    // setCheckedFilter({...checkedFilter, [e.target.name]:e.target.checked})

  }

  const onCategoryTypeChange = (e)=> {
    if(e.target.checked) {
    const categoryTypeValues = Object.values(categoryTypes);
    const categoryCheckedValues = categoryTypeValues.filter(e=> e===true);
    if(categoryCheckedValues.length === categoryTypeValues.length-1) {
      
      if(checkedFilter.ruleTypeChecked) {
        setCheckedFilter({...checkedFilter, categoryTypeChecked:true, allChecked:true})
      } else {
        setCheckedFilter({...checkedFilter, categoryTypeChecked:true})
      }

    }
    setCategoryTypes({...categoryTypes, [e.target.name]:e.target.checked})
  } else {
    setCategoryTypes({...categoryTypes, [e.target.name]:e.target.checked})
    setCheckedFilter({...checkedFilter, categoryTypeChecked:false, allChecked:false})
  }
    


  }
  const onRuleTypeChange = (ruleType, e) => {
    e.preventDefault();
    if(!e.target.checked) {
      setCheckedFilter({...checkedFilter, ruleTypeChecked: e.target.checked, allChecked: e.target.checked})
    } else {
      const tempRuleTypes = ruleTypes.filter(rule => rule.isChecked === true);
      if(tempRuleTypes.length === ruleTypes.length-1) {
       
        if(checkedFilter.categoryTypeChecked) {

          setCheckedFilter(previoustate=>({...previoustate, allChecked:true,  ruleTypeChecked: e.target.checked}))
        } else{
          setCheckedFilter( previousState => ({...previousState, ruleTypeChecked: e.target.checked}));

        }

      }
    }
    let tempRuleTypes = ruleTypes.map(rule =>{ 
      if(rule.ID == ruleType.ID) {
     rule.isChecked = e.target.checked
    }
  return rule
})
    setRuleTypes(tempRuleTypes)
  }

  const onCustomCategoryChange = (c, e) => {
    e.preventDefault();
    if(!e.target.checked) {
      setCheckedFilter({...checkedFilter, customcategoryTypeChecked: e.target.checked, allChecked: e.target.checked})
    } else {
      const tempCategory = customCategory.filter(category => category.isChecked === true);
      if(tempCategory.length === customCategory.length-1) {
       
        if(checkedFilter.categoryTypeChecked) {

          setCheckedFilter(previoustate=>({...previoustate, allChecked:true,  customcategoryTypeChecked: e.target.checked}))
        } else{
          setCheckedFilter( previousState => ({...previousState, customcategoryTypeChecked: e.target.checked}));

        }

      }
    }
    let tempCategory = customCategory.map(category =>{ 
      if(category.ID == c.ID) {
        category.isChecked = e.target.checked
    }
      return category
    })
    setCustomCategory(tempCategory)
  }


  

  const list = (anchor) => {
    const { ruleTypeChecked, allChecked, stageChecked, categoryTypeChecked, customcategoryTypeChecked } = checkedFilter;
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
      >
        <AppBar position="static" className={classes.filterAppbar}>
          Filters
      </AppBar>
        <List component="nav">
          <ListItem>
            <ListItemIcon>
              <Checkbox edge="start" checked={allChecked} name="allChecked" onChange={onCheckChange} />
            </ListItemIcon>
            <ListItemText primary={`All`} className={classes.listItemText} />
          </ListItem>
          <Divider />
          <ListItem >
            <ListItemIcon>
              <Checkbox edge="start" checked={ruleTypeChecked} name="ruleTypeChecked" onChange={onCheckChange} />
            </ListItemIcon>
            <ListItemText
              primary={`Rule Type`}
              className={classes.listItemText}
            />
            {openCollapse.ruleOpen ? <ExpandLess button onClick={() => handleClick("ruleOpen", !openCollapse.ruleOpen)} /> : <ExpandMore button onClick={() => handleClick("ruleOpen", !openCollapse.ruleOpen)} />}
          </ListItem>
          <Collapse in={openCollapse.ruleOpen} timeout="auto" unmountOnExit>

            <List component="div" disablePadding>
              {ruleTypes.map(ruleType=><ListItem key={ruleType.ID} button className={classes.nested}>
                <ListItemIcon>
                  <Checkbox edge="start" checked={ruleType.isChecked} name={ruleType.NAME} onChange={(e)=>onRuleTypeChange(ruleType, e)}/>
                </ListItemIcon>
                <ListItemText primary={ruleType.NAME} className={classes.listItemText}/>
              </ListItem>)}
              
            </List>
          </Collapse>

          <Divider />



          <ListItem   >
            <ListItemIcon>
              <Checkbox edge="start" checked={categoryTypeChecked} name="categoryTypeChecked" onChange={onCheckChange} />
            </ListItemIcon>
            <ListItemText
              primary={`Groups`}
              className={classes.listItemText}
            />
            {openCollapse.typeOpen ? <ExpandLess button onClick={() => handleClick("typeOpen", !openCollapse.typeOpen)} /> : <ExpandMore button onClick={() => handleClick("typeOpen", !openCollapse.typeOpen)} />}
          </ListItem>
          <Collapse in={openCollapse.typeOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Checkbox edge="start" name="builtIn" checked={categoryTypes.builtIn} onChange={onCategoryTypeChange} />
                </ListItemIcon>
                <ListItemText
                  primary={`Built In`}
                  className={classes.listItemText}
                />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Checkbox edge="start" name="custom" checked={categoryTypes.custom} onChange={onCategoryTypeChange} />
                </ListItemIcon>
                <ListItemText
                  primary={`Custom`}
                  className={classes.listItemText}
                />
              </ListItem>
            </List>
          </Collapse>



          <Divider />

          <ListItem>
            <ListItemIcon>
              <Checkbox edge="start" checked={customcategoryTypeChecked} name="customcategoryTypeChecked" onChange={onCheckChange} />
            </ListItemIcon>
            <ListItemText
              primary={`Category`}
              className={classes.listItemText}
            />
            {openCollapse.categoryOpen ? <ExpandLess button onClick={() => handleClick("categoryOpen", !openCollapse.categoryOpen)} /> : <ExpandMore button onClick={() => handleClick("categoryOpen", !openCollapse.categoryOpen)} />}
          </ListItem>
          <Collapse in={openCollapse.categoryOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {customCategory.map(customCategory=><ListItem key={customCategory.ID} button className={classes.nested}>
                <ListItemIcon>
                  <Checkbox edge="start" checked={customCategory.isChecked} name={customCategory.NAME} onChange={(e)=>onCustomCategoryChange(customCategory, e)}/>
                </ListItemIcon>
                <ListItemText primary={customCategory.NAME} className={classes.listItemText}/>
              </ListItem>)}
            </List>
          </Collapse>

          <Divider />

          {/* <ListItem>
            <ListItemIcon>
              <Checkbox edge="start" checked={stageChecked} name="stageChecked" onChange={onCheckChange} />
            </ListItemIcon>
            <ListItemText
              primary={`Stage Details`}
              className={classes.listItemText}
            />
          </ListItem> */}
        </List>
        <div class={FilterCss.filter_drawer_btn}>
          <BinarywayButton
            variant="outlined"
            label="Reset"
            onClick={onReset}
          ></BinarywayButton>
          <div style={{ marginLeft: '10px' }}>
            <BinarywayButton label="Apply" onClick={onApplyFilter}></BinarywayButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SwipeableDrawer
        id={"ruleListFilter_drawer"}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </>
  );
};
export default Filter;
