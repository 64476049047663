import React, { useState, useContext } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";

import ListUser from "./User/ListUser/ListUser";
import ListCompany from "./Company/ListCompany/ListCompany";
import ListTemplate from "./Template/ListTemplate/ListTemplate"
import ListExecutionAudit from "./ExecutionAudit";
import { getPermissionStatus } from "../../service/service";
import { AppContext } from "../../service/context";
import { appConstants } from "../../service/constants";
import SuperAdminCss from "./SuperAdmin.module.css";
import "./SuperAdmin.css";

const SuperAdmin = (props) => {
  const [selectedTab, SetSelectedTab] = useState({ tab: "Company" });
  const { appData, setAppData } = useContext(AppContext);
  const onNavClick = (type) => {
    SetSelectedTab({ tab: type });
  };

  return (
    <>
      <div id="superAdminNav">
        <Nav>
          <NavItem onClick={() => onNavClick("Company")}>
            <NavLink
              className={
                selectedTab.tab === "Company"
                  ? SuperAdminCss.superAdmin_selected
                  : ""
              }
            >
              Company List
            </NavLink>
          </NavItem>
          <NavItem onClick={() => onNavClick("User")}>
            <NavLink
              className={
                selectedTab.tab === "User"
                  ? SuperAdminCss.superAdmin_selected
                  : ""
              }
            >
              User List
            </NavLink>
          </NavItem>
          <NavItem onClick={() => onNavClick("Template")}>
            <NavLink
              className={
                selectedTab.tab === "Template"
                  ? SuperAdminCss.superAdmin_selected
                  : ""
              }
            >
              Templates
            </NavLink>
          </NavItem>
          <NavItem onClick={() => onNavClick("ExecutionAudit")}>
            <NavLink
              className={
                selectedTab.tab === "ExecutionAudit"
                  ? SuperAdminCss.superAdmin_selected
                  : ""
              }
            >
              Execution Audit
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div>
        {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_COMPANY) &&
          selectedTab &&
          selectedTab.tab === "Company" && (
            <ListCompany isSuperAdmin={true}></ListCompany>
          )}
        {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_USER) &&
          selectedTab &&
          selectedTab.tab === "User" && (
            <ListUser isSuperAdmin={true}></ListUser>
          )}
        {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_TEMPLATE) &&
          selectedTab &&
          selectedTab.tab === "Template" && (
            <ListTemplate isSuperAdmin={true}></ListTemplate>
            
          )}
          {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_EXECUTION) &&
          selectedTab &&
          selectedTab.tab === "ExecutionAudit" && (
            <ListExecutionAudit isSuperAdmin={true}></ListExecutionAudit>
          )}

      </div>
    </>
  );
};

export default SuperAdmin;
