import React, { useState, useEffect } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Row, Col, Container } from "reactstrap";
import BinarywayInput from "../../../UI-Components/Input/Input";
import BinarywayButton from "../../../UI-Components/Button/Button";

import "bootstrap/dist/css/bootstrap.css";
import AddCompanyCss from "./AddCompany.module.css";

import {
  postAddCompanyDetails,
  postUpdateCompanyDetails,
} from "../../../../service/service";

import _ from "lodash";

const useStyles = makeStyles({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
});

const AddCompany = (props) => {
  const [companyDetails, setCompanyDetails] = useState({
    CompanyName: null,
    isVaildResponse: true,
    inValidDetails: false,
  });

  const toggleDrawer = (open) => (event) => {
    setCompanyDetails({
      CompanyName: null,
      isVaildResponse: true,
      inValidDetails: false,
    });
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setOpen({ ...props.isOpen, isOpenAddCompany: open });
  };

  useEffect(() => {
    onLoadSetData();
  }, []);

  const onLoadSetData = () => {
    if (props && props.dataFromList) {
      setCompanyDetails({
        CompanyName: _.cloneDeep(props.dataFromList.name),
        isVaildResponse: true,
        inValidDetails: false,
      });
    }
  };

  const onUserDetailsUpdate = (type, event) => {
    let newValue = event.target.value;

    if(type === "CompanyName") {
      newValue = newValue.replace(/[^A-Za-z-0-9-_ ]/gi, "");
    }

    if(type === "CompanyName" && newValue.length > 50 ) return;
    
    setCompanyDetails({ ...companyDetails, [type]: newValue });
  };

  const onSaveCompanyDetails = async () => {
    if (companyDetails && companyDetails.CompanyName && companyDetails.CompanyName.trim().length < 51) {
      setCompanyDetails({ ...companyDetails, inValidDetails: false });
      if (props && props.dataFromList) {
        const body = {
          companyname: companyDetails.CompanyName.trim(),
          companyid: props.dataFromList.id,
        };
        const result = await postUpdateCompanyDetails(body);
        if (result && result.success === true) {
          props.onChange("successUpdate");
        }
      } else {
        const body = {
          companyname: companyDetails.CompanyName.trim(),
        };
        const result = await postAddCompanyDetails(body);
        if (result && result.success === true) {
          props.onChange("successAdd");
        }
      }
    } else {
      setCompanyDetails({ ...companyDetails, inValidDetails: true });
    }
  };

  const list = (anchor) => (
    <>
      <Container fluid={true}>
        <Row className={AddCompanyCss.addCompany_Row_header}>
          <span>
            {props.isOpen.type === "add" ? (
              <> Add Company</>
            ) : (
              <> Edit Company</>
            )}
          </span>
          <span>
            <CancelIcon onClick={toggleDrawer(false)} />
          </span>
        </Row>
        <Row className={AddCompanyCss.addCompany_Row_body}>
          <Col md="12">
            <BinarywayInput
              label={"Company Name"}
              type={"text"}
              validators={["required", "maxLength"]}
              errormessages={[
                "this field is required",
                "Max Length of the field is 50",
              ]}
              value={companyDetails.CompanyName}
              defaultValue={props.dataFromList ? props.dataFromList.name : ""}
              onChange={(event) => onUserDetailsUpdate("CompanyName", event)}
            ></BinarywayInput>
          </Col>
        </Row>
        {companyDetails.inValidDetails === true && (
          <Row className={AddCompanyCss.addCompany_Row_error}>
            Please enter a valid company name
          </Row>
        )}
        <Row className={AddCompanyCss.addCompany_Row_button}>
          <BinarywayButton
            variant="outlined"
            label="Close"
            onClick={toggleDrawer(false)}
          ></BinarywayButton>
          <div style={{ marginLeft: '10px' }}>
            <BinarywayButton
              label={props.isOpen.type === "add" ? "Save" : "Update"}
              onClick={() => onSaveCompanyDetails()}
            ></BinarywayButton>
          </div>
        </Row>
      </Container>
    </>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          id={"adminAddCompany_drawer"}
          anchor="right"
          open={props.isOpen.isOpenAddCompany}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default AddCompany;
