import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import Paper from "@material-ui/core/Paper";

import ListCompanyCss from "./ListCompany.module.css";
import AddCompany from "../AddCompany/AddCompany";
import BinarywayButton from "../../../UI-Components/Button/Button";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import {
  getCompanyListDetails,
  postDeleteCompanyDetails,
  getPermissionStatus,
} from "../../../../service/service";
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import { fade, makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  search: {
    margin: "5px",
    position: "relative",

    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    float: "right",
    borderRadius: "5px"
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  filterColor: {
    color: "red"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    display: "block",

    padding: "0.8rem 0.5rem",
    width: "100%",

    border: "1px solid black",
    borderRadius: "12px"

    // ?boxShadow: "0px 2px 3px 1px #bab8af",

  },

  card: {
    width: '100%',
    marginBottom: "1rem"
  },
  selectedCard: {
    width: '100%',
    border: "1px solid #4d8fd1",
    marginBottom: "1rem"

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  popList: {
    boxShadow: "0px !important",
    padding: "0.6rem",
    boxShadow: "0px 2px 1px 1px grey",
    display: "flex"
  },
  iconStyle: {
    margin: "0px 0.2rem"
  }

}));

const ListCompany = (props) => {
  const classes = useStyles();
  let currentCompany = {};

  const [ListCompany, SetListCompany] = useState([]);
  const [companyForm, SetCompanyForm] = useState({
    data: null,
    isOpenAddCompany: false,
  });
  const [filterdCompanies, setfilterdCompanies] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dialogOpen, setdialogOpen] = useState(false);
  const [dialogMessage, setdialogMessage] = useState('');
  const [dialogButtonText, setdialogButtonText] = useState('');
  const [dialogNext, setdialogNext] = useState(null);
  const { appData, setAppData } = useContext(AppContext);

  useEffect(() => {
    onLoadSetData();
  }, []);

  const onLoadSetData = () => {
    getCompanyListDetails().then((result) => {
      if (result.success === true) {
        SetListCompany(result.data);
        setfilterdCompanies(result.data)
      } else {
        SetListCompany([]);
      }
    });
  };

  const onAddCompanyButton = () => {
    SetCompanyForm({ isOpenAddCompany: true, data: null, type: "add" });
  };

  const onUserCompany = (data) => {
    SetCompanyForm({ isOpenAddCompany: false, data: null });
    const _msg =
      data === "successUpdate"
        ? "Company details updated successfully"
        : "Company added successfully";
    onAlert(true, _msg);
    onLoadSetData();
  };

  function showDialog(company, message, buttonText, next) {
    currentCompany = company
    setdialogMessage(message)
    setdialogButtonText(buttonText)
    setdialogOpen(true)
    setdialogNext(next)
  }

  const handleDialogClose = () => {
    setdialogOpen(false)
  }

  const onDeleteCompany = () => {
    let company = currentCompany

    const body = {
      companyid: company.id,
    };
    postDeleteCompanyDetails(body).then((result) => {
      if (result && result.success === true) {
        onAlert(true, "Company deleted successfully");
        onLoadSetData();
      }
    });
    setdialogOpen(false)
  };

  const onAlert = (bol, msg) => {
    appData.ShowAlert = {
      bool: true,
      msg: msg,
      type: "success",
    };
    setAppData({ ...appData, ShowAlert: appData.ShowAlert });
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    if (ListCompany) {
      setfilterdCompanies(ListCompany.filter(company => {
        const regExp = new RegExp(`${e.target.value}`, "gi");
        return (company.name).match(regExp)
      }));
    }
  };

  return (
    <>
      <Container fluid={true}>
        <Row className={ListCompanyCss.listCompany_header}>
          <Col md="4" className={ListCompanyCss.listCompany_header_title}>
            Company List
          </Col>
          <Col md="4">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Col>
          <Col md="4">
            {appData.Permissions &&
              getPermissionStatus(
                appData.Permissions,
                appConstants.CREATE_COMPANY
              ) && (
                <div className={ListCompanyCss.listCompany_header_button}>
                  <BinarywayButton
                    onClick={onAddCompanyButton}
                    label="Add Company"
                  ></BinarywayButton>
                </div>
              )}
          </Col>
        </Row>

        <Row className={ListCompanyCss.listCompany_body_background}>
          <Col xs="12" className="py-2">
            <Paper elevation={3}>
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.LIST_COMPANY
                ) &&
                filterdCompanies &&
                filterdCompanies.length > 0 ? (
                  <div className={ListCompanyCss.listCompany_body_content}>
                    {filterdCompanies &&
                      filterdCompanies.map((x, index) => (
                        <Paper
                          elevation={3}
                          key={index}
                          className={
                            ListCompanyCss.listCompany_body_content_company
                          }
                        >
                          <span>{x.name}</span>

                          {x.name !== "BinaryWay" &&
                            appData.Permissions &&
                            getPermissionStatus(
                              appData.Permissions,
                              appConstants.DELETE_COMPANY
                            ) && (
                              <span
                                className={
                                  ListCompanyCss.listCompany_body_content_company_btn
                                }
                                onClick={() => showDialog(x, `Do you want to delete company '${x.name}' ?`, 'Delete', () => onDeleteCompany)}
                              >
                                <i
                                  className="fa fa-trash-o deleteicon"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          {x.name !== "BinaryWay" &&
                            appData.Permissions &&
                            getPermissionStatus(
                              appData.Permissions,
                              appConstants.UPDATE_COMPANY
                            ) && (
                              <span
                                className={
                                  ListCompanyCss.listCompany_body_content_company_btn
                                }
                                onClick={() => {
                                  SetCompanyForm({
                                    isOpenAddCompany: true,
                                    data: x,
                                    type: "edit",
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-pencil deleteicon"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </Paper>
                      ))}
                  </div>
                ) : (
                  <Row className="py-4">
                    <Col md="12" style={{ textAlign: "center" }}>
                      Seems Like you have not created any users yet. You can quicky
                      add it
                    </Col>
                  </Row>
                )}
            </Paper>
          </Col>
        </Row>
      </Container>
      <AddCompany
        isOpen={companyForm}
        setOpen={SetCompanyForm}
        onChange={onUserCompany}
        dataFromList={companyForm.data}
      ></AddCompany>

      <Dialog onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <span style={{ textAlign: 'right', paddingBottom:"5px", paddingRight:"7px" }}>
          <BinarywayButton onClick={handleDialogClose} color="default" label="Close" autoFocus>
          </BinarywayButton>
          <BinarywayButton onClick={() => dialogNext()} color="primary" label={dialogButtonText} autoFocus>
          </BinarywayButton>
        </span>
      </Dialog>
    </>
  );
};

export default ListCompany;
