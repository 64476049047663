import React from 'react';

export const emailRegex= /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
export const getHelperText = (type) => {
    switch (type) {
        case 'Naming Convention': return <div>
            Select this rule type to validate all your components(Object, Process, Stage, Elements, etc.) are compliant with your Company's naming standards. Supply a wildcard/regex to match the expected Naming convention.
            Need help building regex? There are ample of websites like https://regex101.com/ to help you build one for yourself!
        </div>

        case 'Exists': return <div>
            Select this rule type to check if any given component or a component property exists or not in your release file. 
For example: Ensure appropriate Run mode exists on Object, or breakpoint does not exist on Stage
        </div>


        case 'Whether all components are used?': return <div>
            Having a component declaration which goes unused OR making use of an undeclared component might be inefficient. Select this rule type to examine such scenarios.
        </div>

        case 'Process Flow': return <div>
             A process flow is a sequence of actions that are necessary to achieve a goal. The sequence may be multiple stages to be present in a particular order OR a pair of components which needs to co-exist, etc. Use this rule type to validate a process flow.
            
            <div>Example: Every 'Create instance' of a MS Excel VBO should have a corresponding 'Close instance'</div>
        </div>

        case 'Structure': return <div>
           Use this rule type to validate if the structure of the release file is laid out properly. 
For example: Check if 'Element tree' is laid out logically, etc.
        </div>

        case 'Value Check': return <div>
           {`Simple check of value for the corresponding component. You can choose from a list of operators(=, <, >) to evaluate the equality of the condition.
One example is to check of the zoom value is set to 100%`}
            </div>
        case 'Compare Template': return <div>
            Select this rule type to compare a pre-loaded Template with your bp release file. It compares  whether all the Global Data Items in the template are  present in the BP release file. If you don't find a template while creating this rule please contact your system administrator
        </div>

    }
}