import React, { useState, useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";

import ListUser from "../User/ListUser/ListUser";
import ListTemplate from ".././Template/ListTemplate/ListTemplate"
import { getPermissionStatus } from "../../../service/service";
import { AppContext } from "../../../service/context";
import { appConstants } from "../../../service/constants";
import AdminCss from "./Admin.module.css";
import ListExecutionAudit from "../ExecutionAudit";

const Admin = (props) => {
  const { appData, setAppData } = useContext(AppContext);
  const [selectedTab, SetSelectedTab] = useState({ tab: "User" });
  const onNavClick = (type) => {
    SetSelectedTab({ tab: type });
  };
  return (
    <>
      <div id="superAdminNav">
        <Nav>
          <NavItem onClick={() => onNavClick("User")}>
            <NavLink
              className={
                selectedTab.tab === "User"
                  ? AdminCss.admin_selected
                  : ""
              }
            >
              User List
            </NavLink>
          </NavItem>
          <NavItem onClick={() => onNavClick("Template")}>
            <NavLink
              className={
                selectedTab.tab === "Template"
                  ? AdminCss.admin_selected
                  : ""
              }
            >
              Templates
            </NavLink>
          </NavItem>
          <NavItem onClick={() => onNavClick("ExecutionAudit")}>
            <NavLink
              className={
                selectedTab.tab === "ExecutionAudit"
                  ? AdminCss.admin_selected
                  : ""
              }
            >
              Execution Audit
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div>
        {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_USER) &&
          selectedTab &&
          selectedTab.tab === "User" && (
            <ListUser></ListUser>
          )}
        {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_TEMPLATE) &&
          selectedTab &&
          selectedTab.tab === "Template" && (
            <ListTemplate></ListTemplate>
          )}
          {appData.Permissions &&
          getPermissionStatus(appData.Permissions, appConstants.LIST_EXECUTION) &&
          selectedTab &&
          selectedTab.tab === "ExecutionAudit" && (
            <ListExecutionAudit></ListExecutionAudit>
          )}
      </div>
    </>
  );
};

export default Admin;
