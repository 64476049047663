import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import BinarywayInput from "../../../UI-Components/Input/Input";
import BinarywayButton from "../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import "bootstrap/dist/css/bootstrap.css";
import AddUserCss from "./AddUser.module.css";
import CloseIcon from '@material-ui/icons/Close';
import {
  postAddUserDetails,
  postUpdateUserDetails,
  getCompanyListDetails,
  getRoles,
  getUserDetailsFromJwtToken,
} from "../../../../service/service";
import AppBar from "@material-ui/core/AppBar";


const useStyles = makeStyles({
  list: {
    width: "25rem",
  },
  fullList: {
    width: 'auto',
  },
  addUserAppbar: {
    padding: "1.2rem 1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    color: "white",
    fontSize: "1.2rem",
    boxShadow: "none",
    fontWeight: 500,
    marginBottom: "1.5rem"

  },
  inputCol: {
    marginBottom: "1.3rem"
  }
});

const AddUser = (props) => {
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState({
    FirstName: null,
    LastName: null,
    PhoneNumber: null,
    EmailId: null,
    CompanyId: props.companyId || null,
    RoleId: null,
    isVaildResponse: true,
    inValidDetails: false,
    errorMessage: "",
    active: true
  });

  const [viewType, setViewType] = useState("");
  const [ListCompany, SetListCompany] = useState([]);
  const [ListRole, SetListRole] = useState([]);
  const [logedInUser, SetLogedInUser] = useState(getUserDetailsFromJwtToken());

  const resetUserDetails = () => {
    setUserDetails({
      FirstName: null,
      LastName: null,
      PhoneNumber: null,
      EmailId: null,
      CompanyId: props.companyId || null,
      RoleId: null,
      isVaildResponse: true,
      inValidDetails: false,
      errorMessage: "",
      active: true
    })
    props.setIsAddUser(false);
  }

  useEffect(() => {
    onLoadSetData();
    onCompanyList();
    onRoleList();
  }, [props.dataFromList]);

  const onLoadSetData = () => {
    if (props && props.dataFromList) {
      setUserDetails({
        FirstName: props.dataFromList.firstname,
        LastName: props.dataFromList.lastname,
        PhoneNumber: props.dataFromList.phone,
        EmailId: props.dataFromList.email,
        CompanyId: props.dataFromList.company_id,
        RoleId: props.dataFromList.role_id,
        isVaildResponse: true,
        inValidDetails: false,
        active: props.dataFromList.active
      });
      setViewType("Edit");
    } else {
      setViewType("Add");
    }
  };

  const onCompanyList = () => {
    if (logedInUser) {
      if (logedInUser.role === "super admin") {
        getCompanyListDetails().then((result) => {
          if (result.success === true) {
            result.data = result.data.map((y) => {
              const _x = { value: y.id, displayName: y.name };
              return _x;
            });
            SetListCompany(result.data);
          } else {
            SetListCompany([]);
          }
        });
      }
    } else {
      SetListCompany([]);
    }
  };

  const onRoleList = () => {
    getRoles().then((result) => {
      if (result.success === true) {
        result.data = result.data.map((y) => {
          const _x = { value: y.id, displayName: y.name };
          return _x;
        });
        if (logedInUser) {
          if (logedInUser.role === "super admin" || logedInUser.role === "admin") {
            let _list = [];
            result.data.forEach((element) => {
              if (element.displayName !== "super admin") {
                _list.push(element);
              }
            });
            SetListRole(_list);
          } else {
            SetListRole(result.data);
          }
        } else {
          SetListRole(result.data);
        }
      } else {
        SetListRole([]);
      }
    });
  };

  const onUserDetailsUpdate = (type, event) => {
    let value = event.target.value;
    let emailValid = true;

    if (type === "FirstName" || type === "LastName") {
      value = value.replace(/[^A-Za-z]/gi, "");
    } else if (type === "PhoneNumber") {
      value = value.replace(/[^0-9]/gi, "");
    } else if(type === 'EmailId') {
      emailValid = validateEmail(value);

    }

    

    setUserDetails({ ...userDetails, [type]: value, emailValid });
  };

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSaveUserDetails = async () => {
    if (
      (userDetails &&
        userDetails.FirstName &&
        userDetails.LastName &&
        userDetails.PhoneNumber &&
        userDetails.EmailId && userDetails.emailValid) || (
        props && props.dataFromList &&
        props.dataFromList.firstname &&
        props.dataFromList.lastname &&
        props.dataFromList.email &&
        props.dataFromList.company_id &&
        props.dataFromList.role_id
      )
    ) {
      setUserDetails({ ...userDetails, inValidDetails: false });
      if (props && props.dataFromList) {

        const body = {
          first_name: userDetails.FirstName,
          last_name: userDetails.LastName,
          contact: userDetails.PhoneNumber,
          email: userDetails.EmailId,
          username: userDetails.EmailId,
          userid: props.dataFromList.id,
          companyid: userDetails.CompanyId,
          roleid: props.dataFromList.role_id,
          active: userDetails.active
        };
        const result = await postUpdateUserDetails(body);
        if (result && result.success === true) {
          resetUserDetails();
          props.onChange("successUpdate");
        }
      } else {
        const body = {
          first_name: userDetails.FirstName,
          last_name: userDetails.LastName,
          contact: userDetails.PhoneNumber,
          email: userDetails.EmailId,
          username: userDetails.EmailId,
          companyid:
            props.isSuperAdmin === true
              ? userDetails.CompanyId
              : logedInUser.compid,
          roleid: userDetails.RoleId,
        };

        const result = await postAddUserDetails(body);
        if (result && result.success === true) {
          resetUserDetails();
          props.onChange("successAdd");

        } else {
          setUserDetails({
            ...userDetails,
            inValidDetails: true,
            errorMessage: result.message,
          });
        }
      }
    } else {
      setUserDetails({ ...userDetails, inValidDetails: true });
    }

  };

  const list = (anchor) =>
    (<div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"

    >
      <AppBar position="static" className={classes.addUserAppbar}>
        <span>{props.dataFromList ? 'Edit User' : 'Add User'}</span>
        <CloseIcon onClick={() => props.setIsAddUser(false)} />

      </AppBar>
      {userDetails.inValidDetails && userDetails.errorMessage && (
        <p style={{ color: "red" }}>{userDetails.errorMessage}</p>
      )}
      <Row className={AddUserCss.addUser_Row_body}>
        <Col md="12" className={classes.inputCol}>
          <BinarywayInput
            label={"First Name"}
            type={"text"}
            value={userDetails.FirstName}
            defaultValue={props.dataFromList ? props.dataFromList.firstname : ''}
            onChange={(event) => onUserDetailsUpdate("FirstName", event)}
            style={{ width: "100%" }}
            inputProps={{ maxLength: 50 }}
            validators={["required", "maxLength"]}
            errormessages={[
              "this field is required",
              "Max Length of the field is 50",
            ]}
          ></BinarywayInput>
          {userDetails.inValidDetails &&
            (userDetails.FirstName === "" ||
              userDetails.FirstName === null) && (
              <small style={{ color: "red" }}>Firstname is required</small>
            )}
        </Col>
        <Col md="12" className={classes.inputCol}>
          <BinarywayInput
            label={"Last Name"}
            type={"text"}
            value={userDetails.LastName}
            validators={["required", "maxLength"]}
            inputProps={{ maxLength: 50 }}
            errormessages={[
              "this field is required",
              "Max Length of the field is 50",
            ]}
            defaultValue={props.dataFromList ? props.dataFromList.lastname : ''}
            onChange={(event) => onUserDetailsUpdate("LastName", event)}
            style={{ width: "100%" }}
          ></BinarywayInput>
          {userDetails.inValidDetails &&
            (userDetails.LastName === "" ||
              userDetails.LastName === null) && (
              <small style={{ color: "red" }}>Lastname is required</small>
            )}
        </Col>


        <Col md="12" className={classes.inputCol}>
          <BinarywayInput
            label={"Phone Number"}
            type={"text"}
            maxLength={15}
            value={userDetails.PhoneNumber}
            onChange={(event) => onUserDetailsUpdate("PhoneNumber", event)}
            defaultValue={props.dataFromList ? props.dataFromList.phone : ''}
            style={{ width: "100%" }}
            inputProps={{ maxLength: 15 }}
            validators={["required", "maxLength"]}
            errormessages={[
              "this field is required",
              "Max Length of the field is 15",
            ]}
          ></BinarywayInput>
          {userDetails.inValidDetails &&
            (userDetails.PhoneNumber === "" ||
              userDetails.PhoneNumber === null) && (
              <small style={{ color: "red" }}>Phone number is required</small>
            )}
        </Col>
        <Col md="12" className={classes.inputCol}>
          {" "}
          <BinarywayInput
            label={"Email Id"}
            type={"email"}
            value={userDetails.EmailId}
            defaultValue={props.dataFromList ? props.dataFromList.email : ''}
            disabled={props.dataFromList ? true : false}
            onChange={(event) => onUserDetailsUpdate("EmailId", event)}
            style={{ width: "100%" }}
            validators={["required", "isEmail"]}
            errormessages={[
              "this field is required",
              "email is not valid",
            ]}
          ></BinarywayInput>
          {userDetails.inValidDetails &&
            (userDetails.EmailId === "" || userDetails.EmailId === null) && (
              <small style={{ color: "red" }}>Email is required</small>
            )}
        </Col>


        <Col md="12" className={classes.inputCol}>
          {props.isSuperAdmin === true ? (
            (props.dataFromList && props.dataFromList.company_id ?
              <BinarywayInput
                label={"Company"}
                type={"text"}
                value={props.dataFromList && props.dataFromList.company}
                defaultValue={props.dataFromList && props.dataFromList.company_id}
                onChange={(event) => onUserDetailsUpdate("", event)}
                disabled={true}
              ></BinarywayInput>
              :
              <>
                <BinarywayDropdown
                  placeHolder=""
                  label="Company"
                  value={userDetails.CompanyId}
                  options={ListCompany}
                  disabled={
                    viewType === "Edit" ? true : false
                  }
                  onChange={(event) => onUserDetailsUpdate("CompanyId", event)}

                />
                {userDetails.inValidDetails &&
                  (userDetails.CompanyId === "" ||
                    userDetails.CompanyId === null) && (
                    <small style={{ color: "red" }}>Company is required</small>
                  )}
              </>)
          ) : (
              <>
                <div className={AddUserCss.addUser_Row_Company_header}>
                  Company:
                </div>
                <div className={AddUserCss.addUser_Row_Company_subheader}>
                  {logedInUser.companyname}
                </div>
              </>
            )}
        </Col>
        <Col md="12" className={classes.inputCol}>
          {props.dataFromList && props.dataFromList.role ?
            <BinarywayInput
              label={"Role"}
              type={"text"}
              value={props.dataFromList && props.dataFromList.role}
              defaultValue={props.dataFromList && props.dataFromList.rolee}
              onChange={(event) => onUserDetailsUpdate("", event)}
              disabled={true}
            ></BinarywayInput> : <BinarywayDropdown
              placeHolder=""
              label="Role"
              value={userDetails.RoleId}
              options={ListRole}
              onChange={(event) => onUserDetailsUpdate("RoleId", event)}
            />}
          {userDetails.inValidDetails &&
            (userDetails.RoleId === "" || userDetails.RoleId === null) && (
              <small style={{ color: "red" }}>Role is required</small>
            )}
        </Col>


        {/* {userDetails.inValidDetails === true && (
          <Row className={AddUserCss.addUser_Row_error}>
            Please enter all the details above.
          </Row>
        )} */}
        <Col md={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <BinarywayButton
            label={"Cancel"}
            variant={"outlined"}
            color="primary"
            onClick={() => resetUserDetails()}
          ></BinarywayButton>
          <div style={{ marginLeft: '10px' }}>
            <BinarywayButton
              label={"Save"}
              onClick={() => onSaveUserDetails()}
            ></BinarywayButton>
          </div>
        </Col>

      </Row>
    </div>

    )


  return (
    <>
      {/* <Container fluid={true}>
     
        
      </Container> */}
      <SwipeableDrawer

        open={props.isAddUser}
        onClose={props.toggleDrawer(false)}
        onOpen={props.toggleDrawer(true)}
        anchor="right"
      >
        {list('right')}
      </SwipeableDrawer>
    </>
  );
};

export default AddUser;
