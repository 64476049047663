import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Row, Col, Container } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";
import Tooltip from "@material-ui/core/Tooltip";
import {
  deleteAnalyzer,
  getPermissionStatus,
  getAnalyzer,
  getCompanyListDetails,
  getUserDetailsFromJwtToken,
} from "../../../../service/service";

import CustomCard from "../../../UI-Components/Card/Card";

import { AppContext, AnalyzerContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import RuleListPng from "../../../../assets/RuleList.png";
import AnalyzerViewInfo from "../AnalyzerViewInfo/AnalyzerViewInfo";
import BinarywayButton from "../../../UI-Components/Button/Button";
import ListAnalyzerCss from "./ListAnalyzer.module.css";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid lightgrey",
    borderRadius: 12,
  },
  cardTitltContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "16px",
  },
  cardFooterContainer: {
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  cardMainDescription: {
    marginBottom: 7,
    fontSize: 14,
    color: "#000000",
    fontWeight: "normal",
    padding: 5,
  },
  cardNumOfRules: {
    color: "#919294",
    marginRight: 3,
    fontSize: 14,
    padding: 5,
  },
  cardTitle: {
    color: "white",
    fontSize: 18,
  },
  selectInputField: {
    width: "80%",
    "& label": {
      color: "#43b0f1",
    },
    borderColor: "red",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#43b0f1",
      },
      "&:hover fieldset": {
        borderColor: blue[700],
      },
      "&.Mui-focused fieldset": {
        borderColor: blue[900],
      },
    },
  },
}));

export const ListAnalyzer = (props) => {
  const classes = useStyles();

  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [viewInfoDetails, setViewInfoDetails] = useState({});
  const [AnalyzerListDetails, SetAnalyzerListDetails] = useState([]);
  const [ConfigKey, SetConfigKey] = useState([]);
  const { appData, setAppData } = useContext(AppContext);
  const { analyzerData, setAnalyzerData } = useContext(AnalyzerContext);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [userData, setUserData] = useState("");
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [isCompanyFilterVisible, setIsCompanyFilterVisible] = useState(false);
  const [
    completeAnalyzerListDetails,
    setCompleteAnalyzerListDetails,
  ] = useState([]);

  useEffect(() => {
    onLoadSetData();
    onLoadData();
  }, []);

  useEffect(() => {
    let _list = completeAnalyzerListDetails.filter((x) => {
      if (selectedCompany.id == 0) {
        return true;
      } else if (x.COMPANY_ID == selectedCompany.id) {
        return true;
      }
      return false;
    });
    SetAnalyzerListDetails(_list);
  }, [selectedCompany]);

  useEffect(() => {
    if (userData.roleid & (userData.roleid === 3)) {
      setIsDeveloper(true);
    } else if (userData.roleid & (userData.roleid === 1)) {
      setIsCompanyFilterVisible(true);
    } else {
      setIsDeveloper(false);
      setIsCompanyFilterVisible(false);
    }
  }, [userData]);

  const onLoadData = () => {
    getAnalyzer().then((result) => {
      SetAnalyzerListDetails(result.data);
      setCompleteAnalyzerListDetails(result.data);
      SetConfigKey(result.config_key);
    });
  };

  const onLoadSetData = () => {
    const _userData = getUserDetailsFromJwtToken();
    if (_userData) {
      setUserData(_userData);
      if (_userData.roleid === 1) {
        getCompanyListDetails().then((result) => {
          if (result.success === true) {
            let _data = result.data;
            if (_data && _data.length > 0) {
              _data = _data.map((y) => {
                const _x = { value: y, displayName: y.name };
                return _x;
              });
              _data.unshift({
                value: { id: 0, name: "All" },
                displayName: "All",
              });
              setCompanyList(_data);
              setSelectedCompany(_data[0].value);
            }
          }
        });
      } else {
        setCompanyList([]);
      }
    }
  };

  const onRefresh = () => {
    onLoadData();
  };

  const onDeleteAnalyzer = async (analyzerId) => {
    const res = await deleteAnalyzer(analyzerId);
    if (res.success) {
      onLoadData();
    }
  };

  const onViewAnalyzer = (obj) => {
    setAnalyzerData({ ...analyzerData, viewType: "view", selectedObj: obj });
    props.onGotoAnalyzerView();
  };

  const onViewAnalyzerInfoDetails = (data) => {
    data.rules.map((x) => (x.showDescription = false));
    data.rules.map((x) => {
      ConfigKey.map((c) => {
        if (c.ID == x.CATEGORY_ID) {
          x.category_name = c.NAME;
        }
      });
    });
    setViewInfoDetails(data);
    setOpenViewMore(true);
  };

  const onAddAnalyzer = () => {
    setAnalyzerData({ ...analyzerData, viewType: "add" });
    props.onGotoAnalyzerCreate();
  };

  const onEditAnalyzer = (obj) => {
    setAnalyzerData({ ...analyzerData, viewType: "edit", selectedObj: obj });
    props.onGotoAnalyzerCreate();
  };
  return (
    <>
      <Container fluid={true}>
        <Row className="analyzer_header">
          <Col xs="8">
            <div className="analyzer_header_title">
              <span> List of Analyzers</span>{" "}
            </div>
          </Col>
          <Col xs="2">
            {isCompanyFilterVisible ? (
              <BinarywayDropdown
                placeHolder=""
                label="Company"
                value={selectedCompany}
                options={companyList}
                onChange={(event) => { setSelectedCompany(event.target.value)}}
              />
            ) : (
              ""
            )}
          </Col>

          <Col xs="2">
            <div className="analyzer_stepper_button">
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_ANALYZER
                ) && (
                  <BinarywayButton
                    label={"Add New Analyzer"}
                    onClick={() => onAddAnalyzer()}
                  ></BinarywayButton>
                )}
            </div>
          </Col>
        </Row>
        <Row className="analyzer_body_background">
          <Col xs="12" className={ListAnalyzerCss.analyzer_list_body_main}>
            <div
              className={ListAnalyzerCss.analyzer_list_body_tile_row}
              style={{ overflowX: "hidden" }}
            >
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.LIST_RULE
                ) && (
                  <>
                    {AnalyzerListDetails && AnalyzerListDetails.length > 0 ? (
                      <>
                        <Row className={ListAnalyzerCss.ruleList_body_tile_row}>
                          {AnalyzerListDetails &&
                            AnalyzerListDetails.length > 0 &&
                            AnalyzerListDetails.map((obj, id) => (
                              <Col xs="4" style={{ padding: "8px" }}>
                                <Box
                                  className={classes.cardContainer}
                                  boxShadow={1}
                                >
                                  <Grid container direction="column">
                                    <Box
                                      bgcolor="#0f183f"
                                      pb={3}
                                      className={classes.cardTitltContainer}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.cardTitle}
                                      >
                                        {obj.ANALYZER_NAME}
                                      </Typography>
                                    </Box>
                                    <Box bgcolor="white" p={1}>
                                      <Typography
                                        className={classes.cardMainDescription}
                                      >
                                        {obj.DESCRIPTION}
                                      </Typography>
                                      <Divider />
                                      <Grid
                                        container
                                        item
                                        justify={selectedCompany.name == "All" ? 'flex-start' : 'flex-start'}
                                        alignItems="center"
                                      >
                                        { selectedCompany.name == "All" && <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', overflow:'hidden'}}>
                                        <Typography
                                          className={classes.cardNumOfRules}
                                        >
                                          Company :
                                        </Typography>
                                        <Typography>
                                          {obj.NAME}
                                        </Typography>
                                        </div>}
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                                        <Typography
                                          className={classes.cardNumOfRules}
                                        >
                                          | Rules :
                                        </Typography>
                                        <Typography>
                                          {obj.rules.length}
                                        </Typography>
                                        </div>
                                      </Grid>
                                    </Box>
                                    <Box
                                      bgcolor="#e8edf3"
                                      p={1}
                                      pt={1}
                                      className={classes.cardFooterContainer}
                                    >
                                      <Grid container justify="space-between">
                                        {!isDeveloper ? (
                                          <DeleteIcon
                                            onClick={() =>
                                              onDeleteAnalyzer(obj.ID)
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Box mr={1}>
                                          <Grid container item>
                                            <Box mr={1}>
                                              {!isDeveloper ? (
                                                <CreateIcon
                                                  onClick={() =>
                                                    onEditAnalyzer(obj)
                                                  }
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                            <VisibilityIcon
                                              onClick={() => {
                                                onViewAnalyzer(obj);
                                              }}
                                            />
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Box>
                              </Col>
                            ))}
                        </Row>
                      </>
                    ) : (
                      <div
                        className={
                          ListAnalyzerCss.analyzer_list_paper_noData_body
                        }
                        onClick={() => onAddAnalyzer(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <img
                            className={
                              ListAnalyzerCss.analyzer_list_paper_noData_body_image
                            }
                            src={RuleListPng}
                            alt="BinaryWayLogo"
                          />
                        </div>
                        <div
                          className={
                            ListAnalyzerCss.analyzer_list_paper_noData_body_content
                          }
                        >
                          Click anywhere to Create New Analyzer
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListAnalyzer;
