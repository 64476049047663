import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

import { AnalyzerContext } from "../../../service/context";
import ListAnalyzer from "./ListAnalyzer/ListAnalyzer";
import AnalyzerCreate from "./AnalyzerCreate/AnalyzerCreate";
import AnalyzerViewInfo from "./AnalyzerViewInfo/AnalyzerViewInfo";
import "./Analyzer.css";

const Analyzer = (props) => {
  const [analyzerData, setAnalyzerData] = useState({});

  const onGotoAnalyzerCreate = () => {
    props.history.push("/Dashboard/Analyzer/AnalyzerCreate");
  };

  const onGotoListAnalyzer = () => {
    props.history.push("/Dashboard/Analyzer/ListAnalyzer");
  };

  const onGotoAnalyzerView = (id) => {
    props.history.push("/Dashboard/Analyzer/ViewAnalyzer");
  };

  return (
    <AnalyzerContext.Provider
      value={{
        analyzerData: analyzerData,
        setAnalyzerData: setAnalyzerData,
      }}
    >
      <Switch>
        <Route exact path="/Dashboard/Analyzer/ListAnalyzer">
          <ListAnalyzer onGotoAnalyzerCreate={onGotoAnalyzerCreate} onGotoAnalyzerView={onGotoAnalyzerView}/>
        </Route>
        <Route exact path="/Dashboard/Analyzer/AnalyzerCreate">
          <AnalyzerCreate onGotoListAnalyzer={onGotoListAnalyzer} />
        </Route>
        <Route exact path="/Dashboard/Analyzer/ViewAnalyzer">
          <AnalyzerViewInfo  onGotoListAnalyzer={onGotoListAnalyzer} />
        </Route>        
      </Switch>
    </AnalyzerContext.Provider>
  );
};
export default Analyzer;
