import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { blue } from '@material-ui/core/colors';
import MUIRichTextEditor from 'mui-rte';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  closeButton: {
    position: 'absolute'
  },
});

const CustomDialog = (props) => {
  const classes = useStyles();
  const { onClose, openDialog, data } = props;

  const handleClose = () => {
    onClose();
  };


  const DialogTitle = withStyles(useStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} style={{ position: 'absolute', top: '8px', right: '8px', color: '#9e9e9e' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} >
        REMEDY
      </DialogTitle>
      <DialogContent dividers>
        {
          data ? <MUIRichTextEditor
            id="MUIRichTextEditor_ReadOnlyUI"
            defaultValue={data}
            toolbar={false}
            readOnly={true}
          /> : "-"
          // <div dangerouslySetInnerHTML={{ __html: data && data.remedy ? data.remedy : "" }} />
        }
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;