export const objectData = [
    {
        id: '1',
        label: 'Resource',
        value: 'Resource',
    },
    {
        id: '2',
        label: 'Stage',
        value: 'Stage',
    },
];

export const fieldData = [
    {
        id: '3',
        label: 'Action',
        value: 'Action',

    },
    {
        id: '4',
        label: 'MS Excel VBO',
        value: 'MS Excel VBO',
    },
    {
        id: '5',
        label: 'File Management',
        value: 'File Management'
    },
];

export const conditionData = [
    {
        id: '17',
        label: 'Should contain',
        value: 'Should contain',

    },
    {
        id: '18',
        label: 'Should not contain',
        value: 'Should not contain',

    },
    {
        id: '19',
        label: 'Exists',
        value: 'Exists',

    },
    {
        id: '20',
        label: 'Does not exists',
        value: 'Does not exists',

    },
    {
        id: '21',
        label: 'Count should be',
        value: 'Count should be ',
    },
    {
        id: '22',
        label: 'Starts with',
        value: 'Starts with',
    },
    {
        id: '23',
        label: 'Ends withs',
        value: 'Ends with',
    },
    {
        id: '24',
        label: 'regexp',
        value: 'regexp',
    },
];

export const valueData = [
    {
        id: '25',
        label: 'clsWorkQueuesActions',
        value: 'clsWorkQueuesActions'
    },
    {
        id: '26',
        label: 'breakpoint',
        value: 'breakpoint'
    },
    {
        id: '27',
        label: 'action=Show',
        value: 'action=Show'
    },
    {
        id: '28',
        label: 'action=File Exists',
        value: 'action=File Exists'
    },
    {
        id: '29',
        label: 'single, multiple ,none',
        value: 'single, multiple ,none'
    },
    {
        id: '30',
        label: 'Close instance',
        value: 'Close instance'
    },
];

export const rootData = [
    {
        id: '01',
        label: 'Process.process.Stage',
        value: 'Process.process.Stage'
    },
    {
        id: '02',
        label: 'Object.Process.Stage',
        value: 'Object.Process.Stage'
    },
    {
        id: '03',
        label: 'Process',
        value: 'Process'
    },
];

export const filterObjectData = [
    {
        id: '04',
        label: 'type',
        value: 'type'
    },
    {
        id: '05',
        label: 'action',
        value: 'action'
    },
    {
        id: '06',
        label: 'object',
        value: 'object'
    },
    {
        id: '07',
        label: 'name',
        value: 'name'
    },
];

export const filterObjectValue = [
    {
        id: '08',
        label: 'Action',
        value: 'Action'
    },
    {
        id: '09',
        label: 'Create Instance',
        value: 'Create Instance'
    },
    {
        id: '10',
        label: 'MS Excel VBO',
        value: 'MS Excel VBO'
    },
    {
        id: '11',
        label: '%File Management%',
        value: '%File Management%'
    },
];

export const objectToEvaluate = [
    {
        id: '12',
        label: 'resource',
        value: 'resource'
    },
    {
        id: '13',
        label: 'breakpoint',
        value: 'breakpoint'
    },
]

export const objectFilterValue = [
    {
        id: '14',
        label: 'MS Excel VBO',
        value: 'MS Excel VBO'
    },
    {
        id: '15',
        label: 'Show',
        value: 'Show'
    },
    {
        id: '16',
        label: 'Create Instance',
        value: 'Create Instance'
    },
]

// ---------------------------------
//      New Rebuilder Data 
// ---------------------------------


export const ruleTypeData = [

    {
        id: '1',
        label: 'Naming Convention',
        value: 'Naming Convention',
        isRuleCreteria: true
    },
    {
        id: '2',
        label: 'Exists',
        value: 'Exists',
        isRuleCreteria: false
    },
    {
        id: '28',
        label: 'Does not exist',
        value: 'Does not exist',
        isRuleCreteria: false
    },
    {
        id: '3',
        label: 'Occurences',
        value: 'Occurences',
        isRuleCreteria: true
    },
    {
        id: '4',
        label: 'Pairing',
        value: 'Pairing',
        isRuleCreteria: false,
        isPairing: true
    },
    {
        id: '5',
        label: 'Resources Utilization',
        value: 'Resources Utilization',
        isRuleCreteria: false
    },
    {
        id: '38',
        label: 'Property Value',
        value: 'Property Value',
        isRuleCreteria: false
    },
    {
        id: '39',
        label: 'Structure',
        value: 'Structure',
        isRuleCreteria: false
    },
    // {
    //     id: '45',
    //     label: 'Valid Resource',
    //     value: 'Valid Resource',
    //     isRuleCreteria: false
    // },
];

export const ruleCreteriaData = [
    {
        id: '6',
        label: '<',
        value: '<',
        type: '',
        preId: '2',
    },
    {
        id: '7',
        label: ' = ',
        value: ' = ',
        type: '',
        preId: '2',
    },
    {
        id: '8',
        label: '>',
        value: '>',
        type: '',
        preId: '2',
    },
    {
        id: '29',
        label: '>=',
        value: '>=',
        type: '',
        preId: '2',
    },
    {
        id: '30',
        label: '<=',
        value: '<=',
        type: '',
        preId: '2',
    },
    {
        id: '31',
        label: '!=',
        value: '!=',
        type: '',
        preId: '2',
    },
]

export const ruleComponent = [
    {
        id: '9',
        label: 'Object',
        value: 'Object',
        isChild: 'Object Stages',
        isAttribute: true,
        NA_Pre_Id: ['5'],
    },
    {
        id: '10',
        label: 'Process',
        value: 'Process',
        isChild: 'Process Stages',
        isAttribute: true,
        NA_Pre_Id: ['5', '39'],
    },
    {
        id: '11',
        label: 'Work Queue',
        value: 'Work Queue',
        NA_Pre_Id: ['4', '39', '2', '28', '3', '38', '45', '39'],

    },
    {
        id: '12',
        label: 'Credentials',
        value: 'Credentials',
        NA_Pre_Id: ['4', '39', '2', '28', '3', '38', '45', '39'],
    },
    {
        id: '32',
        label: 'Environment Variable',
        value: 'Environment Variable',
        NA_Pre_Id: ['4', '39', '2', '28', '3', '38', '45', '39'],
    },
    {
        id: '33',
        label: 'Stage',
        value: 'Stage',
        isChild: 'Generic Stages',
        NA_Pre_Id: ['39', '5', '39'],
    },
    {
        id: '33',
        label: 'Release',
        value: 'Release',
        // isChild: 'Generic Stages',
        isAttribute: true,
        NA_Pre_Id: ['39', '3', '4', '5', '38', '45', '39'],
    },
]

export const objectStage = [
    {
        id: '13',
        label: 'Block',
        value: 'Block',
    },
    {
        id: '14',
        label: 'Process',
        value: 'Process',
    },
    {
        id: '15',
        label: 'Page',
        value: 'Page',
    },
    {
        id: '16',
        label: 'Action',
        value: 'Action',
        isChild: true
    },
    {
        id: '17',
        label: 'Data Item',
        value: 'Data Item',
        isChild: 'true'
    },
    {
        id: '18',
        label: 'Collection',
        value: 'Collection',
    },
    {
        id: '19',
        label: 'Note',
        value: 'Note',
    },
    {
        id: '20',
        label: 'Alert',
        value: 'Alert',
    },
    {
        id: '21',
        label: 'Exception',
        value: 'Exception',
    },
    {
        id: '40',
        label: 'Recover',
        value: 'Recover',
        isChild: true
    },
    {
        id: '45',
        label: 'Loop',
        value: 'Loop',
        isChild: true
    },
];

export const businessObject = [
    {
        id: '22',
        label: 'Work Queues',
        value: 'Work Queues',
        isChild: true
    },
    {
        id: '23',
        label: 'Credentials',
        value: 'Credentials',
        isChild: true
    },
    {
        id: '26',
        label: 'ExcelVBO',
        value: 'ExcelVBO',
        isChild: true
    }
]

export const businessActionObject = [
    {
        id: '24',
        label: 'Add To Queue',
        value: 'Add To Queue',
        preId: '22',
        isChild: true
    },
    {
        id: '25',
        label: 'Delete Item',
        value: 'Delete Item',
        preId: '22'

    },
    {
        id: '46',
        label: 'Get Pending Items',
        value: 'Get Pending Items',
        preId: '22',

    },
    {
        id: '47',
        label: 'Set',
        value: 'Set',
        preId: '23',
        isChild: true
    },
    {
        id: '27',
        label: 'Create Instance',
        value: 'Create Instance',
        preId: '26'

    },
    {
        id: '28',
        label: 'Close Instance',
        value: 'Close Instance',
        preId: '26'
    },
]

export const componentAttribute = [
    {
        id: '34',
        label: 'Run Mode',
        value: 'Run Mode',
        isChild: true,
        preId: '9',
        NA_Pre_Id: '39'
    },
    {
        id: '35',
        label: 'Stage',
        value: 'Stage',
        preId: '9',
        NA_Pre_Id: '39'


    },
    {
        id: '36',
        label: 'Stage',
        value: 'Stage',
        preId: '10'

    },
    {
        id: '37',
        label: 'Application Modeller',
        value: 'Application Modeller',
        preId: '9'

    },
    {
        id: '42',
        label: 'Break Point',
        value: 'Break Point',
        preId: '33'

    },
];

export const componentPropertyValue = [
    {
        id: '50',
        label: 'Background',
        value: 'Backgound',
        preId: '34'
    },
    {
        id: '51',
        label: 'Foreground',
        value: 'Foreground',
        preId: '34'
    },
    {
        id: '52',
        label: 'Exclusive',
        value: 'Exclusive',
        preId: '34'
    },

]