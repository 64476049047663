import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import _, { filter } from "lodash";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { Row, Col, Container } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import { fade, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";
import CreateIcon from "@material-ui/icons/Create";
import { ReactComponent as ActiveSVG } from "../../../../assets/active-icon.svg";
import { ReactComponent as DeactiveSVG } from "../../../../assets/deactive-icon.svg";

import {
  getPermissionStatus,
  deleteRule,
  getRuleData,
  getConfig,
  activeRule,
  getUserDetailsFromJwtToken
} from "../../../../service/service";
import RuleDetailsDrawer from "./RuleDetailsDrawer/RuleDetailsDrawer";
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import BinarywayButton from "../../../UI-Components/Button/Button";
import RuleListPng from "../../../../assets/RuleList.png";
import ListRulesCss from "./ListRules.module.css";
import Filter from "./Filter/Filter";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import "../Rules.css";
import { red } from "@material-ui/core/colors";

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  search: {
    margin: "5px",
    position: "relative",
    borderRadius: "5px",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    float: "right",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  filterColor: {
    color: "red"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    display: "block",
    border: "1px solid grey",
    padding: "0.8rem 0.5rem",
    width: "100%",
    borderRadius: "15px",
  },
  customTooltip: {
    backgroundColor: '#c5c2c2',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between'
  },
  customArrow: {
    color: '#c5c2c2',
  },
}));



const ListRules = (props) => {
  const classes = useStyles();
  const [filterRule, setFilterRule] = useState([]);
  const [userDetails, SetUserDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filterColor, setFilterColor] = useState(false);
  const [rulesDetails, SetRulesDetails] = useState({
    addRule: false,
    disableBtn: false,
    rules: [],
  });
  const [checkedFilter, setCheckedFilter] = useState({
    ruleTypeChecked: true,
    categoryTypeChecked: true,
    customcategoryTypeChecked: true,
    allChecked: true
  })
  const [nestedFilter, setNestedFilter] = useState({
    ruleTypeChecked: {
      namingConvention: true,

    }
  })
  const [currentRule, setCurrentRule] = useState(null);
  const [configData, setConfigData] = useState([]);
  const { appData, setAppData } = useContext(AppContext);

  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [ruleTypes, setRuleTypes] = useState([]);
  const [customCategory, setCustomCategory] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState({
    builtIn: true,
    custom: true
  })

  let currRuleForStatus = null;

  // const open = Boolean(anchorEl);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [dialogMessage, setdialogMessage] = useState('');
  const [dialogButtonText, setdialogButtonText] = useState('');
  const [dialogNext, setdialogNext] = useState(null);
  // const [isOpenViewMore, setOpenViewMore] = useState(false);

  function showDialog(rule, message, buttonText, next) {
    currRuleForStatus = rule;
    setdialogMessage(message)
    setdialogButtonText(buttonText)
    setdialogOpen(true)
    // setdialogNext(next)
    onActiveRule(rule)
  }



  const toggleAddRule = () => {
    props.history.push("/Dashboard/RuleBuilder/RulesBasicDetails");
  };



  const editRule = (ruleData) => {
    props.history.push({
      state: {
        editData: ruleData,
        isEdit: true
      },
      pathname: '/Dashboard/RuleBuilder/RulesBasicDetails',
    })
  };

  // useEffect(()=>{
  //    setFilterRule
  // },[])

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(open);
  };
  const onSearchChange = (e) => {
    setSearchText(e.target.value);

    let filterdData = rulesDetails.rules.filter((obj) => obj.NAME.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilterRule(filterdData);
  };

  const onReset = () => {
    setOpenFilter(!isOpenFilter)
    setCheckedFilter({ ...checkedFilter, allChecked: true, ruleTypeChecked: true, categoryTypeChecked: true, customcategoryTypeChecked: true })
    const resetRuleTypes = ruleTypes.map(rule => {
      rule.isChecked = true;
      return rule;
    })
    const resetCustomCategory = customCategory.map(category => {
      category.isChecked = true;
      return category;
    })
    setCustomCategory(resetCustomCategory);
    setRuleTypes(resetRuleTypes);
    setCategoryTypes({ ...categoryTypes, builtIn: true, custom: true });
    setFilterColor(false);
    setFilterRule(rulesDetails.rules);
  }

  const onApplyFilter = () => {

    const filterRules = ruleTypes.filter(rule => rule.isChecked === true);
    const filterRuleNames = filterRules.map(filterRule => (filterRule.NAME));

    const filterCategory = customCategory.filter(rule => rule.isChecked === true);
    const filterCategoryNames = filterCategory.map(filterRule => (filterRule.NAME));
    let allrule = rulesDetails.rules;
    let filterdData = [];
    if (filterCategoryNames.length > 0) {
      filterdData = allrule.filter(obj => {

        return filterCategoryNames.includes(obj.CATEGORY);
      });
    }

    if (filterRuleNames.length > 0) {
      let filterdRuleData = allrule.filter(obj => {

        return filterRuleNames.includes(obj.RULE_TYPE);
      });
      filterdData.push(...filterdRuleData);
    }

    if (categoryTypes.builtIn && !categoryTypes.custom) {
      if (filterdData.length == 0) {
        filterdData = rulesDetails.rules;
      }
      filterdData = filterdData.filter(obj => obj.COMPANY_ID === -1);

    } else if (!categoryTypes.builtIn && categoryTypes.custom) {
      if (filterdData.length == 0) {
        filterdData = rulesDetails.rules;
      }
      filterdData = filterdData.filter(obj => obj.COMPANY_ID !== -1);

    } else if (categoryTypes.builtIn && categoryTypes.custom) {
      if (filterdData.length == 0) {
        filterdData = rulesDetails.rules;
      }
    }
    setFilterRule(filterdData);
    if (!checkedFilter.allChecked) {
      setFilterColor(true)
    } else {
      setFilterColor(false);
    }


    setOpenFilter(!isOpenFilter)


  }

  const onDeleteRule = async (ruleId) => {
    const res = await deleteRule(ruleId);
    if (res.success) {
      handleRuleData();
    }
  };
  const handleRuleData = async () => {
    const newRules = await getRuleData();
    if (newRules.success === true) {
      SetRulesDetails({ ...rulesDetails, rules: newRules.data });
      setFilterRule(newRules.data);
    } else {

    }
  };

  const getConfigData = async () => {
    const configKeys = await getConfig();
    if (configKeys.success) {
      setConfigData(
        configKeys.data.recordsets[0] && configKeys.data.recordsets[0].length > 0
          ? configKeys.data.recordsets[0].map((y) => {
            const _x = { value: y, displayName: y.NAME };
            return _x;
          })
          : []
      );
      let ruleNames = configKeys.data.recordsets[0].filter(data => data.TYPE === "COMPONENT_TYPE")
      ruleNames = ruleNames.map(rule => {
        rule.isChecked = true;
        return rule;
      })
      setRuleTypes(ruleNames);

      let customCategory = configKeys.data.recordsets[0].filter(data => data.TYPE === "CATEGORY")
      customCategory = customCategory.map(category => {
        category.isChecked = true;
        return category;
      })
      setCustomCategory(customCategory);
    } else {
      //Handle Not correct request here
    }
  };

  useEffect(() => {
    const _userDetails = getUserDetailsFromJwtToken();

    SetUserDetails(_userDetails);

    handleRuleData();
    getConfigData();
  }, []);

  const onViewMore = (obj) => {
    setCurrentRule(obj);
    setOpenViewMore(true);
  };

  const onFilter = () => {
    setOpenFilter(true);
  };

  const onActiveRule = async (rule) => {
    setdialogOpen(false)
    const body = {
      RULE_ID: currRuleForStatus.ID,
      DEACTIVE: currRuleForStatus.DEACTIVE ? 0 : 1
    }

    const res = await activeRule(body);
    rule.DEACTIVE = currRuleForStatus.DEACTIVE ? 0 : 1
    handleRuleData()



    if (res.data.success) {
      appData.ShowAlert = {
        bool: true,
        msg: `Rule ${!currRuleForStatus.DEACTIVE ? "Acitaved" : "Deactivated"} Successfully`,
        type: "success"
      };
      setAppData({ ...appData, ShowAlert: appData.ShowAlert });
      // onCancelButton();
    } else {
      appData.ShowAlert = {
        bool: true,
        msg: "Some Problem. Please try again. if problem persist, contact support",
        type: "error",
      };
      setAppData({ ...appData, ShowAlert: appData.ShowAlert });
      // onCancelButton();
    }
    setdialogOpen(false)

  }


  const handleDialogClose = () => {
    setdialogOpen(false)
  }


  return (
    <>
      <Container fluid={true}>
        <Row className="rules_header">
          <Col xs="4">
            <div className="rules_header_title">
              <span> List of Rules</span>{" "}
              <span>
                <FilterListIcon className={filterColor ? classes.filterColor : null} onClick={() => onFilter()} />
              </span>
            </div>
          </Col>
          <Col xs="5">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Col>

          <Col xs="3">
            <div className="rules_stepper_button">
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_RULE
                ) && (
                  <BinarywayButton
                    label={"Add New Rule"}
                    onClick={() => toggleAddRule()}
                    // disabled={userDetails.role !== 'super admin'}
                  ></BinarywayButton>
                )}
            </div>
          </Col>
        </Row>
        <Row className="rules_body_background">
          <Col xs="12" className={ListRulesCss.ruleList_body_main}>
            <div
              className={ListRulesCss.ruleList_body_tile}
              style={{ overflowX: "hidden" }}
            >
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.LIST_RULE
                ) && (
                  <>
                    {rulesDetails.rules &&
                      rulesDetails.rules.length > 0 &&
                      rulesDetails.addRule === false ? (
                        <>
                          <Row className={ListRulesCss.ruleList_body_tile_row}>
                            {filterRule &&
                              filterRule.length > 0 &&
                              filterRule.map((obj, id) => (
                                <Col xs="4" key={id + obj.NAME} className={ListRulesCss.cardMainBox}>
                                  <Paper elevation={3}>
                                    <div className={obj.DEACTIVE ? ListRulesCss.cardDeactivatedContent : ListRulesCss.cardActivatedContent}>
                                      <div className={ListRulesCss.cardheaderContent}>
                                        <Tooltip title={obj.NAME} placement="bottom" 
                                        classes={{
                                          tooltip: classes.customTooltip,
                                          arrow: classes.customArrow
                                        }}
                                        arrow>
                                          <span className={ListRulesCss.cardheaderText}>{obj.NAME}</span>
                                        </Tooltip>
                                      </div>
                                      <div className={ListRulesCss.cardMiddleContent}>
                                        <div className={ListRulesCss.cardMiddleContentDescriptonBlock}>
                                          {/* <span class={ListRulesCss.cardMiddleContentBlockTitle}>Description: </span> */}
                                          <Tooltip title={obj.DESCRIPTION || "-"} placement="bottom"
                                          classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                          }}
                                          arrow>
                                            <span className={ListRulesCss.cardMiddleContentDescriptionValue} >
                                              {obj.DESCRIPTION || "-"}
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <hr />
                                        <div className={ListRulesCss.cardMiddleContentBlock}>
                                          <span class={ListRulesCss.cardMiddleContentBlockTitle}>Rule Type: </span>
                                          <span className={ListRulesCss.cardMiddleContentBlockValue} >
                                            {obj.RULE_TYPE || "-"}
                                          </span>
                                        </div>
                                        <hr />
                                        <div className={ListRulesCss.cardMiddleContentBlock}>
                                          {obj.RULE_TYPE !== 'Compare Template' ?
                                            (<div>
                                              <span class={ListRulesCss.cardMiddleContentBlockTitle}> Component Type: </span>
                                              <span className={ListRulesCss.cardMiddleContentBlockValue} >
                                                {obj.COMPONENT_NAME || "-"}
                                              </span></div>) :
                                            (<div><span class={ListRulesCss.cardMiddleContentBlockTitle}> Template: </span>
                                              <span className={ListRulesCss.cardMiddleContentBlockValue} >
                                                {obj.ATTRIBUTE_PROPERTY || "-"}
                                              </span></div>)}
                                        </div>
                                        <hr />
                                        <div className={ListRulesCss.cardMiddleContentBlock}>
                                          <span class={ListRulesCss.cardMiddleContentBlockTitle}>Category: </span>
                                          <span className={ListRulesCss.cardMiddleContentBlockValue} >
                                            {customCategory && customCategory.map((o, i) => {
                                              if (o.ID == obj.CATEGORY_ID) {
                                                obj.categoryName = o.NAME;
                                              }
                                            })}
                                            {obj.categoryName || "-"}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={ListRulesCss.cardFooterContent}>
                                        {obj.COMPANY_ID !== -1 && appData.Permissions &&
                                          getPermissionStatus(
                                            appData.Permissions,
                                            appConstants.DELETE_RULE
                                          ) && (
                                            <div>
                                              {userDetails.role === 'super admin' &&
                                                <Tooltip title="Edit" placement="bottom">
                                                  <CreateIcon title="Edit" onClick={() => editRule(obj)} />
                                                </Tooltip>
                                              }
                                              <Tooltip title="Delete" placement="bottom">
                                                <DeleteIcon title="Delete" style={{ marginRight: "10px" }} onClick={() => onDeleteRule(obj.ID)} />
                                              </Tooltip>

                                              {appData.Permissions &&
                                                getPermissionStatus(
                                                  appData.Permissions,
                                                  appConstants.DEACTIVE_RULE
                                                ) && <span
                                                  onClick={() => showDialog(obj, `Do you want to ${!obj.DEACTIVE ? 'Deactivate' : 'activate'} rule '${obj.NAME}' ?`, `${!obj.DEACTIVE ? 'Deactivate' : 'Activate'}`, () => onActiveRule)}
                                                >
                                                  {obj.DEACTIVE ?
                                                    <Tooltip title="Activate" placement="bottom">
                                                      <DeactiveSVG title="Activate" style={{ cursor: "pointer" }} />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Deactivate" placement="bottom">
                                                      <ActiveSVG title="Deactivate" style={{ marginRight: "10px", cursor: "pointer" }} />
                                                    </Tooltip>

                                                  }
                                                </span>
                                              }
                                            </div>
                                          )}
                                        <div className={ListRulesCss.cardFooterContentIcon}>
                                          <Tooltip title="View" placement="bottom">
                                            <VisibilityIcon title="View" onClick={() => onViewMore(obj)} />
                                          </Tooltip>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  </Paper>
                                </Col>
                              ))}
                          </Row>
                        </>
                      ) : rulesDetails.addRule === false ? (
                        <div
                          className={ListRulesCss.rule_list_noData_body}
                          onClick={() => toggleAddRule()}
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            <img
                              className={ListRulesCss.rule_list_noData_body_image}
                              src={RuleListPng}
                              alt="BinaryWayLogo"
                            />
                          </div>
                          <div
                            className={ListRulesCss.rule_list_noData_body_content}
                            onClick={() => toggleAddRule()}
                            style={{ cursor: "pointer" }}
                          >
                            Click anywhere to Get Started
                        </div>
                        </div>
                      ) : null}
                  </>
                )}
            </div>
          </Col>
        </Row>
      </Container>
      <Dialog onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        {/* <span style={{ textAlign: 'right' }}>
          <BinarywayButton onClick={handleDialogClose} color="default" label="Close" autoFocus>
          </BinarywayButton>
          <BinarywayButton onClick={() => dialogNext()} color="primary" label={dialogButtonText} autoFocus>
          </BinarywayButton>
        </span> */}
      </Dialog>
      <Filter
        isOpen={isOpenFilter}
        setOpenFilter={setOpenFilter}
        configData={configData}
        checkedFilter={checkedFilter}
        setCheckedFilter={setCheckedFilter}
        ruleTypes={ruleTypes}
        setRuleTypes={setRuleTypes}
        customCategory={customCategory}
        setCustomCategory={setCustomCategory}
        onApplyFilter={onApplyFilter}
        categoryTypes={categoryTypes}
        setCategoryTypes={setCategoryTypes}
        toggleDrawer={toggleDrawer}
        onReset={onReset}
      />
      <RuleDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
        onDeleteRule={onDeleteRule}
      />
    </>
  );
};

export default ListRules;
