import React, { useContext, useState, useEffect } from "react";

import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { RuleBuilderContext } from "../../../../service/context";
import RulesBasicDetails from "./RulesBasicDetails/RulesBasicDetails";
import RulesCreate from "./RulesCreate/RulesCreate";
import RulesProperties from "./RulesProperties/RulesProperties";
import RuleBuilderCss from "./RuleBuilder.module.css";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const RuleBuilder = (props) => {
  const classes = useStyles();
  const [ruleBuilderData, setRuleBuilderData] = useState({
    companyId: null,
    ruleName: null,
    detail: null,
    targetVersion: null,
    ruleType: null,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const path =
      getSteps() && getSteps().length > 0 && getSteps()[activeStep + 1]["path"];

    if (history && history.location && history.location.state && history.location.state.isEdit) {

      let editRule = true;
      let ruleData = history.location.state.editData;
      props.history.push({
        state: { 
          editData: ruleData,
          isEdit: true
        },
        pathname: path,
      })
    } else {
      props.history.push(path);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const path =
      getSteps() && getSteps().length > 0 && getSteps()[activeStep - 1]["path"];
    props.history.push(path);
  };

  const handleCancel = () => {
    props.history.push("/Dashboard/Rules");
  };

  const getSteps = () => {
    return [
      {
        displayName: "Rule Basic Details",
        path: "/Dashboard/RuleBuilder/RulesBasicDetails",
      },
      {
        displayName: "Rule Properties",
        path: "/Dashboard/RuleBuilder/RulesProperties",
      },
      {
        displayName: "Create Rule",
        path: "/Dashboard/RuleBuilder/RulesCreate",
      },
    ];
  };

  return (
    <RuleBuilderContext.Provider
      value={{
        ruleBuilderData: ruleBuilderData,
        setRuleBuilderData: setRuleBuilderData,
      }}
    >
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {getSteps().map((data) => (
            <Step key={data.displayName}>
              <StepLabel>{data.displayName}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={RuleBuilderCss.ruleBuilder_body}>
        <Switch>
          <Route exact path="/Dashboard/RuleBuilder/RulesBasicDetails">
            <RulesBasicDetails
              onhandleBack={handleBack}
              onhandleNext={handleNext}
              onhandleCancel={handleCancel}
            />
          </Route>
          <Route exact path="/Dashboard/RuleBuilder/RulesProperties">
            <RulesProperties
              onhandleBack={handleBack}
              onhandleNext={handleNext}
              onhandleCancel={handleCancel}
            />
          </Route>
          <Route exact path="/Dashboard/RuleBuilder/RulesCreate">
            <RulesCreate
              onhandleBack={handleBack}
              onhandleNext={handleNext}
              onhandleCancel={handleCancel}
            />
          </Route>
        </Switch>
      </div>
    </RuleBuilderContext.Provider>
  );
};
export default RuleBuilder;
