import React, { useContext, useState, useEffect, useRef } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, Container } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MUIRichTextEditor from 'mui-rte';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import ProcessAnalyzerDetailDrawerCss from "./ProcessAnalyzerDetailDrawer.module.css";
import CustomDialog from "../../../UI-Components/Modal/CustomDialog";
import RuleDetailsDrawer from "../../Rules/ListRules/RuleDetailsDrawer/RuleDetailsDrawer";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  customChoosefileBtn: {
    backgroundColor: "#28a745",
  },
  drawer: {
    maxHeight: "50%",
  },
  resultHeading: {
    marginTop: "4%",
    marginRight: "10px",
  },
  rootResult: {
    float: "right",
  },
  cardTitleGreen: {
    background: '#079943',
    color: 'white',
    padding: '16px',
    
    '& .MuiTypography-h5': {
      fontSize: '18px'
    }
  },
  cardTitleRed: {
    background: '#dc3f30',
    color: 'white',
    padding: '16px',
    '& .MuiTypography-h5': {
      fontSize: '18px'
    }
  },
  cardContent: {
    minHeight: '70px',
    color: 'black',
    padding: '10px 16px',
  },
  cardAction: {
    backgroundColor: '#e8edf3',
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  firstRow: {
    display: "block",
    paddingBottom: "5px;",
    borderBottom: "1px solid #dcdddc;"
  },
  viewRule: {
    fontSize: "14px",
    color: "#43b0f1",
    cursor: "pointer"
  },
  customTooltip: {
    backgroundColor: '#c5c2c2',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between'
  },
  customArrow: {
    color: '#c5c2c2',
  },
}));

const ProcessAnalyzerDetailDrawer = ({
  isOpen,
  setOpen,
  currentProcessAnalyzerRuleData,
  currentRuleForProcessResult,
  selectedGroupByFilter,
}) => {
  const classes = useStyles();
  const { appData, setAppData } = useContext(AppContext);
  const [filteredBy, setFilteredBy] = useState("all");
  const [virTualProcessAnalyzerRuleData, setVirTualProcessAnalyzerRuleData] = useState({});
  const [endReached, setEndReached] = useState(false);
  const [isCardView, setCardView] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [remedyForModal, setRemedyForModal] = useState("");
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);

  useEffect(() => {
    if (isOpen === true) {
      setDrawerData();
    }
  }, [isOpen, filteredBy])

  const handleDialogOpen = (remedy) => {
    setRemedyForModal(remedy);
    setOpenDialog(true);
  };

  const handleDialogClose = (value) => {
    setRemedyForModal(null);
    setOpenDialog(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFilteredBy("all");
    setOpen(open);
  };

  const onViewMore = (obj) => {
    setCurrentRule(obj);
    setOpenViewMore(true);
  };

  const setDrawerData = () => {
    if (currentProcessAnalyzerRuleData && currentProcessAnalyzerRuleData.title) {
      setEndReached(false);
      let VirTualData = JSON.parse(JSON.stringify(currentProcessAnalyzerRuleData));
      VirTualData.outputMessage = VirTualData.outputMessage.filter(x =>
        filteredBy === "all" ? x : filteredBy === "pass" ? x.pass : !x.pass);
      //VirTualData.outputMessage = VirTualData.outputMessage.slice(0, VirTualData.outputMessage.length >= 10 ? 10 : VirTualData.outputMessage.length);
      setVirTualProcessAnalyzerRuleData(VirTualData);
    }
  }

  const handleResultChange = (event) => {
    setFilteredBy(event.target.value);
  };

  const loadMore = (event) => {
    if (!endReached) {
      let VirTualData = JSON.parse(JSON.stringify(virTualProcessAnalyzerRuleData));
      const virtualOp = currentProcessAnalyzerRuleData.outputMessage.filter(x =>
        filteredBy === "all" ? x : filteredBy === "pass" ? x.pass : !x.pass).slice(0, (currentProcessAnalyzerRuleData.outputMessage.length - virTualProcessAnalyzerRuleData.outputMessage.length + 5) > 5 ? virTualProcessAnalyzerRuleData.outputMessage.length + 5 : currentProcessAnalyzerRuleData.outputMessage.length);
      VirTualData.outputMessage = virtualOp;

      setVirTualProcessAnalyzerRuleData(VirTualData);

      if (VirTualData.outputMessage.length === currentProcessAnalyzerRuleData.outputMessage.length) {
        setEndReached(true)
      }
    }
  }

  const ruleWiseDetails = (anchor) => (
    <div>
      {virTualProcessAnalyzerRuleData ? (
        <>
          <Container fluid={true} style={{ padding: "0px" }}>
            <div>
              <Col xs="12" style={{backgroundColor: '#0f183f3b'}}>
                <Row className="processAnalyzerDrawer_header">
                  <Col xs="8">
                    <div className="processAnalyzer_header_title_drawer">
                      {<>
                        <span onClick={toggleDrawer(false)} style={{ marginRight: "10px" }}><i className="fa  fa-arrow-left"></i></span>
                        <span style={{ fontWeight: '500' }}>{virTualProcessAnalyzerRuleData.title}</span>
                        <span style={{ color: "#919294" }}> {" "} (Total Results - {virTualProcessAnalyzerRuleData.outputMessage &&
                          virTualProcessAnalyzerRuleData.outputMessage.length})</span> {" "}
                        {selectedGroupByFilter &&
                          selectedGroupByFilter.name === "Rule Wise" &&
                          <span className={classes.viewRule}>
                            <a onClick={() => onViewMore({
                              NAME: currentRuleForProcessResult.ruleName,
                              DESCRIPTION: currentRuleForProcessResult.ruleDescription,
                              RULE_TYPE: currentRuleForProcessResult.ruleType, COMPONENT_NAME: '-',
                              stageData: [],
                              REMEDY: currentRuleForProcessResult.remedy
                            })}>View Rule</a>
                          </span>
                        }
                      </>}
                    </div>
                    <div className="processAnalyzer_header_title_subheader_drawer" style={{ marginLeft: "30px" }}>
                      {<span>{virTualProcessAnalyzerRuleData.description}</span>}
                    </div>
                    {/* {selectedGroupByFilter &&
                      selectedGroupByFilter.name === "Rule Wise" &&
                      <div className="processAnalyzer_header_title_subheader_drawer">
                        <span variant="outlined" color="primary" onClick={e => handleDialogOpen(virTualProcessAnalyzerRuleData ? virTualProcessAnalyzerRuleData.remedy : "")}>
                          Remedy: <HelpIcon />
                        </span>
                        <CustomDialog openDialog={openDialog} data={remedyForModal} onClose={handleDialogClose} />
                      </div>
                    } */}
                  </Col>

                  <Col xs="4">
                    <div className="" style={{ marginTop: "5px" }}>
                      {
                        <>
                          <FormControl className={classes.rootResult}>
                            <RadioGroup
                              row
                              aria-label="result"
                              name="result"
                              onChange={handleResultChange}
                              defaultValue="all"
                            >
                              <FormLabel className={classes.resultHeading}>
                                Results
                              </FormLabel>
                              <FormControlLabel
                                value="all"
                                control={<Radio color="primary" />}
                                label="All"
                              />
                              <FormControlLabel
                                value="pass"
                                control={<Radio color="primary" />}
                                label="Passed"
                              />
                              <FormControlLabel
                                value="fail"
                                control={<Radio color="primary" />}
                                label="Failed"
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
                <Row style={{ overflowY: "scroll", height: "80vh" }} onScroll={(event) => loadMore(event)}>
                  <Col xs="12">
                    {virTualProcessAnalyzerRuleData && (
                      <div
                        className={
                          ProcessAnalyzerDetailDrawerCss.procees_list_paper_right_main
                        }
                      >
                        <div
                          className={
                            ProcessAnalyzerDetailDrawerCss.procees_list_paper_right_display
                          }
                        >
                          <div
                            className={
                              ProcessAnalyzerDetailDrawerCss.procees_list_paper_right_meta_main
                            }
                          >
                            <div
                              className={
                                ProcessAnalyzerDetailDrawerCss.procees_list_paper_right_message
                              }
                            >
                              {virTualProcessAnalyzerRuleData &&
                                virTualProcessAnalyzerRuleData.outputMessage &&
                                virTualProcessAnalyzerRuleData.outputMessage
                                  .map((msg, index) => (
                                    <>
                                      <Col xs={4} style={{ padding: '8px' }} key={index}>
                                        <Card className={classes.selectedCard}  >
                                          <Tooltip title={msg.message} placement="bottom"
                                          classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                          }}
                                          arrow>
                                            <CardHeader
                                              className={
                                                msg.pass === true
                                                  ? classes.cardTitleGreen
                                                  : classes.cardTitleRed
                                              }
                                              title={msg.message.length > 40 ? msg.message.substring(0, 40) + '...' : msg.message}
                                            />
                                          </Tooltip>
                                          <CardContent className={classes.cardContent}>
                                            {/* <span
                                              style={{ display: "block", overflowWrap: "break-word" }}
                                            >{
                                                msg.message ? <b>Message :</b> : ""
                                              } {`${msg.message}`}</span> */}
                                            {selectedGroupByFilter &&
                                              selectedGroupByFilter.name !==
                                              "Process Wise" && (
                                                <div className={classes.firstRow}>
                                                  <span
                                                    style={{ color: "#919294" }}
                                                  >
                                                    {
                                                      msg.root ?  msg.root.split('-')[0]: ""
                                                    }
                                                  </span>
                                                  <span> {`${msg.root.replace(/^(Process -|Object -)+/, '')}`}{" "}
                                                  </span>
                                                </div>
                                              )}
                                            <div className={
                                              selectedGroupByFilter &&
                                                selectedGroupByFilter.name !==
                                                "Rule Wise" ? classes.firstRow : "pt-1"
                                            }  >
                                              <span
                                                style={{ color: "#919294" }
                                                }
                                              > {
                                                  msg.page
                                                    ? <>Page : </>
                                                    : <><>Page :</>{" Main Page"}</>
                                                }</span>
                                              <span>{`${msg.page}`}</span>
                                            </div>
                                            {selectedGroupByFilter &&
                                              selectedGroupByFilter.name !==
                                              "Rule Wise" && (
                                                <div className="pt-1">
                                                  <span
                                                    style={{ color: "#919294" }}
                                                  >{
                                                      msg.ruleType
                                                        ? <> Rule : </>
                                                        : <>{"Rule : "}</>
                                                    }</span>
                                                <Tooltip title={msg.ruleName} placement="bottom"
                                                classes={{
                                                  tooltip: classes.customTooltip,
                                                  arrow: classes.customArrow
                                                }}
                                                arrow>
                                                  <span>{msg.ruleName.length > 25 ? msg.ruleName.substring(0, 25) + '...' : msg.ruleName}</span>
                                                </Tooltip>
                                                </div>
                                              )}
                                            {selectedGroupByFilter &&
                                              selectedGroupByFilter.name !==
                                              "Rule Wise" && <div className="pull-right" style={{ margin: "5px" }}>
                                                <Tooltip title="View Result" placement="bottom">
                                                  <VisibilityIcon title="View" onClick={() => onViewMore({
                                                    NAME: msg.ruleName,
                                                    DESCRIPTION: msg.ruleDescription ? msg.ruleDescription  : "No Description",
                                                    RULE_TYPE: msg.ruleType, COMPONENT_NAME: '-', stageData: [], REMEDY: msg.remedy
                                                  })} />
                                                </Tooltip>
                                              </div>
                                            }
                                            {/* {selectedGroupByFilter &&
                                              selectedGroupByFilter.name !==
                                              "Category Wise" && (
                                                <span
                                                  style={{ display: "block" }}
                                                >{
                                                    msg.ruleCategory
                                                      ? <b>{"Category :"}</b>
                                                      : ""
                                                  } {`${msg.ruleCategory}`}</span>
                                              )} */}
                                            {/* {selectedGroupByFilter &&
                                              selectedGroupByFilter.name !==
                                              "Rule Wise" && (
                                                <>
                                                  <span variant="outlined" color="primary" onClick={e => handleDialogOpen(msg ? msg.remedy : "")}>
                                                    Remedy : <HelpIcon />
                                                  </span>
                                                  <CustomDialog openDialog={openDialog} data={remedyForModal} onClose={handleDialogClose} />
                                                </>
                                              )} */}
                                          </CardContent>
                                          {/* {selectedGroupByFilter &&
                                            selectedGroupByFilter.name !==
                                            "Rule Wise" && <CardActions className={classes.cardAction}>
                                              <Tooltip title="View Result" placement="bottom">
                                                <VisibilityIcon title="View" onClick={() => onViewMore({
                                                  NAME: msg.ruleName,
                                                  DESCRIPTION: msg.ruleDescription,
                                                  RULE_TYPE: msg.ruleType, COMPONENT_NAME: '-', stageData: [], REMEDY: msg.remedy
                                                })} />
                                              </Tooltip>
                                            </CardActions>} */}
                                        </Card>
                                      </Col>
                                    </>
                                  ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </div>
          </Container>
        </>
      ) : null
      }
    </div >
  );

  return (
    <>
      <SwipeableDrawer
        id={"processAnalyzerCreate_drawer"}
        className={classes.drawer}
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {/* {pageWiseDetails("bottom")} */}
        {ruleWiseDetails("right")}
      </SwipeableDrawer>

      <RuleDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
      />
    </>
  );
};

export default ProcessAnalyzerDetailDrawer;
