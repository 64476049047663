import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";

import ListTemplateCss from "./ListTemplate.module.css";
import AddTemplate from "../AddTemplate/AddTemplate";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayButton from "../../../UI-Components/Button/Button";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getUserDetailsFromJwtToken,
  getTemplateDetailsByCompanyId,
  postDeleteTemplateDetails,
  getCompanyListDetails,
  getPermissionStatus,
} from "../../../../service/service";
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import { fade, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { indexOf, set } from "lodash";
import "../Template.css";
import TemplateDetailsDrawer from "./TemplateDetailsDrawer/TemplateDetailsDrawer";
const useStyles = makeStyles((theme) => ({
  search: {
    margin: "5px",
    position: "relative",
    borderRadius: "5px",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    float: "right",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  filterColor: {
    color: "red"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    display: "block",

    padding: "0.8rem 0.5rem",
    width: "100%",

    border: "1px solid black",
    borderRadius: "12px"

    // ?boxShadow: "0px 2px 3px 1px #bab8af",

  },

  card: {
    width: '100%',
    marginBottom: "1rem",
    fontSize: '14px !important'
  },
  bodyCard: {
    fontSize: '14px !important'

  },
  titleCard: {
    '& span': {
      // minHeight: '46px',
      fontSize: '18px !important'

    }
  },
  selectedCard: {
    width: '100%',
    border: "1px solid #4d8fd1",
    marginBottom: "1rem"

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  popList: {
    boxShadow: "0px !important",
    padding: "0.6rem",
    boxShadow: "0px 2px 1px 1px grey",
    display: "flex"
  },
  iconStyle: {
    margin: "0px 0.2rem"
  }

}));

const ListTemplate = (props) => {
  let curruntTemplate = {};
  const classes = useStyles();
  const [isAddTemplate, setIsAddTemplate] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [TemplateList, SetTemplateList] = useState([]);
  const [ListCompany, SetListCompany] = useState([]);
  const [selectedCompany, SetSelectedCompany] = useState({
    id: 0,
    name: "",
  });
  const [currentSelectedTemplate, setcurrentSelectedTemplate] = useState({});
  const [currentTemplateOpted, setCurrentTemplateOpted] = useState({});
  const [filterdTemplates, setfilterdTemplates] = useState([]);
  const [ShowAlert, SetAlert] = useState({ isShowAlert: false, msg: "" });
  const [ToggleForm, SetToggleForm] = useState({
    isToggleForm: false,
    data: null,
  });
  const [toggleReset, setToggleReset] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(getUserDetailsFromJwtToken());
  const { appData, setAppData } = useContext(AppContext);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [dialogMessage, setdialogMessage] = useState('');
  const [dialogButtonText, setdialogButtonText] = useState('');
  const [dialogNext, setdialogNext] = useState(null);
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  useEffect(() => {
    onLoadSetData();
  }, []); 

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsAddTemplate(open);
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    if (TemplateList) {
      const searchTemplates = TemplateList.filter(Template => {
        const regExp = new RegExp(`${e.target.value}`, "gi");
        return (Template.name).match(regExp)
      })
      setfilterdTemplates(searchTemplates);
    }
  };

  const onLoadSetData = () => {
    if (props && props.isSuperAdmin && props.isSuperAdmin === true) {
      if (ListCompany && ListCompany.length === 0) {
        getCompanyListDetails().then((result) => {
          if (result.success === true) {
            let _data = result.data;
            if (_data && _data.length > 0) {
              _data = _data.map((y) => {
                const _x = { value: y, displayName: y.name };
                return _x;
              });
              _data.unshift({
                value: { id: 0, name: "All" },
                displayName: "All",
              });
              SetListCompany(_data);
              SetSelectedCompany(_data[0]["value"]);
              onGetTemplates(_data[0]["value"]["id"]);
            } else {
              SetListCompany([]);
            }
          } else {
            SetListCompany([]);
          }
        });
      } else {
        onGetTemplates(selectedCompany.id);
      }
    } else {
      const _userData = getUserDetailsFromJwtToken();
      if (_userData) {
        onGetTemplates(_userData.compid);
        SetSelectedCompany({ comp_id: _userData.compid, name: "" });
      }
    }

  const _userData = getUserDetailsFromJwtToken();
    if (_userData) {
      setLoggedInUser(_userData);
    }
  };

  const onGetTemplates = (companyid) => {
    const body = {
      params: {
        companyid: companyid,
      },
    };
    getTemplateDetailsByCompanyId(body).then((result) => {
      if (result.success === true) {
        SetTemplateList(result.data);
        setfilterdTemplates(result.data);
      } else {
        SetTemplateList([]);
      }
    });
  };

  const onAddTemplateButton = () => {
    SetToggleForm({ isToggleForm: false, data: null });
    setCurrentTemplateOpted({});
    setcurrentSelectedTemplate({});
    setIsAddTemplate(!isAddTemplate);
  };

  const onTemplateAdd = (data) => {
    SetToggleForm({ isToggleForm: false, data: null });
    const _msg =
      data === "successUpdate"
        ? "Template details updated successfully"
        : "Template added successfully";
    onAlert(true, _msg);
    onLoadSetData();
  };

  const onCompanyDropDownChange = (event) => {
    if (event && event.target.value) {
      SetSelectedCompany(event.target.value);
      onGetTemplates(event.target.value.id);
    }
  };

  const onAlert = (bol, msg) => {
    appData.ShowAlert = {
      bool: true,
      msg: msg,
      type: "success",
    };
    setAppData({ ...appData, ShowAlert: appData.ShowAlert });
  };
  const onTemplateClick = (selectedTemplate) => {
    setcurrentSelectedTemplate(selectedTemplate);
    setOpenViewMore(true);
  }

  function showDialog(template, message, buttonText, next) {
    curruntTemplate = template
    setdialogMessage(message)
    setdialogButtonText(buttonText)
    setdialogOpen(true)
    setdialogNext(next)
  }

  const handleDialogClose = () => {
    setdialogOpen(false)
  }

  const onDeleteTemplate = () => {
    let template = curruntTemplate
    const body = {
      templateid: template.id,
    };
    postDeleteTemplateDetails(body).then((result) => {
      if (result && result.success === true) {
        onAlert(true, `Template ${template.name} deleted successfully`);
        onLoadSetData();
      } else {
        onAlert(false, `Something went wrong.`);
      }
    });
    handleDialogClose();
  };

  return (
    <>
      <Container fluid={true}>
        <Row className="Templates_header">
          <Col xs="3" >
            <div className="Templates_header_title">
              <span> List of Templates</span>{" "}
            </div>
          </Col>
          <Col xs="5">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Col>
          <Col xs="2">
            <div style={{ padding: "10px" }}>
              {loggedInUser.roleid === 1 && (
                <BinarywayDropdown
                  placeHolder=""
                  label="Company"
                  value={selectedCompany}
                  options={ListCompany}
                  onChange={(event) => onCompanyDropDownChange(event)}
                />
              )}
            </div>
          </Col>
          <Col xs="2">
            <div className="Templates_stepper_button">
              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_TEMPLATE
                ) && (
                  <BinarywayButton
                    onClick={onAddTemplateButton}
                    label="Add Template"
                  ></BinarywayButton>
                )}
            </div>
          </Col>
        </Row>

        <Row className="Templates_body_background">
          {appData.Permissions &&
            getPermissionStatus(appData.Permissions, appConstants.LIST_TEMPLATE) &&
            TemplateList &&
            TemplateList.length > 0 ? (
              <>
                <Col xs={12} style={{ overflow: "auto", height: "calc(100vh - 124px)" }} className={ListTemplateCss.listTemplate_body_main}>
                  <div
                    className={ListTemplateCss.listTemplate_body_tile} >
                    <Row className={ListTemplateCss.listTemplate_body_tile_row}>
                      {filterdTemplates &&
                        filterdTemplates.map((x, index) => {
                          return (
                            // x.role_id >= loggedInUser.roleid && 
                            <Col xs={3} style={{ padding: "5px" }} key={index}>
                              <Card className={x.id === currentSelectedTemplate.id ? classes.selectedCard : classes.card}  >
                                <CardHeader
                                  className={classes.titleCard}
                                  title={`${x.name}`}
                                />
                                <hr />
                                <CardContent>
                                  <Typography variant="h6" component="h3" className={classes.bodyCard}>
                                    Company: <span style={{ fontWeight: 500 }}>{x.company}</span>
                                  </Typography>
                                  <Typography variant="h6" component="h3" className={classes.bodyCard} >
                                    Description: <span style={{ fontWeight: 500 }}>{x.description != "null" ? x.description : "-"}</span>
                                  </Typography>
                                  <Typography variant="h6" component="h3" className={classes.bodyCard} >
                                    File Name: <span style={{ fontWeight: 500 }}>{x.filename}</span>
                                  </Typography>
                                </CardContent>
                                <hr />
                                <div className={ListTemplateCss.rule_link}>
                                  <VisibilityIcon
                                    onClick={() => onTemplateClick(x)}
                                  />
                                  <DeleteIcon
                                    onClick={() => showDialog(x, `Do you want to delete template '${x.name}' ?`, 'Delete', () => onDeleteTemplate)}
                                  />
                                </div>
                              </Card>
                            </Col>
                          )
                        })}


                    </Row>
                  </div>
                </Col>
              </>
            ) : (
              <Row className="py-4" style={{ width: "100%" }}>
                <Col md="12" style={{ textAlign: "center" }}>
                  Seems Like you have not created any templates yet. You can quicky
                  add it
                    </Col>
              </Row>
            )}
        </Row>
      </Container>
      <TemplateDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentSelectedTemplate={currentSelectedTemplate}
        setcurrentSelectedTemplate={setcurrentSelectedTemplate}
      />

      <AddTemplate
        isAddTemplate={isAddTemplate}
        setIsAddTemplate={setIsAddTemplate}
        toggleDrawer={toggleDrawer}
        isSuperAdmin={props.isSuperAdmin}
        onChange={onTemplateAdd}
        dataFromList={ToggleForm.data}
      />
      {ToggleForm && ToggleForm.isToggleForm && (
        <Modal
          isOpen={ToggleForm.isToggleForm}
          toggle={() => SetToggleForm({ isToggleForm: false, data: null })}
          className=""
          size="md"
        >
          <ModalHeader
            toggle={() => SetToggleForm({ isToggleForm: false, data: null })}
          >
            Add/Update Template
          </ModalHeader>
          <ModalBody>
            {/* <AddTemplate
              companyId={
                props.isSuperAdmin === true ? null : selectedCompany.id
              }
              isSuperAdmin={props.isSuperAdmin}
              onChange={onTemplateAdd}
              dataFromList={ToggleForm.data}
            ></AddTemplate> */}
          </ModalBody>
        </Modal>
      )}

      <Dialog onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <span style={{ textAlign: 'right', paddingBottom:"5px", paddingRight:"7px" }}>
          <BinarywayButton onClick={handleDialogClose} color="default" label="Close" autoFocus>
          </BinarywayButton>
          <BinarywayButton onClick={() => dialogNext()} color="primary" label={dialogButtonText} autoFocus>
          </BinarywayButton>
        </span>
      </Dialog>
    </>
  );
};

export default ListTemplate;
