import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Dashboard from "./UI/Dashboard/Dashboard";
import Login from "./UI/Login/Login";
import NewLogin from "./UI/Login/NewLogin";

import { getLoaderStatus, binarySubscribe, logoutAllTabs } from "./service/service";
import { AppContext } from "./service/context";
import Chart from "./Chart";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));

const App = (props) => {
  const classes = useStyles();
  const [appData, setAppData] = useState({
    Permissions: null,
    ShowAlert: { bool: false, msg: "", type: "success" },
  });

  const [loaderStatus, setLoaderStatus] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: { dark: "#0f183f", light: "#0f183f", main: "#0f183f" },
      secondary: { dark: "#0f183f", light: "#0f183f", main: "#0f183f" },
    },
  });

  useEffect(() => {
    binarySubscribe((x) => {
      setLoaderStatus(x);
      logoutAllTabs();
    });
  }, []);

  const handleShowAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    appData.ShowAlert = { bool: false, msg: "", type: "success" };
    setAppData({ ...appData });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppContext.Provider value={{ appData: appData, setAppData: setAppData }}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect exact to="/Login" />
            </Route>
            <Route exact path="/Login" component={NewLogin} />
            {/* <Route exact path="/newLogin" component={NewLogin} /> */}
            <Route exact path="/chart" component={Chart} />
            <Route path="/Dashboard" component={Dashboard} />
          </Switch>
        </Router>

        {loaderStatus === true && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          open={appData.ShowAlert.bool}
          autoHideDuration={6000}
          onClose={handleShowAlertClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleShowAlertClose}
            severity={appData.ShowAlert.type}
          >
            {appData.ShowAlert.msg}
          </MuiAlert>
        </Snackbar>
      </AppContext.Provider>
    </MuiThemeProvider>
  );
};

export default App;
