import React, { createRef, useCallback, useState } from "react";
import {
  ClickAwayListener,
  Fade,
  List,
  Paper,
  TextField,
} from "@material-ui/core";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";

const suggestionsState = atom({
  key: "Band Suggestions Visible?",
  default: false,
});

const inputState = atom({
  key: "Suggestable Input Bands",
  default: "",
});

const Suggestions = (field) => {
  const [hasSuggestions, setHasSuggestions] = useRecoilState(suggestionsState);
  const [value, setValue] = useRecoilState(inputState);
  const handleClick = useCallback(
    (band) => {
      setValue(value + band);
      setHasSuggestions(false);
    },
    [value, setValue, setHasSuggestions, field]
  );
  const handleClickAway = useCallback(() => {
    setHasSuggestions(false);
  }, [field, setHasSuggestions]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Fade in={hasSuggestions}>
        <List component={Paper} sx={{ mt: 1 }}>
          <ListItem onClick={() => handleClick("process_object_name")}>
            process_object_name
          </ListItem>
        </List>
      </Fade>
    </ClickAwayListener>
  );
};

export default function SuggestionsField(props) {
  const [property, setProperty] = useState({
    id: props.id || "input_" + Math.random(),
    label: props.label || "",
    placeholder: props.placeholder || "",
    variant: props.variant || "outlined",
    size: props.size || "small",
    value: props.value || "",
    maxLength: props.maxLength || 50,
    multiline: props.multiline || false,
    ...props,
  });
  const textFieldRef = createRef();
  const setHasSuggestions = useSetRecoilState(suggestionsState);
  const [value, setValue] = useRecoilState(inputState);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.match(/[[]$/)) {
      setHasSuggestions(true);
    } else {
      setHasSuggestions(false);
    }
    props.onChange(event);
  };

  return (
    <FormControl fullWidth sx={{ m: 1 }}>
      <TextField
        size="small"
        inputRef={textFieldRef}
        variant={property.variant}
        label={props.label}
        value={value}
        onChange={handleChange}
        color="#63BDF3"
        backgroundColor="#63BDF3"
        inputProps={{ style: { fontFamily: "Roboto", color: "black" } }}
      />
      <Suggestions field={textFieldRef} />
    </FormControl>
  );
}
