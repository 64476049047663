import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ExecutionAuditDisplayTable from './Atoms/execution-audit-table';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import {
  getCompanyListDetails,
  getExecutionAuditListDetails,
  getUserDetailsByCompanyId,
  getUserDetailsFromJwtToken,
} from '../../../service/service';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { round } from 'lodash';

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textFieldDate: {
      
        width: '80%',
        fontSize:'10px',
        borderColor: blue[500],
        outlineColor: blue[500],
        '& label':{
            color:'#43b0f1',
            fontSize:'13px'
        },
        '& input':{
            fontSize:'14px',
            margin:0,
            padding:4
            
        },
        '& .MuiInput-underline:before': {
            
            borderColor:'#43b0f1'
        },      
    },
    selectInputField:{
        width:'80%',
        '& label':{
            color:'#43b0f1',
        },
        borderColor:'red',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#43b0f1',
            },
            '&:hover fieldset': {
              borderColor: blue[700],
            },
            '&.Mui-focused fieldset': {
              borderColor: blue[900],
            },
        },
    },

    heading:{
        fontFamily: 'sans-serif',
        fontSize: '23px',
        fontWeight:"normal",
        fontStretch: "normal",
        lineHeight:"normal",
        paddingTop:'10px'

    },
    applyButton:{
        backgroundColor: '#0051b5',
        color: '#ffffff',
        borderRadius:'4px',
        '&:hover':{
          backgroundColor: '#0051b5',
          color:'white'
        }
    },
    tableContainer:{
      // position:'fixed',
      // minHeight:'100%',
      // minWidth:'100%'
      overflowY: 'scroll',
      maxHeight: '100vh',
      height: '70vh'
    }
    
  }));



function createData(dateString, user, releaseFile, analyzer, rules, success, failed, duration, execution_id) {
 
    const dateTime = moment(dateString).format("MM/DD");
    duration=(duration/(60*1000)).toFixed(2);
    
  return {dateTime , user, releaseFile, analyzer, rules, success, failed, duration, execution_id };
}

const ListExecutionAudit = props => {
    const classes = useStyles();
    const [executionAuditList, setExecutionAuditList]=  useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [fromDate, setFromDate]= useState(moment(new Date()).subtract(3,'month'));
    const [toDate, setToDate]= useState(new Date());
    const [companyList, setCompanyList] = useState([]);
    const [userList, setUserList]= useState([]);
    const [completeUserList, setCompleteUserList]= useState([]);

    useEffect(()=>{
        onLoadSetData()
    },[])

  useEffect(() => {
    getUsers(selectedCompany);
  }, [selectedCompany]);

    const onLoadSetData = () => {
        if (props && props.isSuperAdmin) {
          if (companyList && companyList.length === 0) {
            getCompanyListDetails().then((result) => {
              if (result.success === true) {
                let _data = result.data;
                if (_data && _data.length > 0) {
                  _data = _data.map((y) => {
                    const _x = { value: y, displayName: y.name };
                    return _x;
                  });
                  setCompanyList(_data);
                  setSelectedCompany(_data[0].value.id);
                  getUsers(_data[0]["value"]["id"]);
                } else {
                  setCompanyList([]);
                }
              } else {
                setCompanyList([]);
              }
            });
          } else {
            getUsers(selectedCompany.id);
          }
        } else {
          const _userData = getUserDetailsFromJwtToken();
          if (_userData) {
            getUsers(_userData.compid);
            setSelectedCompany( _userData.compid);
          }
        }
    }

    const getUsers = (companyid) => {
        const body = {
          params: {
            companyid: companyid,
          },
        };
        getUserDetailsByCompanyId(body).then((result) => {
          if (result.success === true) {
            let _data = result.data;
            if (_data && _data.length > 0) {
                _data = _data.map((y) => {
                  
                  return {id:y.id, firstname:y.firstname, lastname:y.lastname, company_id:y.company_id};
                });
                _data.unshift({
                   id: 0, firstname: "All",lastname:"",company_id:"All" 
                });

            setUserList(_data);
            setCompleteUserList(_data);
            setSelectedUser(0);
            }
            else{
              _data.unshift({
                id: 0, firstname: "All",lastname:"",company_id:"All" 
              });

              setUserList(_data);
              setCompleteUserList(_data);
              setSelectedUser(0);              
            }
          } else {
            setUserList([]);
            setCompleteUserList([]);
          }
        });
      };

  const getExecutionAuditData = (req) => {
    getExecutionAuditListDetails(req).then((res) => {
      if (res.success) {
        let resultList = res.data.map((x) =>
          createData(
            x.execution_Time,
            x.username,
            x.filename,
            x.analyzer_name,
            x.builtin_rules + x.custom_rules,
            x.success_count,
            x.failure_count,
            x.analyer_Duration_Ms,
            x.analyzer_execution_id
          )
        );
        setExecutionAuditList(resultList);
      } else {
        setExecutionAuditList([]);
           }
    });
  };
  const handleFilterClick = () => {
    let request = {};
    if (selectedUser && selectedUser != 0) {
      request.user_id = selectedUser;
           }
    if (selectedCompany !== 0) {
      request.company_id = selectedCompany;
    }
    if (fromDate & toDate) {
      request.from_date = moment(fromDate)
        .startOf('day')
        .format('yyyy-MM-DD HH:mm:ss');
      request.to_date = moment(toDate)
        .endOf('day')
        .format('yyyy-MM-DD HH:mm:ss');
        }
    getExecutionAuditData(request);
  };

    return (
    <div>
            <Box margin='20px'>
                <Grid container>
          <Grid item lg={3} md={3} xs={12}>
            <div className={classes.heading}>Execution Audit</div>
          </Grid>
                    <Grid item lg={2} md={2} xs={12}>
            {props.isSuperAdmin ? (
              <FormControl
                className={classes.selectInputField}
                size='small'
                variant='outlined'
              >
                <InputLabel htmlFor='outlined-company-native-simple'>
                  Company
                </InputLabel>
                            <Select
                            native
                            value={selectedCompany}
                  onChange={(event) => setSelectedCompany(event.target.value)}
                            label='Company'
                            inputProps={{
                                name: 'Company',
                                id: 'outlined-company-native-simple',
                            }}
                            >
                            {companyList &&
                                companyList.map((x, index) => (
                                    <option key={index} value={x.value.id}>
                                    {x.displayName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
            ) : (
              ''
            )}
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
            <FormControl
              className={classes.selectInputField}
              size='small'
              variant='outlined'
            >
              <InputLabel htmlFor='outlined-user-native-simple'>
                User
              </InputLabel>
                            <Select
                            native
                            value={selectedUser}
                onChange={(event) => setSelectedUser(event.target.value)}
                            label='Company'
                            inputProps={{
                                name: 'user',
                                id: 'outlined-company-native-simple',
                            }}
                            >
                            {userList &&
                                userList.map((x, index) => (
                                    <option key={index} value={x.id}>
                                    {`${x.firstname} ${x.lastname}`}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                            size='small'
                            id='fromDate'
                            label='From Date'
                            value={fromDate}
                onChange={(date) => setFromDate(date)}
                            required
                            className={classes.textFieldDate}
                            disableToolbar
                            variant='inline'
                            format='MM/DD/yyyy'
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                        size='small'
                        id='toDate'
                        label='To Date'
                        value={toDate}
                onChange={(date) => setToDate(date)}
                        required
                        className={classes.textFieldDate}
                        disableToolbar
                        variant='inline'
                        format='MM/DD/yyyy'
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={1} md={1} xs={12}>
            <Button
              onClick={handleFilterClick}
              className={classes.applyButton}
              variant='contained'
              href='#contained-buttons'
            >
                            Apply
                    </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.tableContainer} bgcolor='#eef1e6' padding='15px'>
                <ExecutionAuditDisplayTable 
          data={executionAuditList}
        ></ExecutionAuditDisplayTable>
            </Box>
        </div>
    );
};

export default ListExecutionAudit;
