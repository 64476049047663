import React, { useContext, useState, useEffect, useRef } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { fade, makeStyles } from "@material-ui/core/styles";

import {
  getPermissionStatus,
  getAnalyzer,
  executeAnalyser,
} from "../../../../service/service";

import BinarywayButton from "../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import { AppContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import CreateProcessAnalyzerCss from "./CreateProcessAnalyzer.module.css";

const useStyles = makeStyles((theme) => ({
  customChoosefileBtn: {
    backgroundColor: "#28a745",
    "&:hover": {
      backgroundColor: "#28a745",
    },
  },
  customPreviewBtn: {
    margin: "5px 5px",
    backgroundColor: "#0664ca",
    "&:hover": {
      backgroundColor: "#1b3dd2",
    },
  },
}));

const CreateProcessAnalyzer = (props) => {
  const classes = useStyles();
  const { appData, setAppData } = useContext(AppContext);
  const [analyzerList, setAnalyzerList] = useState([]);
  const [enableExecute, setEnableExecute] = useState(true);
  const [createProcessAnalyzerData, setCreateProcessAnalyzerData] = useState({
    analyzerId: null,
    selectedFile: null,
  });

  const [message, setMessage] = useState("");

  useEffect(() => {
    onLoadData();
  }, []);

  const onLoadData = async () => {
    getAnalyzer().then((result) => {
      if (result.message === "Valid Request") {
        setAnalyzerList(
          result && result.data && result.data.length > 0
            ? result.data.map((y) => {
                const _x = { value: y.ID, displayName: y.ANALYZER_NAME };
                return _x;
              })
            : []
        );
      }
    });
    setCreateProcessAnalyzerData({
      analyzerId: null,
      selectedFile: null,
    });
  };

  const onExecute = async (e) => {
    e.preventDefault();
    setMessage("");
    const formData = new FormData();
    if (
      createProcessAnalyzerData.selectedFile !== null &&
      createProcessAnalyzerData.analyzerId !== null
    ) {
      /* Testing*/
      formData.append("file", createProcessAnalyzerData.selectedFile);
      formData.append("analyzerId", createProcessAnalyzerData.analyzerId);
      let data = await executeAnalyser(formData);
      if (data == "Error: Request failed with status code 404") {
        setMessage("Please select analyzer with atleast one rule");
      }

      if (data && data.status) {
        const responseCode = data.status;
        let result = await data.data;

        if (responseCode === 200) {
          appData.ShowAlert = {
            bool: true,
            msg: "Analyzer Processed Successfully",
            type: "success",
          };
          setAppData({ ...appData, ShowAlert: appData.ShowAlert });

          const _mock_result = {
            selectedFile: createProcessAnalyzerData.selectedFile,
            analyzer: analyzerList.find(
              (x) => x.value === createProcessAnalyzerData.analyzerId
            ),
            response: result,
            executionID: data.executionId,
          };
          props.onRefresh(_mock_result);
        } else if (responseCode === 404) {
          setMessage("File Not found");
        } else if (result.status === 401) {
          setMessage("You are not Authorized");
        } else {
          setMessage("Something went wrong");
        }
      }
    } else {
      setMessage("Please enter all the fields");
    }
  };


  const toggleDrawer = (open) => (event) => {
    setCreateProcessAnalyzerData({
      analyzerId: null,
      selectedFile: null,
    });
    setEnableExecute(true);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setOpen(open);
  };

  const onRemoveFile = () => {
    setCreateProcessAnalyzerData({
      ...createProcessAnalyzerData,
      selectedFile: null,
    });
    setEnableExecute(true);
  };

  const list = (anchor) => (
    <>
      <div
        className={CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_head}
      >
        <span>Choose Analyzer</span>
        <span>
          <CancelIcon onClick={toggleDrawer(false)} />
        </span>
      </div>
      <div
        className={CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_body}
      >
        <div
          className={
            CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_body_row
          }
        >
          <BinarywayDropdown
            placeHolder=""
            label="Analyzer *"
            validator={true}
            errorMessage={"this field is required"}
            value={createProcessAnalyzerData.analyzerId || null}
            options={analyzerList}
            onChange={(e) => {
              setCreateProcessAnalyzerData({
                ...createProcessAnalyzerData,
                analyzerId: e.target.value,
              });
              if (createProcessAnalyzerData.selectedFile) {
                setEnableExecute(false);
              }
            }}
          />
        </div>

        <div
          className={
            CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_body_row
          }
        >
          <input
            id="contained-button-file-analyzer"
            type="file"
            accept=".bprelease"
            onChange={(e) => {
              setCreateProcessAnalyzerData({
                ...createProcessAnalyzerData,
                selectedFile: e.target.files[0],
              });
              if (createProcessAnalyzerData.analyzerId) {
                setEnableExecute(false);
              }
            }}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file-analyzer">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.customChoosefileBtn}
            >
              Choose File
            </Button>
          </label>
        </div>
        <div
          className={
            CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_body_row
          }
        >
          <span>
            {" "}
            {createProcessAnalyzerData.selectedFile &&
              createProcessAnalyzerData.selectedFile.name}
          </span>
          {createProcessAnalyzerData.selectedFile && (
            <span>
              <Tooltip title="Remove File" placement="bottom">
                <RemoveCircleOutlineIcon
                  onClick={onRemoveFile}
                  style={{ color: "#f44336", marginLeft: "5px" }}
                />
              </Tooltip>{" "}
            </span>
          )}
        </div>
        <div style={{ color: "red" }}>{message}</div>
      </div>
      {appData.Permissions &&
        getPermissionStatus(
          appData.Permissions,
          appConstants.EXECUTE_ANALYZER
        ) && (
          <div
            className={
              CreateProcessAnalyzerCss.processAnalyzerCreate_drawer_btn
            }
          >
            <BinarywayButton
              variant="outlined"
              onClick={toggleDrawer(false)}
              label="Cancel"
            ></BinarywayButton>
            <div style={{ marginLeft: "4%" }}>
              <BinarywayButton
                variant="contained"
                color="primary"
                className={classes.customPreviewBtn}
                disabled={enableExecute}
                onClick={onExecute}
                label="Preview"
              ></BinarywayButton>
            </div>
          </div>
        )}
    </>
  );

  return (
    <>
      <SwipeableDrawer
        id={"processAnalyzerCreate_drawer"}
        anchor="right"
        open={props.isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
};

export default CreateProcessAnalyzer;
