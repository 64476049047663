import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {
  getRuleData,
  getPermissionStatus,
  saveAnalyzer,
  getCompanyListDetails,
  getUserDetailsFromJwtToken,
  updateAnalyzer,
  getConfig,
} from "../../../../service/service";

import BinarywayButton from "../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayInput from "../../../UI-Components/Input/Input";
import { AppContext, AnalyzerContext } from "../../../../service/context";
import { appConstants } from "../../../../service/constants";
import AnalyzerViewCss from "./AnalyzerViewInfo.module.css";
import AnalyzerCreateCss from "../AnalyzerCreate/AnalyzerCreate.module.css";
import RuleDetailsDrawer from "../../Rules/ListRules/RuleDetailsDrawer/RuleDetailsDrawer";
import {
  Grid,
  Box,
  Typography,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { options } from "@amcharts/amcharts4/core";
import { filter } from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid lightgrey",
    borderRadius: 12,
    marginBottom: 10,
  },
  cardTitltContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingBottom: 0,
  },
  cardFooterContainer: {
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  cardMainDescription: {
    marginBottom: 7,
    fontSize: 14,
    color: "#000000",
    fontWeight: "normal",
    padding: 5,
  },
  cardNumOfRules: {
    color: "#919294",
    marginRight: 3,
    fontSize: 14,
    padding: 5,
  },
  cardTitle: {
    color: "white",
    fontSize: 14,
  },
  cardTitltContainerCheckbox: {
    margin: "10px",
    float: "right",
  },
  saveUpdateButtton: {
    boxShadow: "0 2px 3px 0 #000000",
    backgroundColor: "#0051b5",
  },
  autoComplete: {},
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    color: "black",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  autoCompleteTags: {
    color: "white",
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: "#0051b5",
    fontSize: "12px",
  },
  searchInput: {
    width: "432px",
    // height: 40px;
    margin: "0 20px",
    // padding: 7px 16px 8px;
    borderRadius: "12px",
    boxShadow: "0 2px 2px 0 var(--black-30)",
    border: "solid 1px #dcdddc",
    backgroundColor: "#ffffff",
  },
  searchBarLeftContainer: {
    display: "flex",
  },
  selectAllContainer: {
    display: "flex",
    alignItems: "center",
  },
  ruleCountTypography: {
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#919294",
  },
  ruleCountTypographytagline: {
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#919294",
  },
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
      color: "black",
    },
  },
}));

export const AnalyzerViewInfo = (props) => {
  const classes = useStyles();
  const [allrules, SetAllRules] = useState([]);
  const [filteredRules, SetFilteredRules] = useState([]);
  const [companyList, SetCompanyList] = useState([]);
  const [categoryList, SetCategoryList] = useState([]);
  const [userDetails, SetUserDetails] = useState({});
  const [isOpenViewMore, setOpenViewMore] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [searchParamas, setSearchParams] = useState("");
  const [indeterminate, setindeterminate] = useState(false);

  const [CreateAnalyzerDetails, SetCreateAnalyzerDetails] = useState({
    groups: [],
    ruleErrorMessage: "",
    nameErrorMessage: "",
    analyzerName: "",
    analyzerDescription: "",
    checkName: false,
    disbaleBtn: false,
    companyId: null,
    selectAll: false,
    editAnalyzer: false,
  });
  const { appData, setAppData } = useContext(AppContext);
  const { analyzerData, setAnalyzerData } = useContext(AnalyzerContext);
  useEffect(() => {
    onLoadData();
  }, []);

  //Filter rule list on change
  useEffect(() => {
    let filteredData = [];
    filteredData = allrules
      .filter(rule => selectedCategory.displayName === "All" || rule.CATEGORY === selectedCategory.displayName)//Category
      .filter((rule) => rule.NAME.toLowerCase().includes(searchParamas.toLowerCase()));//Rule name
    SetFilteredRules(filteredData);
  }, [allrules, searchParamas, selectedCategory]);

  const onLoadData = async () => {
    const _userDetails = getUserDetailsFromJwtToken();

    SetUserDetails(_userDetails);
    SetCreateAnalyzerDetails({
      ...CreateAnalyzerDetails,
      companyId: _userDetails.compid,
    });

    let rules = await getRuleData();

    //Category list
    let categoriesList = [{ displayName: "All" }];
    const configKeys = await getConfig();
    // eslint-disable-next-line no-unused-expressions
    configKeys.data?.recordsets[0]?.forEach((obj) => {
      if (obj.TYPE === "CATEGORY") {
        categoriesList.push({ value: obj, displayName: obj.NAME });
      }
    });

    SetCategoryList(categoriesList);
    setSelectedCategory(categoriesList[0]);
    if (_userDetails && _userDetails.role === "super admin") {
      let result3 = await getCompanyListDetails();
      const _companyList =
        result3 && result3.data && result3.data.length > 0
          ? result3.data.map((y) => {
            const _x = { value: y.id, displayName: y.name };
            return _x;
          })
          : [];
      SetCompanyList(_companyList);
    };
    //Filter rule for analyzer
    SetAllRules(rules.data.filter(ar => !!analyzerData.selectedObj.rules.find(r => r.id === ar.ID)))
  }

  const onViewRuleDetails = (obj) => {
    setCurrentRule(obj);
    setOpenViewMore(true);
  };

  const handleSelectCategory = (obj) => {
    if (obj.displayName === "All") {
      setSelectedCategory(obj);
      SetFilteredRules(allrules);
      setSearchParams("");
      return obj;
    }
    if (obj) {
      let filteredData = [];
      setSelectedCategory(obj);
      let filteredRules = [];
      const newRules = allrules.map((rule) => {
        if (
          rule.CATEGORY_ID === obj.value.ID &&
          analyzerData.selectedObj.rules.find((obj) => obj.name == rule.NAME)
        )
          filteredRules.push(rule);
        return obj;
      });

      filteredData = filteredRules;
      if (
        analyzerData &&
        analyzerData.selectedObj &&
        analyzerData.selectedObj.rules
      ) {
        filteredData = filteredRules.map((obj) => {
          const index = analyzerData.selectedObj.rules.findIndex(
            (rule) => rule.id === obj.ID
          );
          if (index !== -1) {
            return { ...obj, checked: true };
          } else {
            return { ...obj, checked: false };
          }
        });
      }
      SetFilteredRules(filteredData);

      SetCreateAnalyzerDetails({
        ...CreateAnalyzerDetails,
        ruleErrorMessage: "",
        // selectAll: false,
      });
    }
  };

  const onBack = () => {
    props.onGotoListAnalyzer();
  };


  const [collapse, setCollpase] = useState(false);
  const [isVisible, setVisible] = useState("");
  return (
    <>
      <Container
        fluid={true}
        className={AnalyzerViewCss.analyzerCreate_body_background}
        style={{ paddingLeft: "0px", height: "auto" }}
      >
        <Row className="analyzer_header">
          <Col xs="4">
            <div className="analyzer_header_title">
              <span>
                <ArrowBackIcon onClick={onBack} />
              </span>{" "}
              <span style={{ fontSize: "21px", fontWeight: "normal" }}>
                {" "}
                {CreateAnalyzerDetails.analyzerName}{" "}
              </span>
            </div>
          </Col>
          <Col xs="4">
            {/* <Autocomplete
                multiple
                classes={{
                  option:classes.option
                }}
                size="small"
                limitTags={1}
                id="checkboxes-tags-demo"
                options={rules.sort((a,b)=>{
                  if(a.checked){
                    return -1
                  }
                  else {
                    return 1
                  }
                })}
                disableCloseOnSelect
                onChange={event=>{handleSelectWithId(event,event.target.value)}}
                
                renderTags={value=>{
                  let tag=''
                  if(value.length===1){
                    tag=value[0].NAME
                  }
                  else if (value.length >0){
                    tag = `${value.length} Rules Selected`
                  }
                  if(tag.length>25){
                    tag=tag.substr(0,25)+'...'
                  }
                  
                  return (
                    <React.Fragment >
                      <div className={classes.autoCompleteTags}>{tag}</div>
                    </React.Fragment>
                  )
                }}
                getOptionSelected={(option,value)=>{
                  return option.checked 
                }}
                value={rules.filter(rule=>{
                  return rule.checked?true:false
                })}
                
                getOptionLabel={(option) => option.NAME}
                renderOption={(option,{selected}) => {
                  
                  return (
                  <React.Fragment >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color:"black" }}
                      checked={selected}
                      value={option.ID}
                    />
                   <div >
                      {option.NAME}
                      <br />
                      <Typography variant="caption" style={{color:'gray'}}>Category:{option.CATEGORY}</Typography>
                    </div>
                  </React.Fragment>
                
                )}}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField  {...params} variant="outlined" label="Select Rules" placeholder="Select Rules" />
                )}
              /> */}
          </Col>
          <Col xs="4">
            {" "}
            <div className="analyzer_stepper_button">
              {analyzerData.viewType != "view" &&
                appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_ANALYZER
                ) && (
                  <div>
                    <BinarywayButton
                      class={classes.saveUpdateButtton}
                      label={
                        analyzerData.viewType == "edit" ? "Update" : "Save"
                      }
                    ></BinarywayButton>
                  </div>
                )}
            </div>
          </Col>
        </Row>
        <div
          id="main_container"
          style={{ display: "flex", flexDirection: "row", width: "100%" }}
        >
          <div
            id="collapse_bar"
            style={{
              minWidth: ` ${collapse ? "5vw" : "21vw"} `,
              maxWidth: ` ${collapse ? "5vw" : "21vw"} `,
              backgroundColor: "white",
              marginRight: "10px",
              marginTop: "0px",
              boxShadow: "1px 0px 5px -2px black",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  display: ` ${collapse ? "none" : "block"} `,
                }}
              >
                {analyzerData.viewType === "view"
                  ? "View Analyzer Details"
                  : analyzerData.viewType === "edit"
                    ? "Edit Analyzer Details"
                    : "Create Analyzer Details"}
              </p>
              <div
                style={{
                  width: ` ${collapse ? "100%" : "10%"} `,
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1.1rem",
                  }}
                  onClick={() => {
                    setCollpase(!collapse);
                  }}
                >
                  {" "}
                  {collapse ? (
                    <i
                      className="fa fa-arrow-circle-right"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-arrow-circle-left"
                      style={{ fontSize: "1.4rem" }}
                    ></i>
                  )}{" "}
                </button>
              </div>
            </div>
            {!collapse && <hr />}
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "rgb(15, 24, 63)",
                  borderRadius: "10px",
                  color: "white",
                  display: ` ${collapse ? "none" : "block"} `,
                }}
              >
                Note : Rules are listed in All tab and category wise tabs.
                Please select rules for analyzer and then click Save to proceed.
              </div>
            </div>
            {!collapse && <hr />}
            <div
              style={{
                marginBottom: "8px",
                display: ` ${collapse ? "none" : "block"} `,
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  display: ` ${collapse ? "none" : "block"} `,
                }}
              >
                <BinarywayInput
                  disabled={analyzerData.viewType === "view"}
                  label="Analyzer Name*"
                  type="text"
                  fullWidth={true}
                  validators={["required", "maxLength"]}
                  errormessages={[
                    "this field is required",
                    "Max Length of the field is 50",
                  ]}
                  value={CreateAnalyzerDetails.analyzerName}
                  defaultValue={CreateAnalyzerDetails.analyzerName || ""}
                  style={{ color: "black" }}
                ></BinarywayInput>
              </div>
            </div>
            <div
              style={{
                marginBottom: "8px",
                display: ` ${collapse ? "none" : "block"} `,
              }}
            >
              <BinarywayInput
                disabled={analyzerData.viewType === "view"}
                label="Description*"
                type="text"
                fullWidth={true}
                validators={["required", "maxLength"]}
                errormessages={[
                  "this field is required",
                  "Max Length of the field is 100",
                ]}
                value={analyzerData.selectedObj.DESCRIPTION}
                defaultValue={
                  analyzerData.selectedObj.analyzerDescription || ""
                }
                size="large"
                border="2px solid green"
              ></BinarywayInput>
            </div>
            {userDetails && userDetails.role === "super admin" && (
              <div
                className={AnalyzerCreateCss.analyzerCreate_body_row}
                style={{ display: ` ${collapse ? "none" : "block"} ` }}
              >
                <BinarywayDropdown
                  disabled={analyzerData.viewType === "view"}
                  placeHolder=""
                  label="Company *"
                  validator={true}
                  errorMessage={"this field is required"}
                  value={CreateAnalyzerDetails.companyId}
                  defaultValue={CreateAnalyzerDetails.companyId || null}
                  options={companyList}
                />
              </div>
            )}
          </div>
          <div
            className={AnalyzerViewCss.analyzerCreate_body}
            style={{ flex: "auto" }}
          >
            <Paper
              style={{
                height: "80vh",
                width: "100%",
              }}
            >
              <div className={AnalyzerViewCss.analyzerCreate_categoriesBlock}>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((obj, i) => (
                    <div
                      className={
                        obj.displayName === selectedCategory.displayName
                          ? AnalyzerViewCss.analyzerCreate_selectedCategory
                          : AnalyzerViewCss.analyzerCreate_category
                      }
                      onClick={(e) => handleSelectCategory(obj)}
                    >
                      <span>{obj.displayName} </span>
                    </div>
                  ))}
              </div>
              <div
                className={AnalyzerViewCss.analyzerCreate_categoriesBlockSecond}
              >
                <div>{selectedCategory.displayName} Rules</div>
                <div className={classes.searchBarLeftContainer}>
                  <div className={classes.selectAllContainer}></div>
                  {selectedCategory &&
                    selectedCategory.displayName === "All" ? (
                    <BinarywayInput
                      className={classes.searchInput}
                      value={searchParamas}
                      placeholder="Search Rules"
                      onChange={(event) => setSearchParams(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon></SearchIcon>
                        </InputAdornment>
                      }
                    ></BinarywayInput>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Container
                fluid={true}
                className={AnalyzerViewCss.analyzerCreate_paper_body_height}
                style={{ flex: "1" }}
              >
                {(userDetails.role === "super admin" ? CreateAnalyzerDetails.companyId : true) && (
                  <Row
                    style={{
                      paddingTop: "16px",
                      width: "100%",
                      height: "100%",
                    }}
                    className={AnalyzerViewCss.analyzerCreate_paper_body}
                  >

                    {filteredRules.map((obj, index) => {
                      return (
                        <Col
                          xs={collapse ? "3" : "4"}
                          style={{
                            paddingRight: "5px",
                            paddingLeft: "7px",
                          }}
                        >
                          <Box
                            className={classes.cardContainer}
                            boxShadow={1}
                          >
                            <Grid container direction="column">
                              <Box
                                bgcolor="#0f183f"
                                pb={3}
                                className={classes.cardTitltContainer}
                                onMouseOver={() => {
                                  setVisible(obj.ID);
                                }}
                                onMouseOut={() => {
                                  setVisible("");
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={classes.cardTitle}
                                  style={{
                                    height: "52px",
                                  }}
                                >
                                  <div
                                    style={{
                                      maxWidth: "50px",
                                      minWidth: "50px",
                                      display: "flex",
                                      height: "27px",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontFamily: "Roboto",
                                      backgroundColor: "white",
                                      borderBottomLeftRadius:
                                        "10px",
                                      padding: "0px 10px",
                                      float: "right",
                                    }}
                                  >
                                    <VisibilityIcon
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        onViewRuleDetails(obj);
                                      }}
                                    />
                                  </div>
                                  <p style={{ padding: "6px", paddingLeft: '10px' }}>
                                    {obj.NAME}
                                  </p>
                                </Typography>
                              </Box>
                              {/* <Box bgcolor="white" p={1}>
                                  <Typography className={classes.cardMainDescription}>
                                    {obj.DESCRIPTION || "-"}
                                  </Typography>
                                </Box> */}
                              <Box
                                bgcolor="#0f183f"
                                p={1}
                                pt={0}
                                className={classes.cardFooterContainer}
                              >
                              </Box>
                            </Grid>
                          </Box>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </Container>
            </Paper>
          </div>
        </div>
      </Container>
      <RuleDetailsDrawer
        isOpen={isOpenViewMore}
        setOpen={setOpenViewMore}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
      />
    </>
  );
};

export default AnalyzerViewInfo;
