import React, { useState, useEffect, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import { AppBar, Toolbar } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BuildIcon from "@material-ui/icons/Build";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import ListAnalyzer from "./Analyzer/ListAnalyzer/ListAnalyzer";
import Profile from "../User/Profile/Profile";
import SuperAdmin from "../SuperAdmin/SuperAdmin";
import Admin from "../SuperAdmin/Admin/Admin";
import ListRules from "./Rules/ListRules/ListRules";
import RuleBuilder from "./Rules/RuleBuilder/RuleBuilder";
import Analyzer from "./Analyzer/Analyzer";
import ListProcessAnalyzer from "./ProcessAnalyzer/ListProcessAnalyzer/ListProcessAnalyzer";

import "../../App.css";
import BinaryWayLogoWhite from "../../assets/BinaryWayLogoWhite.png";

import {
  eraseCookie,
  getUserDetailsFromJwtToken,
  setNextRefreshToken,
  getMenu,
  getAllPermissions,
  handleLogout
} from "../../service/service";
import { AppContext } from "../../service/context";
import DashboardCss from "./Dashboard.module.css";
import PrivateRoute from "../../PrivateRoute";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    height:'100%'
  },
  inputRoot: {
    color: "inherit",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list:{
    backgroundColor:'red !important',
  },
  ListItem:{
    backgroundColor:'black !important'
  }

}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [menuValue, setMenuValue] = React.useState(0);
  const [rules, setRules] = useState([]);
  const [currentRule, setCurrentRule] = useState({});
  const [menuItem, setMenuItem] = useState([]);
  const [data, setData] = useState({
    analyzerList: [],
    load: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [userDetailsMenu, setUserDetailsMenu] = useState({});
  const { appData, setAppData } = useContext(AppContext);
  const menuId = "primary-search-account-menu";

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const getMenuItem = (_userData) => {
    const _displayMenu = [
      {
        displayName: "Rule Builder",
        Component: "Rules",
        path: "/Dashboard/Rules",
        code: "RULE_BUILDER_MENU",
      },
      {
        displayName: "Analyzer",
        Component: "CreateAnalyzerList",
        path: "/Dashboard/Analyzer/ListAnalyzer",
        code: "ANALYZER_MENU",
      },
      {
        displayName: "Execute Analyzer",
        Component: "Analayzer",
        path: "/Dashboard/ProcessAnalayzer",
        code: "PROCESS_ANALYZER_MENU",
      },
      {
        displayName: "Super Admin",
        Component: "SuperAdmin",
        path: "/Dashboard/SuperAdmin",
        code: "SUPER_ADMIN_MENU",
      },
      {
        displayName: "Admin",
        Component: "Admin",
        path: "/Dashboard/Admin",
        code: "ADMIN_MENU",
      },
    ];
    let _dataMenu = [];
    const body = {
      params: {
        userid: _userData.userid,
      },
    };
    getMenu(body).then((result) => {
      if (result && result.success) {
        for (let i = 0; i < result.data.length; i++) {
          const _temp = _displayMenu.find((y) => y.code == result.data[i].code);
          if (_temp) {
            _dataMenu.push(_temp);
            if (_temp.displayName === "Super Admin") {
              break;
            }
          }
        }
        setMenuItem(_dataMenu);
      }
    });
    return _displayMenu;
  };

  useEffect(() => {
    setNextRefreshToken();
    const _userData = getUserDetailsFromJwtToken();
    if (_userData) {
      const _data = {
        firstName: titleCase(_userData.firstName),
        lastName: titleCase(_userData.lastName),
        role: titleCase(_userData.role),
      };
      setUserDetailsMenu(_data);
      getMenuItem(_userData);
      getAllPermissionsForUser(_userData);
    }
  }, []);

  useEffect(() => {
    let url = window.location.href;
    let array = url.split("/");
    let path = "";
    array.forEach((x, i) => {
      if (i > 2) {
        path += "/" + x;
      }
    });

    const index = menuItem.findIndex((obj) => obj.path === path);

    if (index !== -1) {
      setMenuValue(index);
    }
  }, [menuItem]);

  const titleCase = (data) => {
    return data
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const getAllPermissionsForUser = (_userData) => {
    const body = {
      params: {
        userid: _userData.userid,
      },
    };
    getAllPermissions(body).then((result) => {
      if (result && result.success === true) {
        setAppData({ ...appData, Permissions: result.data });
      }
    });
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleMenuChange = (event, newValue) => {
    setMenuValue(newValue);
    const path = menuItem && menuItem[newValue] && menuItem[newValue].path;
    props.history.push(path);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          props.history.push("/Dashboard/Profile");
          handleMenuClose();
        }}
      >
        Change Password
      </MenuItem>
      <MenuItem
        onClick={() => handleLogout(props)}
      >
        Log Out
      </MenuItem>
    </Menu>
  );

  const list = (anchor) => {
    return (
      <div
        key={"dashboard_drawer"}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List className={classes.list} >
          {menuItem.map((data, index) => (
            <ListItem
              button
              className={classes.ListItem}
              key={data.displayName}
              onClick={() => {
                props.history.push(data.path);
              }}
            >
              <ListItemIcon>
                {data.displayName === "Rule Builder" && <BuildIcon />}
                {data.displayName === "Analyzer" && <AssessmentIcon />}
                {data.displayName === "Execute Analyzer" && <DataUsageIcon />}
                {data.displayName === "Super Admin" && (
                  <SupervisorAccountIcon />
                )}
                {data.displayName === "Admin" && <SupervisorAccountIcon />}
              </ListItemIcon>
              <ListItemText primary={data.displayName} />
              <Link to={data.path}></Link>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <>
      <div className={DashboardCss.dashboard_container}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title} variant="h6" noWrap>
              <img
                className={DashboardCss.logoImage}
                src={BinaryWayLogoWhite}
              />
              <Tabs
                className={DashboardCss.dashboard_menubar}
                value={menuValue}
                onChange={handleMenuChange}
                aria-label="simple tabs example"
              >
                {menuItem &&
                  menuItem.length > 0 &&
                  menuItem.map((data, index) => (
                    <Tab label={data.displayName} key={data.displayName} {...a11yProps(index)} />
                  ))}
              </Tabs>
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
            ></Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className={DashboardCss.dashboard_menu_carat}>
                <div className={DashboardCss.dashboard_menu_carat_name}>
                  <span>{userDetailsMenu.firstName && userDetailsMenu.firstName.toLowerCase() === 'codeiq'? 'Code IQ' : userDetailsMenu.firstName }</span>
                  <span> {userDetailsMenu.lastName}</span>
                </div>
                <div className={DashboardCss.dashboard_menu_carat_role}>
                  {userDetailsMenu.role}
                </div>
              </div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          id={"dashboard_drawer"}
          className={classes.menuButton}
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
        {renderMenu}
        <div id={"dashboard_main"} className={DashboardCss.dashboard_body}>
          <Switch>
            <PrivateRoute path="/Dashboard/Rules" {...props} component={ListRules} exact />
            <PrivateRoute path="/Dashboard/Analyzer" {...props} component={Analyzer} />
            <PrivateRoute path="/Dashboard/ProcessAnalayzer" {...props} component={ListProcessAnalyzer} exact />
            <PrivateRoute path="/Dashboard/Profile" component={Profile} exact />
            <PrivateRoute path="/Dashboard/Admin" component={Admin} exact />
            <PrivateRoute path="/Dashboard/SuperAdmin" component={SuperAdmin} exact />
            <PrivateRoute path="/Dashboard/RuleBuilder" {...props} component={RuleBuilder} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
