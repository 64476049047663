import React, { useState, useContext } from "react";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";

import "bootstrap/dist/css/bootstrap.css";
import ProfileCss from "./Profile.module.css";
import BinarywayButton from "../../UI-Components/Button/Button";
import BinarywayInput from "../../UI-Components/Input/Input";
import {
  updatePassword,
  eraseCookie,
  getPermissionStatus,
  handleLogout
} from "../../../service/service";
import { Redirect } from "react-router-dom";
import { AppContext } from "../../../service/context";
import { appConstants } from "../../../service/constants";
import AlertPng from '../../../assets/alert.png';
import Tooltip from "@material-ui/core/Tooltip";

const Profile = (props) => {
  const [userPassword, updatePasswordDetails] = useState({
    OldPassword: null,
    NewPassword: null,
    ConfirmPassword: null,
    isVaildResponse: true,
    inValidDetails: false,
    inValidPasswordMatch: false,
  });
  const [isToggleMsg, setToggleMsg] = useState(false);
  const { appData, setAppData } = useContext(AppContext);

  const setPasswordDetails = (type, event) => {
    if (type === "OldPassword") {
      updatePasswordDetails({
        ...userPassword,
        OldPassword: event.target.value,
      });
    } else if (type === "NewPassword") {
      updatePasswordDetails({
        ...userPassword,
        NewPassword: event.target.value,
      });
    } else if (type === "ConfirmPassword") {
      updatePasswordDetails({
        ...userPassword,
        ConfirmPassword: event.target.value,
      });
    }
  };

  const onChangePassword = () => {
    setToggleMsg(false);
    handleLogout(props);
  };

  const onUpdatePassword = async () => {
    if (
      userPassword.OldPassword &&
      userPassword.NewPassword &&
      userPassword.ConfirmPassword
    ) {
      if (userPassword.NewPassword === userPassword.ConfirmPassword) {
        updatePasswordDetails({
          ...userPassword,
          inValidDetails: false,
          inValidPasswordMatch: false,
        });
        const body = {
          oldpassword: userPassword.OldPassword,
          newpassword: userPassword.NewPassword,
        };
        const result = await updatePassword(body);
        if (result && result.success == false) {
          updatePasswordDetails({ ...userPassword, isVaildResponse: false });
        } else {
          updatePasswordDetails({ ...userPassword, isVaildResponse: true });
          setToggleMsg(true);
          //props.history.push("/Login");
          //window.location = "/";
        }
      } else {
        updatePasswordDetails({ ...userPassword, inValidPasswordMatch: true });
      }
    } else {
      updatePasswordDetails({ ...userPassword, inValidDetails: true });
    }
  };

 
    return (
      <>
        <Container fluid={true}>
          <Row className={ProfileCss.profile_Row_header}>
            <h4>Change Password
            <Tooltip placement="bottom" title={<span className={ProfileCss.alert_tooltip}>{
            "1. Password length should be a minimum \n of 10 characters.\n 2. Passwords must use at least three of the \n four available character types: lowercase \n letters, uppercase letters, numbers, and \n symbols."}</span>}>
            <img
              className={ProfileCss.alert_image}
              src={AlertPng}
              alt="Alert"
              />
            </Tooltip>
            </h4>
          </Row>
          <Row className={ProfileCss.profile_Row_body}>
            <BinarywayInput
              label={"Old Password *"}
              type={"password"}
              validators={["required"]}
              errormessages={["This field is required."]}
              onChange={(event) => setPasswordDetails("OldPassword", event)}
            ></BinarywayInput>
          </Row>
          <Row className={ProfileCss.profile_Row_body}>
            <BinarywayInput
              label={"New Password *"}
              type={"password"}
              validators={["required","password"]}
              errormessages={["This field is required.", "Password policy should match."]}
              onChange={(event) => setPasswordDetails("NewPassword", event)}
            ></BinarywayInput>
          </Row>
          <Row className={ProfileCss.profile_Row_body}>
            <BinarywayInput
              label={"Confirm Password *"}
              type={"password"}
              validators={["required","password"]}
              errormessages={["This field is required.", "Password policy should match."]}
              onChange={(event) => setPasswordDetails("ConfirmPassword", event)}
            ></BinarywayInput>
          </Row>

          {userPassword.isVaildResponse === false && (
            <Row className={ProfileCss.profile_Row_error}>
              Old password is not correct.
            </Row>
          )}
          {userPassword.inValidDetails === true && (
            <Row className={ProfileCss.profile_Row_error}>
              Please enter an valid Old Password and New Password.
            </Row>
          )}
          {userPassword.inValidPasswordMatch === true && (
            <Row className={ProfileCss.profile_Row_error}>
              New Password and Confirm Password is not same.
            </Row>
          )}
          <Row className={ProfileCss.profile_Row_button}>
            {/* {appData.Permissions &&
              getPermissionStatus(
                appData.Permissions,
                appConstants.UPDATE_USER
              ) && ( */}
                <BinarywayButton
                  label={"Change Password"}
                  onClick={() => onUpdatePassword()}
                ></BinarywayButton>
              {/* )} */}
          </Row>

          {isToggleMsg && (
            <Modal
              isOpen={isToggleMsg}
              toggle={() => {setToggleMsg(false);onChangePassword();}}
              className=""
              size="md"
              backdrop={true}
            >
              <ModalHeader toggle={() => {setToggleMsg(false);onChangePassword();}}>
                Change Password
              </ModalHeader>
              <ModalBody>
                <div>
                  <p>You have changed the password Successfully</p>
                  <button style={{ float: "right" }} onClick={onChangePassword}>
                    Ok
                  </button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </>
    );
};

export default Profile;
