import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/styles'
import { Box, Typography } from "@material-ui/core";

const CustomizeTextInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    "& label.Mui-focused": {
      color: "#63BDF3",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#63BDF3",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#63BDF3",
      },
      "&:hover fieldset": {
        borderColor: "#63BDF3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63BDF3",
      },
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: "1px solid #63BDF3",
    fontSize: 14,
    minWidth: "60%",
    padding: '20px 12px',
    paddingBottom: 15,
    fontFamily:'Roboto',
  },
}))(TextField);




const BinarywayInput = (props) => {
  const [property, setProperty] = useState({
    id: props.id || "input_" + Math.random(),
    label: props.label || "",
    placeholder: props.placeholder || "",
    variant: props.variant || "outlined",
    size: props.size || "small",
    value: props.value || "",
    maxLength: props.maxLength || 150,
    multiline: props.multiline || false,
    ...props,
  });

  const onInputChange = (event) => {
    if (props.validators && props.validators.length > 0) {
      for (let i = 0; i < props.validators.length; i++) {
        const result = onValidator(props.validators[i], event);
        if (result === false) {
          setProperty({
            ...property,
            error: true,
            helperText: props.errormessages[i],
          });
          break;
        } else {
          setProperty({ ...property, error: false, helperText: "" });
        }
      }
    }
    props.onChange(event);
  };

  const onValidator = (type, event) => {
    let result = false;
    if (type === "required") {
      if (event.target.value) {
        result = true;
      }
    } else if (type === "isEmail") {
      if (event.target.value && event.target.value.trim().length > 0) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = re.test(event.target.value);
      }
    } else if (type === "maxLength") {
      if (event.target.value) {
        result = event.target.value.length <= property.maxLength ? true : false;
      }
    } else if (type === "password") {
      if (event.target.value && event.target.value.trim().length > 0) {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
        result = re.test(event.target.value);
      }
    }
    return result;
  };

  return (
    <CustomizeTextInput
      {...property}
      label={props.label}
      value={props.value}
      onChange={onInputChange}
      onBlur={onInputChange}
      variant="outlined"
      color="#63BDF3"
      backgroundColor="#63BDF3"
      inputProps={{ style: { fontFamily: "Roboto", color: "black", } }}
    />
  );
};

export default BinarywayInput;
