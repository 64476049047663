import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Dialog, Button, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { forgotPassword } from "../../service/service";

const styles = {
  buttonStyle: {
    color: "#0077C8",
    fontSize: "-6.125rem",
    minWidth: "58px",
    minHeight: "36px",
    boxSizing: "border-box",
    fontWeight: "500",
    borderRadius: "1px",
    textTransform: "capitalize"
  },
  Popupbutton: {
    padding: "5px 10px",
    fontSize: "0.8125em",
    textAlign: "center",
    cursor: "pointer",
    outline: "none",
    color: "#fff",
    backgroundColor: "#0077C8",
    border: "none",
    borderRadius: "5px",
    float: "right",
    fontWeight: "400",
    marginLeft: "15px",
    boxShadow: "none"
  },
  MuiDialogContent: {
    flex: "1 1 auto",
    padding: "15px",
    overFlowy: "auto",
    webkitOverflowScrolling: "touch"
  },
  NewButton: {
    color: "#ffffff",
    backgroundColor: "#0077C8",
    float: "right",
    minHeight: "32px",
    marginLeft: "1em"
  },
  textField: {
    marginLeft: "theme.spacing.unit",
    marginRight: "theme.spacing.unit",
    width: "90%"
  },
  dialogTitle:{
    backgroundColor: "#164279",
    flex: "0 0 auto",
    margin: "0",
    paddingleft: "7em",
    padding: "0em"
  },
  dialogH4:{
    color: "white",
    fontSize: "16px",
    fontWeight: "lighter",
    textAlign: "left",
    margin: "0",
    paddingLeft: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  formControl:{
    margin: "theme.spacing.unit",
    width:"100%"
  },
  formControl1:{
    margin: "theme.spacing.unit"
  },
  email:{
    fontSize: "0.875em",
    textAlign: "left"
  },
  formGrid:{
    paddingLeft: "9em",
    paddingTop: "2em"
  },
  red:{
    color:"red"
  },
  rgb:{
    color:"rgba(0, 0, 0, 0.87)"
  }
};

const ActionButtons = ({ classes, onCancel, onSubmit, needSubmit }) => (
  <div>
    {needSubmit ?
      (<div>
        <Button
          variant="contained"
          label="Cancel"
          id="buton"
          onClick={onCancel}
          className={classes.Popupbutton}
          floated="right"
        >
          Cancel
    </Button>
        <Button
          variant="contained"
          type="submit"
          primary
          id="buton"
          className={classes.Popupbutton}
          onClick={onSubmit}
        >
          Reset
    </Button>
      </div>)
      :
      (<Button
        variant="contained"
        type="submit"
        primary
        id="buton"
        className={classes.Popupbutton}
        onClick={onCancel}
      >
        Ok
    </Button>)}
  </div>
);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      agree: false,
      successMessage: "",
      mailFlag: false,
      nextFlag: false,
      showColor: false,
      needSubmit: true
    };
  }

  closeHandler = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  deleteHandleClose = () => {
    const { onCancel } = this.props;
    this.setState({ agree: false });
    onCancel();
  };

  validatem = name => {
    if (name.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({
        mailFlag: false,
        nextFlag: true,
        showColor: false
      });
    } else {
      this.setState({
        mailFlag: true,
        nextFlag: false,
        showColor: false
      });
    }
  };

  captureEmail = name => {
    this.setState({ email: name, showColor: false });
    this.validatem(name);
  };

  onSubmit = async () => {
    const { email, nextFlag } = this.state;

    const form = {
      email
    };
    try{
    if (nextFlag === true) {
      const res = await forgotPassword(form);
      if (res.message === "Success") {
        this.setState({
          successMessage:
            "We have sent a new password to your email. Please check it.",
          needSubmit: false
        });
      } else {
        this.setState({
          successMessage: "The email address you entered is not registered. Please try again.",
          showColor: true,
          mailFlag: true
        });
      }
    } else {
      this.setState({ showColor: true, mailFlag: true });
    }
    this.setState({ agree: true, email: "" });
  }catch(error)
  {
    console.log(error);
  }
  };

  render() {
    const { classes, onCancel, forgotmessage } = this.props;
    const { email, agree, successMessage, mailFlag, showColor, needSubmit } = this.state;

    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          modal
          open
          maxWidth="xs"
          aria-labelledby="form-dialog-title"
          scroll="body"
        >
          <DialogTitle
            id="confirmation-dialog-title"
            className={classes.dialogTitle}
          >
            <h4
            className={classes.dialogH4}
            >
              Forgot Password
            </h4>
          </DialogTitle>
          <DialogContent scroll="body" className={classes.MuiDialogContent}>
            <p className={showColor?classes.red:classes.rgb}>
              {agree ? successMessage : forgotmessage}
            </p>

            {needSubmit ?
              (<FormControl
                className={classes.formControl}
              >
                <TextField
                  id="component3"
                  label="Email"
                  className={classes.email}
                  defaultValue=""
                  name="email"
                  inputProps={{ maxLength: 256 }}
                  value={email}
                  onChange={e => this.captureEmail(e.target.value)}
                  error={mailFlag}
                  helperText={mailFlag ? "Invalid mail id" : ""}
                />
              </FormControl>) : null}

            <FormControl className={classes.formControl1}>
              <Grid container spacing={12}>
                <Grid
                  item
                  xs={12}
                  className={classes.formGrid}
                >
                  <ActionButtons
                    classes={classes}
                    onCancel={onCancel}
                    onSubmit={this.onSubmit}
                    needSubmit={needSubmit}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
