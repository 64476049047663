import React, { useContext, useState, useEffect } from 'react';
import * as d3 from 'd3';
import './Chart.css';
import { AppContext } from '../../../service/context';
import { chartColors } from '../../../service/constants';

const CategoryChart1 = ({ data, count , viewRule, analyzerResultData}) => {
  let total1 = 0;

  let [selectedRule, setSelectedRule] = useState();
  data.map((item) => {
    total1 += item.categoryIndex;
  });
  let API2 = data;
  // let total1 = 1665;

  // let API2 = [
  //   {
  //     category: '03-NA-FI-0010_LoadQueue',
  //     categoryIndex: 75,
  //     subData: [
  //       { name: 'Pass', value: 67 },
  //       { name: 'Fail', value: 8 },
  //     ],
  //   },
  //   {
  //     category: '03-NA-FI-0010-Daily Recon',
  //     categoryIndex: 275,
  //     subData: [
  //       { name: 'Pass', value: 210 },
  //       { name: 'Fail', value: 65 },
  //     ],
  //   },
  //   {
  //     category: 'Core - MS Graph Upload File To Document Library',
  //     categoryIndex: 35,
  //     subData: [
  //       { name: 'Pass', value: 31 },
  //       { name: 'Fail', value: 4 },
  //     ],
  //   },
  //   {
  //     category: 'Core - Oracle Login Process',
  //     categoryIndex: 40,
  //     subData: [
  //       { name: 'Pass', value: 35 },
  //       { name: 'Fail', value: 5 },
  //     ],
  //   },
  //   {
  //     category: '000 - General Utilities',
  //     categoryIndex: 399,
  //     subData: [
  //       { name: 'Pass', value: 382 },
  //       { name: 'Fail', value: 17 },
  //     ],
  //   },
  //   {
  //     category: '000 - Oracle - Find Request',
  //     categoryIndex: 80,
  //     subData: [
  //       { name: 'Pass', value: 46 },
  //       { name: 'Fail', value: 34 },
  //     ],
  //   },
  //   {
  //     category: '000 - Oracle - Navigator',
  //     categoryIndex: 32,
  //     subData: [
  //       { name: 'Pass', value: 25 },
  //       { name: 'Fail', value: 7 },
  //     ],
  //   },
  //   {
  //     category: '000 - Oracle - Parameters',
  //     categoryIndex: 115,
  //     subData: [
  //       { name: 'Pass', value: 66 },
  //       { name: 'Fail', value: 49 },
  //     ],
  //   },
  //   {
  //     category: '000 - Oracle - Requests',
  //     categoryIndex: 115,
  //     subData: [
  //       { name: 'Pass', value: 69 },
  //       { name: 'Fail', value: 46 },
  //     ],
  //   },
  //   {
  //     category: '000 - Submit a New Request',
  //     categoryIndex: 45,
  //     subData: [
  //       { name: 'Pass', value: 32 },
  //       { name: 'Fail', value: 13 },
  //     ],
  //   },
  //   {
  //     category: '000 Oracle -Main Menu Bar',
  //     categoryIndex: 137,
  //     subData: [
  //       { name: 'Pass', value: 84 },
  //       { name: 'Fail', value: 53 },
  //     ],
  //   },
  //   {
  //     category: '03-NA-FI-0010-Daily Recon_Utilities',
  //     categoryIndex: 34,
  //     subData: [
  //       { name: 'Pass', value: 30 },
  //       { name: 'Fail', value: 4 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - Find Bank Statements',
  //     categoryIndex: 34,
  //     subData: [
  //       { name: 'Pass', value: 25 },
  //       { name: 'Fail', value: 9 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - Find Journals',
  //     categoryIndex: 52,
  //     subData: [
  //       { name: 'Pass', value: 34 },
  //       { name: 'Fail', value: 18 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - Journal Entry Inquiry',
  //     categoryIndex: 51,
  //     subData: [
  //       { name: 'Pass', value: 39 },
  //       { name: 'Fail', value: 12 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - Journals',
  //     categoryIndex: 73,
  //     subData: [
  //       { name: 'Pass', value: 48 },
  //       { name: 'Fail', value: 25 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - View Bank Statement',
  //     categoryIndex: 38,
  //     subData: [
  //       { name: 'Pass', value: 36 },
  //       { name: 'Fail', value: 2 },
  //     ],
  //   },
  //   {
  //     category: 'Oracle - View Bank Statement Reconciliation',
  //     categoryIndex: 42,
  //     subData: [
  //       { name: 'Pass', value: 30 },
  //       { name: 'Fail', value: 12 },
  //     ],
  //   },
  //   {
  //     category: 'Release Specific Occurrences',
  //     categoryIndex: 2,
  //     subData: [
  //       { name: 'Pass', value: 0 },
  //       { name: 'Fail', value: 2 },
  //     ],
  //   },
  // ];

  let finalData = API2.map((item, i) => {
    return {
      perc: Math.round((item.categoryIndex / total1) * 100),
      type: item.category,
      subData: [
        {
          name: item.subData[0].name,
          value: Math.round((item.subData[0].value / item.categoryIndex) * 100),
        },
        {
          name: item.subData[1].name,
          value: Math.round((item.subData[1].value / item.categoryIndex) * 100),
        },
      ],
    };
  });

  useEffect(() => {
    categoryChartParent();
  }, []);
  function categoryChartParent() {
    var data = finalData
                .filter(i => i.perc !== 0)
                .sort((a, b) => b.perc - a.perc)
    var text = '';

    var width = 350;
    var height = 330;
    var thickness = 40;
    var duration = 750;
    var padding = 40;
    var opacity = 0.8;
    var categorychartdata = [];
    var opacityHover = 1;
    var otherOpacityOnHover = 0.8;
    var tooltipMargin = 13;

    var radius = Math.min(width - padding, height - padding) / 2;
    var color = d3.scaleOrdinal().domain([]).range(chartColors);
    document.getElementById('categoryChartParent').innerHTML = '';
    document.getElementById('categoryChartParentLegend').innerHTML = '';
    var svg = d3
      .select('#categoryChartParent')
      .append('svg')
      .attr('class', 'pie')
      .attr('width', width)
      .attr('height', height);

    var g = svg
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var arc = d3.arc().innerRadius(0).outerRadius(radius);

    var pie = d3
      .pie()
      .value(function (d) {
        return d.perc;
      })
      .sort(null);

    var div = d3
      .select('#categoryChartParent')
      .append('div')
      .attr('class', 'tooltip-donut')
      .style('opacity', 0);
    var path = g
      .selectAll('path')
      .data(pie(data))
      .enter()
      .append('g')
      .append('path')
      .attr('class', 'slice')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      .style('opacity', opacity)
      .style('stroke', 'white')
      .on('click', function(d, i){  
        // push all slices to center
        d3.selectAll('.slice').attr("transform",'translate(0,0)')
        // pullout clicked slice
        d3.select(this)
          .transition()
          .duration(500).ease(d3.easeBack)
          .attr("transform",function(d) {
              var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
              var x = Math.cos(a) * 20;
              var y = Math.sin(a) * 20;
              return 'translate(' + x + ',' + y + ')';                
          }); 
        legendHighlight(i.data);
        onRotate(i);
      })
      .on('mouseover', function (event, d, i) {
        d3.select(this).transition().duration('50').attr('opacity', '.85');
        div.transition().duration(50).style('opacity', 1);
        let num = `${d.data.type} (${d.data.perc})`;
        div
          .html(num)
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 15 + 'px')
          .style('text-align', 'center');
      })
      .on('mouseout', function (d, i) {
        d3.select(this).transition().duration('50').attr('opacity', '1');
        //Makes the new div disappear:
        div.transition().duration('50').style('opacity', 0);
      })
      // var path = g
      //   .selectAll("path")
      //   .data(pie(data))
      //   .enter()
      //   .append("g")
      //   .append("path")
      //   .attr("d", arc)
      //   .attr("fill", (d, i) => color(i))
      //   .style("opacity", opacity)
      //   .style("stroke", "white")

      //   .on("click", (d, i) => onRotate(i))
      //   .on("mouseover", function (d, i) {
      //     d3.selectAll("path").style("opacity", otherOpacityOnHover);
      //     d3.select(this).style("opacity", opacityHover);

      //     let g = d3
      //       .select("svg")
      //       .style("cursor", "pointer")
      //       .append("g")
      //       .attr("class", "tooltip")
      //       .style("opacity", 0);

      //     g.append("text")
      //       .attr("class", "name-text")
      //       .text(`${i.data.type} (${i.data.perc})`)
      //       .attr("text-anchor", "middle");

      //     let text = g.select("text");
      //     let bbox = text.node().getBBox();
      //     let padding = 2;
      //     g.insert("rect", "text")
      //       .attr("x", bbox.x - padding)
      //       .attr("y", bbox.y - padding)
      //       .attr("width", bbox.width + padding * 2)
      //       .attr("height", bbox.height + padding * 2)
      //       .style("fill", "white")
      //       .style("opacity", 0.75);
      //   })

      .each(function (d, i) {
        if (i === 0) {
          categoryChartChild(d.data.subData, color(i), d.data.type);
        }
        categorychartdata.push(d);
        localStorage.setItem(
          'categorychartdata',
          JSON.stringify(categorychartdata)
        );
        this._current = i;
      });

    let legend = d3
      .select('#categoryChartParentLegend')
      .append('div')
      .attr('class', 'legend');

    let keys = legend
      .selectAll('.key')
      .data(data)
      .enter()
      .append('div')
      .attr('class', 'key')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('margin-right', '20px')
      .style('font-size', '14px');

    keys
      .append('div')
      .attr('class', 'symbol')
      .style('height', '10px')
      .style('width', '10px')
      .style('margin', '5px 5px')
      .style('border-radius', '50%')
      .style('background-color', (d, i) => color(i));

    keys
      .append('div')
      .attr('class', 'name')
      .text((d) => `${d.type} (${d.perc})`)
      .on('click', (d, i) => legendClick(i))
      .each(function (d, i) {
        if (i === 0) {
          legendClick(d)
        }
      });

    keys.exit().remove();

    function onRotate(i) {
      // The amount we need to rotate:
      var rotate = 90 - ((i.startAngle + i.endAngle) / 2 / Math.PI) * 180;
      // Transition the pie chart
      g.transition()
        .attr(
          'transform',
          'translate(' +
            width / 2 +
            ',' +
            height / 2 +
            ') rotate(' +
            rotate +
            ')'
        )
        .duration(1000);

      // Τransition the labels:
      // text
      //   .transition()
      //   .attr("transform", function (dd) {
      //     return (
      //       "translate(" + label.centroid(dd) + ") rotate(" + -rotate + ")"
      //     );
      //   })
      //   .duration(1000);
      categoryChartChild(i.data.subData, color(i.index), i.data.type);
    }

    function legendClick(d) {
      let val = JSON.parse(localStorage.getItem('categorychartdata'));
      // push all slices to center
      d3.selectAll('.slice').attr("transform",'translate(0,0)')
      // pullout clicked slice
      d3.selectAll('.slice').select(function(slice) {
        if(slice.data === d) {
          d3.select(this)
          .transition()
          .duration(500).ease(d3.easeBack)
          .attr("transform",function(d) {
            var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
            var x = Math.cos(a) * 20;
            var y = Math.sin(a) * 20;
            return 'translate(' + x + ',' + y + ')';                
          }); 
        }
      })
      legendHighlight(d);

      let indexData = val.map((item, i) => {
        if (item.data.type === d.type) {
          onRotate(item);
          categoryChartChild(item.data.subData, color(i), item.data.type);
        }
      });
    }
    
    // LEGEND HIGHLIGHT
    function legendHighlight(d) {
      d3.selectAll('.key')
      .select( function(ld) { 
         if (ld === d) {
           var backgroundColor = d3.select(this).select('.symbol').style('background-color')
           d3.select(this).transition().duration(500)//Set transition
             .style('background', backgroundColor)
             .style('color', 'white')
             this.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
         } else {
           d3.select(this).transition().duration(500)//Set transition
             .style('background', 'none')
             .style('color', 'black');
         }
     })
    }
    // LEGEND HIGHLIGHT

    function categoryChartChild(d, sliceColor, sliceTitle) {
      var data = d;

      var text = '';

      var width = 150;
      var height = 150;
      var thickness = 40;
      var duration = 750;
      var padding = 10;
      var opacity = 0.8;
      var opacityHover = 1;
      var otherOpacityOnHover = 0.8;
      var tooltipMargin = 13;

      var radius = Math.min(width - padding, height - padding) / 2.1;
      var color = d3.scaleOrdinal().domain(['Pass', 'Fail']).range(["#28a745", "#dc3545"]);
      document.getElementById('categoryChartChild').innerHTML = '';
      document.getElementById('categoryChartChildLegend').innerHTML = '';
      
      var title = d3
        .select('#categoryChartChild')
        .append('div')
        .attr('class', 'slice-title')
        .style('text-decoration', 'underline')
        .html(sliceTitle)
        .on('click', function() {
          var ruleData = analyzerResultData.filter(x=> {if(x.title === sliceTitle) return x});
          if(ruleData)
            viewRule(ruleData[0]);
        })
        
      var svg = d3
        .select('#categoryChartChild')
        .append('svg')
        .attr('class', 'pie')
        .attr('width', width)
        .attr('height', height)
        .style('background', sliceColor)
        .style('border-radius', '90px')

      var g = svg
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      var arc = d3.arc().innerRadius(0).outerRadius(radius);

      var pie = d3
        .pie()
        .value(function (d) {
          return d.value;
        })
        .sort(null);
      var div = d3
        .select('#categoryChartChild')
        .append('div')
        .attr('class', 'tooltip-donut')
        .style('opacity', 0);
      var path = g
        .selectAll('path')
        .data(pie(data))
        .enter()
        .append('g')
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => color(i))
        .style('opacity', opacity)
        .style('stroke', 'white')
        .style('stroke-width', '2')
        .on('mouseover', function (event, d) {
          d3.select(this).transition().duration('50').attr('opacity', '.85');
          div.transition().duration(50).style('opacity', 1);
          let num = `${d.data.name} (${d.data.value})`;
          div
            .html(num)
            .style('left', event.pageX + 10 + 'px')
            .style('top', event.pageY - 15 + 'px');
        })
        .on('mouseout', function (d, i) {
          d3.select(this).transition().duration('50').attr('opacity', '1');
          //Makes the new div disappear:
          div.transition().duration('50').style('opacity', 0);
        })

        // var path = g
        //   .selectAll("path")
        //   .data(pie(data))
        //   .enter()
        //   .append("g")
        //   .append("path")
        //   .attr("d", arc)
        //   .attr("fill", (d, i) => color(i))
        //   .style("opacity", opacity)
        //   .style("stroke", "white")
        //   .on("mouseover", function (d, i) {
        //     d3.selectAll("path").style("opacity", otherOpacityOnHover);
        //     d3.select(this).style("opacity", opacityHover);

        //     let g = d3
        //       .select("svg")
        //       .style("cursor", "pointer")
        //       .append("g")
        //       .attr("class", "tooltip")
        //       .style("opacity", 0);

        //     g.append("text")
        //       .attr("class", "name-text")
        //       .text(`${i.data.name} (${i.data.value})`)
        //       .attr("text-anchor", "middle");

        //     let text = g.select("text");
        //     let bbox = text.node().getBBox();
        //     let padding = 2;
        //     g.insert("rect", "text")
        //       .attr("x", bbox.x - padding)
        //       .attr("y", bbox.y - padding)
        //       .attr("width", bbox.width + padding * 2)
        //       .attr("height", bbox.height + padding * 2)
        //       .style("fill", "white")
        //       .style("opacity", 0.75);
        //   })

        .each(function (d, i) {
          this._current = i;
        })
        .transition().delay(function(d,i) {
          return i; }).duration(800)
          .attrTween('d', function(d) {
            var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
            return function(t) {
              d.endAngle = i(t); 
              return arc(d)
              }
            })

      let legend = d3
        .select('#categoryChartChildLegend')
        .append('div')
        .attr('class', 'legend')
        .style('margin-top', '30px')
        .style('font-size', '14px');

      let keys = legend
        .selectAll('.key')
        .data(data)
        .enter()
        .append('div')
        .attr('class', 'key')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('margin-right', '20px');

      keys
        .append('div')
        .attr('class', 'symbol')
        .style('height', '10px')
        .style('width', '10px')
        .style('margin', '5px 5px')
        .style('border-radius', '50%')
        .style('background-color', (d, i) => color(i));

      keys
        .append('div')
        .attr('class', 'name')
        .text((d) => `${d.name} (${d.value})`);

      keys.exit().remove();
    }
  }
  return (
    <div className="row">
      <div className="col-sm-3">
        <div id="categoryChartParentLegend"></div>
      </div>
      <div className="col-sm-5">
        <div id="categoryChartParent"></div>
      </div>
      <div className="col-sm-2">
        <div id="categoryChartChild"></div>
      </div>
      <div className="col-sm-2">
        <div id="categoryChartChildLegend"></div>
      </div>
    </div>
  );
};

export default CategoryChart1;
