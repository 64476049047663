import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import TemplateDetailsDrawerCss from "./TemplateDetailsDrawer.module.css";

const useStyles = makeStyles({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
});

const TemplateDetailsDrawer = ({
  isOpen,
  setOpen,
  currentSelectedTemplate,
  setcurrentSelectedTemplate,
}) => {
  const classes = useStyles();


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={TemplateDetailsDrawerCss.drawer_head}>
        <span>Template Details</span>
        <span>
          <CancelIcon onClick={toggleDrawer(false)} />
        </span>
      </div>
      <div className={TemplateDetailsDrawerCss.drawer_body}>
        <p>
          Name:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedTemplate && currentSelectedTemplate.name ? currentSelectedTemplate.name : "-"}
          </span>
        </p>
        <p>
          Company:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedTemplate && currentSelectedTemplate.company ? currentSelectedTemplate.company : "-"}
          </span>{" "}
        </p>
        <p>
          Description:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedTemplate && currentSelectedTemplate.description != "null" ? currentSelectedTemplate.description : "-"}
          </span>{" "}
        </p>
        <p>
          File Name:{" "}
          <span style={{ fontWeight: 500 }}>
            {currentSelectedTemplate && currentSelectedTemplate.filename ? currentSelectedTemplate.filename : "-"}
          </span>{" "}
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          id={"TemplateList_drawer"}
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default TemplateDetailsDrawer;
