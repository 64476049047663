import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import BinarywayInput from "../../../UI-Components/Input/Input";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import BinarywayButton from "../../../UI-Components/Button/Button";
import BinarywayDropdown from "../../../UI-Components/Drop-Down/Drop-Down";
import BinarywayTextArea from "../../../UI-Components/Text-Area/Text-Area";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import "bootstrap/dist/css/bootstrap.css";
import AddTemplateCss from "./AddTemplate.module.css";
import CloseIcon from '@material-ui/icons/Close';
import {
  createTemplate,
  getCompanyListDetails,
  getUserDetailsFromJwtToken
} from "../../../../service/service";
import AppBar from "@material-ui/core/AppBar";
import { AppContext } from "../../../../service/context";

const useStyles = makeStyles({
  list: {
    width: "25rem",
  },
  fullList: {
    width: 'auto',
  },
  addUserAppbar: {
    padding: "1.2rem 1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    color: "white",
    fontSize: "1.2rem",
    boxShadow: "none",
    fontWeight: 500,
    marginBottom: "1.5rem"

  },
  inputCol: {
    marginBottom: "1.3rem"
  }
});

const AddTemplate = (props) => {
  const classes = useStyles();
  const [templateDetails, setTemplateDetails] = useState({
    Name: null,
    Description: null,
    File: null,
    CompanyId: props.companyId || null,
  });

  const [viewType, setViewType] = useState("");
  const [ListCompany, SetListCompany] = useState([]);
  const [logedInUser, SetLogedInUser] = useState(getUserDetailsFromJwtToken());
  const [message, setMessage] = useState("");

  const resetTemplateDetails = () => {
    setTemplateDetails({
      Name: null,
      Description: null,
      File: null,
      CompanyId: props.companyId || null,
    })
  }

  useEffect(() => {
    onLoadSetData();
    onCompanyList();
  }, [props.dataFromList]);

  const onLoadSetData = () => {
    setViewType("Add");
  };

  const toggleDrawer = (open) => (event) => {
    resetTemplateDetails();
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setIsAddTemplate(open);
  };

  const onCompanyList = () => {
    if (logedInUser) {
      if (logedInUser.role === "super admin") {
        getCompanyListDetails().then((result) => {
          if (result.success === true) {
            result.data = result.data.map((y) => {
              const _x = { value: y.id, displayName: y.name };
              return _x;
            });
            SetListCompany(result.data);
          } else {
            SetListCompany([]);
          }
        });
      }
    } else {
      SetListCompany([]);
    }
  };

  const onTemplateDetailsUpdate = (type, event) => {
    let value;
    if (type && type === "File") {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }
    setTemplateDetails({ ...templateDetails, [type]: value });
  };

  const onRemoveFile = () => {
    setTemplateDetails({ ...templateDetails, File: null });
  };

  const onSavetemplateDetails = async (e) => {
    e.preventDefault();
    setMessage("");
    if (
      (templateDetails &&
        templateDetails.Name &&
        (props.isSuperAdmin === true ? templateDetails.CompanyId : logedInUser.compid) &&
        templateDetails.File)
    ) {
      setTemplateDetails({ ...templateDetails, inValidDetails: false });
      const formData = new FormData();
      if (
        templateDetails.Name !== null &&
        templateDetails.File !== null
      ) {
        const companyid = props.isSuperAdmin === true ? templateDetails.CompanyId : logedInUser.compid;

        formData.append("file", templateDetails.File);
        formData.append("name", templateDetails.Name);
        formData.append("description", templateDetails.Description);
        formData.append("companyId", companyid);

        let data = await createTemplate(formData);
        if (data && data.status) {
          const responseCode = data.status;
          let result = await data.data;
          if (responseCode === 200) {
            resetTemplateDetails();
            props.setIsAddTemplate(false);
            props.onChange("successAdd");
          } else {
            if (result.message) {
              setMessage(result.message);
            } else {
              setMessage("Something went wrong");
            }
          }
        } else {
          setMessage("Something went wrong");
        }
      } else {
        setMessage("Please enter all the required fields");
      }
    } else {
      setTemplateDetails({ ...templateDetails, inValidDetails: true });
    }
  };

  const list = (anchor) =>
    (<div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"

    >
      <AppBar position="static" className={classes.addUserAppbar}>
        <span>Add Template</span>
        <CloseIcon onClick={toggleDrawer(false)} />
      </AppBar>
      {templateDetails.inValidDetails && templateDetails.errorMessage && (
        <p style={{ color: "red" }}>{templateDetails.errorMessage}</p>
      )}
      <Row className={AddTemplateCss.addUser_Row_body}>
        <Col md="12" className={classes.inputCol}>
          <BinarywayInput
            label={"Name"}
            type={"text"}
            value={templateDetails.Name}
            defaultValue={''}
            onChange={(event) => onTemplateDetailsUpdate("Name", event)}
            style={{ width: "100%" }}
            inputProps={{ maxLength: 50 }}
            validators={["required", "maxLength"]}
            errormessages={[
              "this field is required",
              "Max Length of the field is 50",
            ]}
          ></BinarywayInput>
          {templateDetails.inValidDetails &&
            (templateDetails.Name === "" ||
              templateDetails.Name === null) && (
              <small style={{ color: "red" }}>Name is required</small>
            )}
        </Col>
        <Col md="12" className={classes.inputCol}>
          <BinarywayTextArea
            label={"Description"}
            type={"textarea"}
            validators={["maxLength"]}
            errormessages={["Max length is 200 characters"]}
            maxLength={200}
            rows={3}
            inputProps={{ maxLength: 200 }}
            value={templateDetails.Description}
            fullWidth={true}
            onChange={(event) => onTemplateDetailsUpdate("Description", event)}
          ></BinarywayTextArea>
        </Col>
        <Col md="12" className={classes.inputCol}>
          {props.isSuperAdmin === true ? (
            <>
              <BinarywayDropdown
                placeHolder=""
                label="Company"
                value={templateDetails.CompanyId}
                onChange={(event) => onTemplateDetailsUpdate("CompanyId", event)}
                options={ListCompany}
              />
              {
                templateDetails.inValidDetails &&
                (templateDetails.CompanyId === "" ||
                  templateDetails.CompanyId === null) && (
                  <small style={{ color: "red" }}>Company is required</small>
                )
              }
            </>
          ) : (
              <>
                <div className={AddTemplateCss.addUser_Row_Company_header}>
                  Company:
                </div>
                <div className={AddTemplateCss.addUser_Row_Company_subheader}>
                  {logedInUser.companyname}
                </div>
              </>
            )}
        </Col>
        <Col md={12} className={classes.inputCol}>
          <input
            id="contained-button-file-analyzer"
            type="file"
            accept=".xml"
            onChange={(event) => onTemplateDetailsUpdate("File", event)}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file-analyzer">
            <Button variant="contained" color="primary" component="span"
              className={classes.customChoosefileBtn}>
              Choose File
            </Button>
          </label>
        </Col>
        <Col md={12}>
          <span>
            {" "}
            {templateDetails.File &&
              templateDetails.File.name}
          </span>
          {templateDetails.File && (
            <span>
              <Tooltip title="Remove File" placement="bottom">
                <RemoveCircleOutlineIcon onClick={onRemoveFile} style={{ color: "#f44336", marginLeft: "5px" }} />
              </Tooltip>{" "}
            </span>
          )}
        </Col>
        <Col md={12}>
          <div style={{ color: 'red' }}>{message}</div>
        </Col>
        <Col md={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <BinarywayButton
            label={"Cancel"}
            variant={"outlined"}
            color="primary"
            onClick={toggleDrawer(false)}
          ></BinarywayButton>
          <div style={{ marginLeft: '10px' }}>
            <BinarywayButton
              label={"Save"}
              disabled={!templateDetails.File}
              onClick={onSavetemplateDetails}
            ></BinarywayButton>
          </div>
        </Col>
      </Row>
    </div>
    )


  return (
    <>
      <SwipeableDrawer
        open={props.isAddTemplate}
        onClose={props.toggleDrawer(false)}
        onOpen={props.toggleDrawer(true)}
        anchor="right"
      >
        {list('right')}
      </SwipeableDrawer>
    </>
  );
};

export default AddTemplate;
