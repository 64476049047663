import React from "react";
import Button from "@material-ui/core/Button";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import BinarywayButtonCss from "./Button.module.css";

const CustomizeButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#0051b5",
    minWidth: 104,
    minHeight: 40,
    [theme.breakpoints.down('sm')] : {
      minWidth: 90,
    minHeight: 30,
    },
    fontSize: "12px !important",
    "&:disabled": {
      backgroundColor: "#0051b5",
      color: "white"
    },
    "&:hover": {
      backgroundColor: "#0051b5",
    }
  },
}))(Button);

const BinarywayButton = (props) => {
  const id = props.id || "button-" + Math.random,
    variant = props.variant || "contained",
    color = props.color || "primary",
    label = props.label || "Button",
    size = props.size || "medium",
    disabled = props.disabled || false,
    customClass = props.class;
  return (
    <CustomizeButton
      id={id}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onClick={props.onClick}
      className={customClass ? customClass : BinarywayButtonCss.binaryway_button}
    >
      {label}
    </CustomizeButton>
  );
};

export default BinarywayButton;
