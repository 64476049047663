import axios from "axios";

import { url, endpoints } from "./config";
import { BroadcastChannel } from "broadcast-channel";

const logoutChannel = new BroadcastChannel("logout");

var jwt = require("jwt-decode");
let jwtToken = "login";
let jwtCookieName = "access-token";
let jwtCookieTimeName = "time-access-token";
export const jwtCookieTime = 240;
let apiLoaderCount = 0;

const onRequestRejected = (error) => {
  // Do something with request error
  apiLoaderCount -= 1;
  eraseCookie();
  if (apiLoaderCount > 0) {
    binaryNext(true);
  } else {
    binaryNext(false);
  }
  window.location = "/";
  return Promise.reject(error);
};


const onResponseRejected = (error) => {
  if (error.response.status === 401) {
    window.location = "/Login";
  }
  apiLoaderCount -= 1;
  if (apiLoaderCount > 0) {
    binaryNext(true);
  } else {
    binaryNext(false);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(function (config) {
  apiLoaderCount += 1;
  config.headers["Content-Type"] = "application/json";
  // Do something before request is sent
  jwtToken = getCookie() || jwtToken;

  if (apiLoaderCount > 0) {
    binaryNext(true);
  } else {
    binaryNext(false);
  }
  try {
    if (jwtToken) {
      config.headers["x-jwt-token"] = jwtToken;
    } else {
      throw "jwt Token error";
    }
  } catch (e) {
    return onRequestRejected(e);
  }
  return config;
}, onRequestRejected);

axios.interceptors.response.use(
  function (response) {
    setTimeout(() => {
      apiLoaderCount -= 1;
      if (apiLoaderCount > 0) {
        binaryNext(true);
      } else {
        binaryNext(false);
      }
    }, 750);
    return response;
  }, function (error) {
    onResponseRejected(error)
    return error.response
  });


// will remove this code later
// export const executeAnalyzer = async (data) => {
//   try {
//     const res = await axios.post(`${url}${endpoints.executeAnalyser}`, data);
//     return res;
//   } catch (err) {
//     return err;
//   }
// };

export const addRule = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.addRule}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};


export const updateRule = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.updateRule}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};


export const getConfig = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getConfig}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getRulConfig = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getRuleConfig}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getChildData = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.getChildData}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getElementData = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getElementData}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getRuleData = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getRule}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const saveAnalyzer = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.saveAnalyzer}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateAnalyzer = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.updateAnalyzer}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAnalyzer = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getAnalyzer}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteRule = async (ruleId) => {
  try {
    const res = await axios.delete(`${url}${endpoints.deleteRule}/${ruleId}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteAnalyzer = async (analyzerId) => {
  try {
    const res = await axios.delete(
      `${url}${endpoints.deleteAnalyzer}/${analyzerId}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updatePassword = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.updatePassword}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const forgotPassword = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.forgotPassword}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userLogin = async (body) => {
  const res = await axios.post(`${url}${endpoints.postUserLogin}`, body);
  return res.data;
};

export const userLogout = async (body) => {
  const res = await axios.get(`${url}${endpoints.logout}`);
  return res.data;
};

export const handleLogout = async (props) => {
    logoutChannel.postMessage("Logout!!!");
    let result = await userLogout()
    eraseCookie();
    props.history.push("/Login");
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    handleLogout();
    logoutChannel.close();
  }
} 

export const resetPassword = async (userId) => {
  const res = await axios.post(`${url}${endpoints.resetPassword}`, { userId });
  return res.data;
};

export const activateUser = async (userId) => {
  const res = await axios.post(`${url}${endpoints.activateUser}`, { userId });
  return res.data;
};
export const deactivateUser = async (userId) => {
  const res = await axios.post(`${url}${endpoints.deactivateUser}`, { userId });
  return res.data;
};

export const postAddUserDetails = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.postAddUserDetails}`, body);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const postUpdateUserDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postUpdateUserDetails}`,
      body
    );

    return res.data;
  } catch (err) {
    return err;
  }
};

export const postDeleteUserDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postDeleteUserDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getUserDetailsByCompanyId = async (body) => {
  try {
    const res = await axios.get(
      `${url}${endpoints.getUserDetailsByCompanyId}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const postAddCompanyDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postAddCompanyDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const postUpdateCompanyDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postUpdateCompanyDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const postDeleteCompanyDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postDeleteCompanyDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getCompanyListDetails = async (body) => {
  try {
    const res = await axios.get(
      `${url}${endpoints.getCompanyListDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getExecutionAuditListDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.getExecutionAudit}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
}
export const getRefreshtoken = async () => {
  const res = await axios.get(`${url}${endpoints.getRefreshtoken}`);
  return res.data;
};

export const getRoles = async () => {
  const res = await axios.get(`${url}${endpoints.getRoles}`);
  return res.data;
};

export const getAllPermissions = async (body) => {
  try {
    const res = await axios.get(`${url}${endpoints.getAllPermissions}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getMenu = async (body) => {
  try {
    const res = await axios.get(`${url}${endpoints.getMenu}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const executeAnalyser = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.executeAnalyser}`, body);

    res.data.processNameData = res.data.data.processNameData
    res.data.success = res.data.data.success
    let exec = res.data.data.executionId
    let data = res.data.data.data
    if (data.length > 0) {
      data = data.map(rs => {
        return ({ ...rs, ruleType: rs.outputMessage[0].rule })
      })
    }
    res.data.data = data;
    res.data.executionId = exec;
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFromRichText = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.uploadFromRichText}`, body);
    return res;
  } catch (err) {
    return err;
  }
};

export const setCookie = (value) => {
  eraseCookie();
  let expires = "";
  let date = new Date();
  if (jwtCookieTime) {
    const _intervalTime = jwtCookieTime * 60 * 1000;
    date.setTime(date.getTime() + _intervalTime);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = jwtCookieName + "=" + (value || "") + expires + "; path=/";
  document.cookie =
    jwtCookieTimeName + "=" + (date || "") + expires + "; path=/";
};

export const getCookie = () => {
  const nameEQ = jwtCookieName + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const setNextRefreshToken = () => {
  const nameEQ = jwtCookieTimeName + "=";
  const ca = document.cookie.split(";");
  let expCookie = null;
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      expCookie = c.substring(nameEQ.length, c.length);
    }
  }
  let seconds = (jwtCookieTime - 1) * 60 * 1000;
  if (expCookie) {
    const currentDateTime = new Date();
    const setDateTime = new Date(expCookie);
    seconds = setDateTime.getTime() - currentDateTime.getTime() - 30000;
  }
  // return seconds;
  setTimeout(
    () => {
      getRefreshtoken().then((result) => {
        if (result && result.token) {
          setCookie(result.token);
          setNextRefreshToken();
        }
      });
    },
    seconds < 0 ? 50 : seconds
  );
};

export const eraseCookie = () => {
  document.cookie =
    jwtCookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    jwtCookieTimeName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
export const getUserDetailsFromJwtToken = () => {
  const _data = getCookie();
  let _jwtData = null;
  if (_data) {
    _jwtData = jwt(_data);
  }
  return _jwtData;
};

export const getLoaderStatus = () => {
  return apiLoaderCount === 0 ? false : true;
};

export const getPermissionStatus = (list, code) => {

  let result = false;

  if (!list || !list.length) return result;

  const _data = list.find((x) => x === code);
  if (_data) {
    result = true;
  }
  return result;
};

// Custom Observable//
let binarySubscribers = [];

export const binarySubscribe = (method) => {
  binarySubscribers.push(method);
};

export const binaryNext = (value) => {
  binarySubscribers.forEach((x) => {
    x(value);
  });
};
// Custom Observable//


//Template :START
export const getTemplateDetailsByCompanyId = async (body) => {
  try {
    const res = await axios.get(
      `${url}${endpoints.getTemplateDetailsByCompanyId}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const postDeleteTemplateDetails = async (body) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.postDeleteTemplateDetails}`,
      body
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const createTemplate = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.createTemplate}`, body);
    return res;
  } catch (err) {
    return err;
  }
};
//Template :END


export const activeRule = async (body) => {
  try {
    const res = await axios.post(`${url}${endpoints.updateactivestatus}`, body);
    return res;
  } catch (err) {
    return err;
  }
}
export const getDownload = async (id) => {
  try {
    const res = await axios.get(`${url}${endpoints.executeDownload}/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getBlock= async () => {
  try {
    const res = await axios.get(`${url}${endpoints.getBlock}`);
    return res.data;
  } catch (err) {
    return err;
  }
};