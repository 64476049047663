import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Paper from "@material-ui/core/Paper";

import { addRule, getPermissionStatus, updateRule } from "../../../../../service/service";
import BinarywayButton from "../../../../UI-Components/Button/Button";
import { AppContext } from "../../../../../service/context";
import { appConstants } from "../../../../../service/constants";
import { RuleBuilderContext } from "../../../../../service/context";
import RuleCreatePng from "../../../../../assets/RuleCreate.png";
import RulesCreateCss from "./RulesCreate.module.css";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';


const RuleCreate = (props) => {
  const { appData, setAppData } = useContext(AppContext);
  const { ruleBuilderData, setRuleBuilderData } = useContext(
    RuleBuilderContext
  );
  const history = useHistory();

  const [editRule, setEditRule] = useState(false)


  useEffect(() => {
    let ruleData = null
    let editRule = false
    if (history && history.location && history.location.state && history.location.state.isEdit) {

      editRule = true;
      ruleData = history.location.state.editData;
      ruleBuilderData.ruleId = ruleData.ID
      setEditRule(true)
    }
    if (!ruleBuilderData.ruleType || !ruleBuilderData.ruleName) {
      window.location = "/Dashboard/Rules";
    }
  }, []);

  const onSaveButton = () => {
    if(editRule) {
      updateRule(ruleBuilderData).then((data) => {
        if (data.success) {
          appData.ShowAlert = {
            bool: true,
            msg: "Rule Updated Successfully",
            type: "success"
          };
          setAppData({ ...appData, ShowAlert: appData.ShowAlert });
          onCancelButton();
        } else {
          appData.ShowAlert = {
            bool: true,
            msg: "Some Problem. Please try again. if problem persist, contact support",
            type: "error",
          };
          setAppData({ ...appData, ShowAlert: appData.ShowAlert });
          onCancelButton();
        }
      });
      return;
    }
    addRule(ruleBuilderData).then((data) => {
      if (data.success) {
        appData.ShowAlert = {
          bool: true,
          msg: "Rule Added Successfully",
          type: "success"
        };
        setAppData({ ...appData, ShowAlert: appData.ShowAlert });
        onCancelButton();
      } else {
        appData.ShowAlert = {
          bool: true,
          msg: "Some Problem. Please try again. if problem persist, contact support",
          type: "error",
        };
        setAppData({ ...appData, ShowAlert: appData.ShowAlert });
        onCancelButton();
      }
    });
  };

  const setColorForRuleType = (type) => {
    const _rule_type = [
      { name: "Naming Convention" },
      { name: "Exists" },
      { name: "Whether all components are used?" },
      { name: "Process Flow" },
      { name: "Structure" },
      { name: "Value Check" },
    ];
  };

  const onCancelButton = () => {
    props.onhandleCancel();
  };

  return (
    <Container fluid={true}>
      <Paper className="rules_stepper_body" elevation={3}>
        <Row>
          <Col xs="6">
            <div className="rules_stepper_title">
              Great, you've filled all details ! save to create a rule
            </div>
          </Col>
          <Col xs="6">
            <div className="rules_stepper_button">
              <BinarywayButton
                variant={"outlined"}
                label={"Back"}
                onClick={props.onhandleBack}
                className="rules_stepper_button"
              ></BinarywayButton>
            </div>
          </Col>
        </Row>
        {ruleBuilderData && (
          <div className="custom-card-body">
            <Row>
              <Col xs="3">
                <Paper
                  className={RulesCreateCss.rule_create_left_main}
                  elevation={2}
                >
                  {/* <div>
                    <img
                      className={RulesCreateCss.rule_create_left_image}
                      src={RuleCreatePng}
                      alt="RuleCreatePng"
                    />
                  </div> */}
                  <div className={"rule_avtar"}>
                    {ruleBuilderData.ruleType && ruleBuilderData.ruleType.NAME}
                  </div>
                  <div className={RulesCreateCss.rule_create_left_body}>
                    {ruleBuilderData.ruleName}
                  </div>
                </Paper>
              </Col>
              <Col xs="9">
                <Paper
                  className={RulesCreateCss.rule_create_right_main}
                  elevation={2}
                >
                  <div className={RulesCreateCss.rule_create_right_body}>
                    <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Rule Name:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.ruleName}
                      </span>
                    </div>
                    {ruleBuilderData.detail && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Description:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.detail ? ruleBuilderData.detail : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.category && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Category:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.category
                          ? ruleBuilderData.category.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.targetVersion && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Blue Prism Version:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.targetVersion
                          ? ruleBuilderData.targetVersion.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleType && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Rule Type:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.ruleType
                          ? ruleBuilderData.ruleType.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleComponent && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Component:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.ruleComponent
                          ? ruleBuilderData.ruleComponent.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.componentAttribute && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Component Property:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.componentAttribute
                          ? ruleBuilderData.componentAttribute.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.BlockNames && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                       Block:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.BlockNames
                          ? ruleBuilderData.BlockNames.name
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleType && ruleBuilderData.ruleType.NAME != "Compare Template" && ruleBuilderData.ruleComponent.NAME !=="Spell Check" && !ruleBuilderData.BlockNames && ruleBuilderData.componentAtrributeValue && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Component Property Value:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.componentAtrributeValue
                          ? Array.isArray(ruleBuilderData.componentAtrributeValue) ? ruleBuilderData.componentAtrributeValue.toString(",")
                          : ruleBuilderData.componentAtrributeValue.NAME
                          : "-"}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleComponent.NAME ==="Spell Check"  && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Spell Check:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.spellCheck.map(i=>i.name).join(", ")}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleCriteriaValue && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        {`${ruleBuilderData.ruleType.NAME === 'Naming Convention' ? 'Pattern' : 'Operand'}`}:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.ruleCriteriaValue
                          ? ruleBuilderData.ruleCriteriaValue
                          : "-"}
                      </span>
                    </div>}
                      {ruleBuilderData.ruleType.NAME === 'Naming Convention2'  && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Camel Case:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.isCamelCase? 'true' : 'false'}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleType.NAME === 'Naming Convention2'  && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Inclusion Dictionary:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.isDictionary? 'true' : 'false'}
                      </span>
                    </div>}
                    {ruleBuilderData.ruleType.NAME === 'Naming Convention2'  && <div className={RulesCreateCss.rule_create_right_body_row}>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subheader
                        }
                      >
                        Exclusion Keywords:{" "}
                      </span>
                      <span
                        className={
                          RulesCreateCss.rule_create_right_body_subcontent
                        }
                      >
                        {ruleBuilderData.isKeyword? 'true' : 'false'}
                      </span>
                    </div>}
                    {ruleBuilderData && ruleBuilderData.stageData && ruleBuilderData.stageData.length ? ruleBuilderData.stageData.map((obj) => {
                      if (!obj.stage || !obj.stage.NAME) return;

                      return (
                        <Accordion style={{ border: '1px solid silver' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <>{obj.stage.NAME} </>
                          </AccordionSummary>
                          {ruleBuilderData && obj.businessValue && <AccordionDetails>
                            <p>
                              {
                                ruleBuilderData.ruleType.NAME === "Process Flow" &&
                                  obj.stage.NAME === "Recover" ? "Max Attempts:" : "Business Object:"
                              } {" "}
                              <span style={{ fontWeight: 500 }}>
                                {ruleBuilderData ? (obj.businessValue.NAME || '-') : "-"}{" "}
                              </span>
                            </p>
                          </AccordionDetails>}
                          {ruleBuilderData && obj.businessAction && <AccordionDetails>
                            <p>
                              Action:{" "}
                              <span style={{ fontWeight: 500 }}>
                                {ruleBuilderData ? obj.businessAction.NAME : "-"}
                              </span>{" "}
                            </p>
                          </AccordionDetails>}
                        </Accordion>
                      )


                    }) : null}
                  </div>
                </Paper>
              </Col>
              <Col xs="1"></Col>
            </Row>
          </div>
        )}
        <Row>
          <Col xs="6"></Col>
          <Col xs="6">
            <div className="rules_stepper_button">
              <BinarywayButton
                variant={"outlined"}
                label={"Cancel"}
                onClick={onCancelButton}
                className="rules_stepper_button"
              ></BinarywayButton>

              {appData.Permissions &&
                getPermissionStatus(
                  appData.Permissions,
                  appConstants.CREATE_RULE
                ) && (
                  <div style={{ marginLeft: '10px', display: 'inline-block', float: 'right' }}>
                    <BinarywayButton
                        label={editRule ? "Update" : "Save"}
                        onClick={onSaveButton}
                        className="rules_stepper_button"
                    ></BinarywayButton>
                  </div>
                )}
            </div>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};
export default RuleCreate;
