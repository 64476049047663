import React from "react";
import TextField from "@material-ui/core/TextField";

const BinarywayTextArea = (props) => {
  let id = props.id || "input_" + Math.random(),
    label = props.label || "Input",
    variant = props.variant || "outlined",
    type = props.type || "text",
    size = props.size || "small",
    fullWidth = props.fullWidth ? true : false,
    rowsMax = props.rowsMax || 4,
    error =props.error || false,
    maxLength = props.inputProps || 100,
    rows = props.rows || 1,
    helperText = "";


    const onInputChange = (event) => {
      if (props.validators && props.validators.length > 0) {
        for (let i = 0; i < props.validators.length; i++) {
          const result = onValidator(props.validators[i], event);
          if (result === false) {
            error = true;
            helperText = props.errormessages[i]
            break;
          } else {
            error = false;
            helperText = ""
          }
        }
      }
      props.onChange(event);
    };
  
    const onValidator = (type, event) => {
      let result = false;
      if (type === "required") {
        if (event.target.value && event.target.value.trim().length > 0) {
          result = true;
        }
      } else if (type === "isEmail") {
        if (event.target.value && event.target.value.trim().length > 0) {
          const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          result = re.test(event.target.value);
        }
      } else if (type === "maxLength") {
        if (event.target.value && event.target.value.trim().length > 0) {
          result = event.target.value.length <= maxLength ? true : false;
        }
      }
      return result;
    };

  return (
    <>
      {fullWidth && fullWidth == true ? (
        <TextField
          id={id}
          label={label}
          rows={rows}
          variant={variant}
          type={type}
          size={size}
          value={props.value}
          onChange={onInputChange}
          onBlur={onInputChange}
          multiline
          fullWidth
          rowsMax={rowsMax}
          error={error}
          inputProps={maxLength}
          helperText={helperText}
           
        />
      ) : (
        <TextField
          id={id}
          label={label}
          rows={rows}
          variant={variant}
          type={type}
          size={size}
          value={props.value}
          onChange={onInputChange}
          onBlur={onInputChange}
          multiline
          rowsMax={rowsMax}
          helperText={helperText}
        />
      )}
    </>
  );
};

export default BinarywayTextArea;
