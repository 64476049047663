import { createContext } from "react";

export const AppContext = createContext({
  appData: {},
  setAppData: () => {},
});

export const RuleBuilderContext = createContext({
  ruleBuilderData: {},
  setRuleBuilderData: () => {},
});
export const AnalyzerContext = createContext({
  analyzerData: {},
  setAnalyzerData: () => {},
});
