import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { grey } from '@material-ui/core/colors';
import { getDownload } from '../../../../../service/service';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

const useStyles = makeStyles({
  tableContainer: {
    borderRadius: '10px',
  },
  head: {
    backgroundColor: '#013765',
    color: '#ffffff',
  },
  headCell: {
    color: '#ffffff',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[200],
    },
  },
  tableCellRelease: {
    minWidth: '400px',
  },
  tableCellAnalyzer: {
    minWidth: '200px',
  },
});

const ExecutionAuditDisplayTable = (props) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const handleCodeIQClick = async (e, id, n) => {
    e.preventDefault();
    if(id <= 0)
    {
      return;
    }
    let response = await getDownload(id);
    const type = response.headers['content-type'];
    const blob = new Blob([response.data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let date = moment(new Date()).format(
      'DDMMYY-HH24MMSS'
    )
    link.download = `${n.replace(/-([^-]*)$/, ' - CodeIQ updated - ' + date + '.bprelease')}`;
    link.click();
    link.remove();
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell className={classes.headCell}>Date</TableCell>
            <TableCell className={classes.headCell}>User</TableCell>
            <TableCell className={classes.headCell}>Release File</TableCell>
            <TableCell className={classes.headCell}>Analyzer</TableCell>
            <TableCell className={classes.headCell}>Rules</TableCell>
            <TableCell className={classes.headCell}>Success</TableCell>
            <TableCell className={classes.headCell}>Failed</TableCell>
            <TableCell className={classes.headCell}>
              Duration&nbsp;(min)
            </TableCell>
            <TableCell className={classes.headCell}>
              CodeIQ release file
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell>{row.dateTime}</TableCell>
              <TableCell>{row.user}</TableCell>
              <TableCell className={classes.tableCellRelease}>
                {row.releaseFile}
              </TableCell>
              <TableCell className={classes.tableCellAnalyzer}>
                {row.analyzer}
              </TableCell>
              <TableCell>{row.rules}</TableCell>
              <TableCell>{row.success}</TableCell>
              <TableCell>{row.failed}</TableCell>
              <TableCell>{row.duration}</TableCell>
              <TableCell>
                <a href=''>
                  <span
                    style={{ marginRight: '5px' }}
                    onClick={(e) => {
                      handleCodeIQClick(e, row.execution_id, row.releaseFile);
                    }}
                  >
                    <Tooltip title='Download CodeIQ bprelease file' placement='bottom'>
                      <GetAppIcon />
                    </Tooltip>
                  </span>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExecutionAuditDisplayTable.propTypes = {};

export default ExecutionAuditDisplayTable;
