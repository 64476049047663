export const url = '/api';
export const site_key = '6LcwP8oiAAAAAJl4LMjzPlMSiJl51UbRHJj9Smw3';
export const allowedFiles = ["jpeg", "jpg", "png", "tiff", "mp4", "wmv"];
export const endpoints = {
  addRule: '/addRule',
  updateRule: '/updaterule',
  getConfig: '/getconfig',
  getRuleConfig: '/getruleconfig',
  getChildData: '/getChild',
  getElementData: '/getElementData',
  getRule: '/getRule',
  saveAnalyzer: '/saveAnalyzer',
  updateAnalyzer: '/updateAnalyzer',
  executeAnalyser: '/executeAnalyser',
  getAnalyzer: '/getAnalyzer',
  deleteRule: '/deleterule',
  deleteAnalyzer: '/deleteanalyzer',
  postUserLogin: '/login',
  updatePassword: '/changepassword',
  forgotPassword: '/forgotpassword',
  postAddUserDetails: '/user/create',
  postUpdateUserDetails: '/user/update',
  postDeleteUserDetails: '/user/delete',
  getUserDetailsByCompanyId: '/user/',
  postAddCompanyDetails: '/company/create',
  postUpdateCompanyDetails: '/company/update',
  postDeleteCompanyDetails: '/company/delete',
  getCompanyListDetails: '/company/',
  resetPassword: '/user/resetpassword',
  activateUser: '/user/activate',
  deactivateUser: '/user/deactivate',
  getRefreshtoken: '/refresh',
  getRoles: '/role/',
  getAllPermissions: '/user/allpermissions',
  getMenu: '/user/menu',
  logout: '/logout',
  getTemplateDetailsByCompanyId: '/getTemplate/',
  postDeleteTemplateDetails: '/deleteTemplate',
  createTemplate: '/createTemplate',
  uploadFromRichText: '/uploadFromRichText',
  updateactivestatus: '/updateactivestatus',
  getExecutionAudit: '/getExecutionAudit',
  executeDownload: '/download',
  getBlock: '/getBlock'
};
